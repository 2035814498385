import { JaguarResultResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { VitecNextProductPureInfoItems } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import { JaguarEstate } from '../../../../pure/types/JaguarEstate'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { useJaguarEstate } from './QueryHooks'
import { ReducerState } from './useFirestoreQueryHelper'

export function useJaguarResult<T extends JaguarResultResponse>(l?: LeopardOrder): ReducerState<T> {
  const q = useJaguarEstate(l?.externalEstateId)

  const data = getJaguarResultResponse({ jaguarEstate: q.data, product: l?.product })
  const query = { ...q, data } as ReducerState<T>
  return query
}

export function getJaguarResultsForLeopardOrderAndEstate({
  leopardOrders,
  jaguarEstate
}: {
  leopardOrders: LeopardOrder[]
  jaguarEstate?: JaguarEstate
}): (JaguarResultResponse | undefined)[] {
  return leopardOrders.map((l) => getJaguarResultResponse({ jaguarEstate, product: l.product }))
}

function getJaguarResultResponse({
  jaguarEstate,
  product
}: {
  jaguarEstate?: JaguarEstate
  product?: Product
}): JaguarResultResponse | undefined {
  const workflowId = mapProduct(product)
  if (!jaguarEstate || !workflowId) return undefined
  const results = jaguarEstate?.workflow_history?.[workflowId]?.findLast((w) => true)

  if (typeof results?.structured_outputs === 'string')
    results.structured_outputs = JSON.parse(results.structured_outputs)

  return results
}

const mapProduct = (product?: Product): keyof JaguarEstate['workflow_history'] | undefined => {
  if (product) return VitecNextProductPureInfoItems[product]?.['workflowIds'][0]

  return undefined
}
