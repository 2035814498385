import { DescriptionTypes } from '../../../../pure/libs/JaguarWorkflowDescriptionsItemsPure'
import { JaguarWorkflowAdValidationClientItem } from '../../../../pure/types/JaguarWorkflowAdValidationClientItem'
import { HardCodedTexts } from './HardCodedTexts'

export const JaguarWorkflowDescriptionsItems: {
  [property in DescriptionTypes]: JaguarWorkflowAdValidationClientItem
} = {
  short_description: {
    textKey: HardCodedTexts.descriptionsHeader,
    enableEdit: true
  },
  long_description: {
    textKey: HardCodedTexts.descriptionsShort,
    enableEdit: true
  },
  innhold: {
    textKey: HardCodedTexts.Innhold,
    enableEdit: true
  },
  standard: {
    textKey: HardCodedTexts.Standard,
    enableEdit: true
  }
}

export const DescriptionKeysAll = Object.keys(JaguarWorkflowDescriptionsItems)
