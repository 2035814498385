import styled from '@emotion/styled'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import Images from '../libs/Images'
import { IsSteppingThroughStatusContext } from '../types/IsSteppingThroughStatus'
import Box from './Box'
import FigmaImage from './FigmaImage'
import { useState } from 'react'

export function VitecNextPdfDrawerBottomComponent(
  props: {
    isSteppingThroughStatusContext: IsSteppingThroughStatusContext
    onClick?: () => void
  } & VitecNextOrderProductProps
) {
  const [hasClicked, setHasClicked] = useState(false)
  const { isSteppingThroughStatusContext } = props
  const [isSteppingThroughStatus] = isSteppingThroughStatusContext
  const isDone = isSteppingThroughStatus === 'done'
  if (isDone) return null

  const onClick = () => {
    setHasClicked(true)
    if (props.onClick) props.onClick()
  }

  const Container = hasClicked ? Box : ArrowContainer

  return (
    <Container onClick={onClick} pointer>
      <FigmaImage imageKey={Images.validatePdfArrowDown} />
    </Container>
  )
}

const ArrowContainer = styled(Box)`
  animation: blinker 1s steps(1, end) infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`
