import dayjs from 'dayjs'
import { createUniqueId } from '../../../../pure/libs/Common'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { VitecNextProductInfoItems } from '../components/VitecNextProductInfoItems'
import { State } from '../hooks/useAppState'
import { tryCreateJaguarOrder } from '../libs/CloudFunctionsApiHandler'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { getFeatureFlagsFromUrl } from './Common'

export type CreateLeopardOrderArgs = VitecNextOrderProductProps & {
  requestId?: string
  state: State
  enablePreview: boolean
  extra?: LeopardOrder['extra']
}

export const createLeopardOrder = async (props: CreateLeopardOrderArgs) => {
  const { product, externalEstateId, requestId = createUniqueId(), enablePreview, state, extra } = props
  const opts = VitecNextProductInfoItems[product].opts || {}
  opts.featureFlags = getFeatureFlagsFromUrl()

  // Disabling file cache to be able to rerun orders from ui
  opts.enableJaguarOrderFileCache = false

  const id = getLeopardOrderId(props)
  const leopardOrder: LeopardOrder = {
    _id: id,
    id,
    externalEstateId,
    requestId,
    createdAt: dayjs().format(),
    enablePreview,
    finishedAt: null,
    product,
    extra,
    metaData: {
      opts
    }
  }

  await upsertLeopardOrder(leopardOrder)

  await tryCreateJaguarOrder(
    {
      requestId,
      externalEstateId,
      product: props.product,
      extra,
      opts,
      source: 'Gazelle UI'
    },
    state
  )
}
