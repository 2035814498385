import { Address } from '../types/Address'
import { Estate } from '../types/Estate'
import { ActiveSourceSystem, ServerOptions } from '../types/types'

export enum EstateFunctionsEnum {
  getAddress = 'getAddress',
  shouldStartAdValidation = 'shouldStartAdValidation',
  shouldCreateJaguarOrderOnNewDocumentDownloaded = 'shouldCreateJaguarOrderOnNewDocumentDownloaded'
}

type Props = {
  estate?: Estate
}

export const getAddress = ({ estate }: { estate?: Estate }): Address | undefined =>
  getEstateFunctionValue({ estate, estateFunction: EstateFunctionsEnum.getAddress })

const getEstateFunctionValue = <T>(p: Props & { estateFunction: EstateFunctionsEnum }) => {
  const { estateFunction, estate } = p
  const sourceSystem = estate?.sourceSystem || 'VITEC_NEXT'
  const estateFunctionValue = EstateFunctionsPure[estateFunction][sourceSystem]
  if (!estateFunctionValue)
    throw new Error(`Estate function ${estateFunction} is not supported for environment ${estate?.environment}`)

  return estateFunctionValue(p) as T
}

export const shouldStartAdValidation = ({ estate }: Props): boolean =>
  getEstateFunctionValue({ estate, estateFunction: EstateFunctionsEnum.shouldStartAdValidation })

export const shouldCreateJaguarOrderOnNewDocumentDownloaded = ({ estate }: Props): boolean =>
  getEstateFunctionValue({ estate, estateFunction: EstateFunctionsEnum.shouldCreateJaguarOrderOnNewDocumentDownloaded })

const EstateFunctionsPure: {
  [property in EstateFunctionsEnum]: {
    [property in ActiveSourceSystem]: ((p: Props) => any) | undefined
  }
} = {
  [EstateFunctionsEnum.getAddress]: {
    VITEC_NEXT: ({ estate }): Address | undefined => estate?.metaData?.vitecNextEstate?.address || undefined,
    VISMA_CORE: ({ estate }): Address | undefined => ({
      apartmentNumber: estate?.metaData.vismaCoreCase?.ApartmentNumber,
      streetAdress: estate?.metaData.vismaCoreCase?.Street,
      zipCode: estate?.metaData.vismaCoreCase?.ZipCode,
      city: estate?.metaData.vismaCoreCase?.City
    })
  },
  [EstateFunctionsEnum.shouldStartAdValidation]: {
    VITEC_NEXT: ({ estate }): boolean => !!estate?.metaData.vitecNextOrder?.estateId,
    VISMA_CORE: ({ estate }): boolean => false
  },
  [EstateFunctionsEnum.shouldCreateJaguarOrderOnNewDocumentDownloaded]: {
    VITEC_NEXT: ({ estate }): boolean => !!estate?.metaData.vitecNextOrder?.estateId,
    VISMA_CORE: ({ estate }): boolean => false
  }
}
