import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { useSourceFile } from '../libs/VitecNextProductSourceFileInfoHelper'
import Box from './Box'
import { VitecNextOrderProductStatusJaguarWarning } from './VitecNextOrderProductStatusJaguarWarning'
import { VitecNextProductSourceFileInfoView } from './VitecNextProductSourceFileInfoView'
import { DrawerContext } from './useDrawerContext'

export type Props = VitecNextOrderProductProps & {
  drawerContext?: DrawerContext
}

export function VitecNextOrderProductStatus(props: Props) {
  const { drawerContext } = props
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { data: results } = useJaguarResult(leopardOrder)
  const warnings = results?.warnings || []

  const sourceFile = useSourceFile(leopardOrder)

  const [fileName] = sourceFile || []

  if (warnings.length > 0)
    return (
      <Box fullWidth>
        <VitecNextOrderProductStatusJaguarWarning results={results} />
      </Box>
    )

  return (
    <Box fullWidth align="center">
      {fileName && (
        <Box onClick={() => drawerContext?.open()}>
          <VitecNextProductSourceFileInfoView fileName={fileName} />
        </Box>
      )}
    </Box>
  )
}
