/* eslint-disable prettier/prettier */
import type Stripe from 'stripe'
import { AreGazelleProductsEnabledRequest } from '../../../../pure/leopard/AreGazelleProductsEnabledRequest.js'
import CloudFunction, { StripeCloudFunction } from '../../../../pure/leopard/CloudFunctions.js'
import { CreateJaguarOrderRequest } from '../../../../pure/leopard/CreateJaguarOrderRequest.js'
import { GetEstateForVitecNextOrderRequest } from '../../../../pure/leopard/GetEstatenForVitecNextOrderRequest.js'
import { CreateLeopardOrderForVismaOrderRequest } from '../../../../pure/types/CreateLeopardOrderForVismaOrderRequest.js'
import { FulfillLeopardOrderRequest } from '../../../../pure/types/FulfillLeopardOrderRequest.js'
import { VismaLandingQueryParams } from '../../../../pure/types/QueryParamTypes.js'
import { SlackFeedbackRequest } from '../../../../pure/types/SlackFeedbackRequest.js'
import { DeliverVitecNOCaptionsRequest, PutVitecNOImageResponse } from '../../../../pure/types/VitecNODerivedTypes.js'
import { VitecNextEstate } from '../../../../pure/types/VitecNextEstate.js'
import { CreateLoginTokenRequest, CustomAuthToken, ExternalSession, InviteUserRequest, LoginWithCodeToken } from '../../../../pure/types/types.js'
import { State } from '../hooks/useAppState.js'
import { callFunction } from './CloudFunctionsApiHandlerHelper.js'

export const deleteAccount = (uid: string, state: State): Promise<void> => callFunction(CloudFunction.deleteAccount, state, uid)

export const createAuthenticationTokenForLoginToken = (req: LoginWithCodeToken, state: State): Promise<CustomAuthToken> => callFunction(CloudFunction.createAuthenticationTokenForLoginToken, state, req)

export const createLoginWithCodeToken = (req: CreateLoginTokenRequest, state: State): Promise<CreateLoginTokenRequest> => callFunction(CloudFunction.createLoginWithCodeToken, state, req)

export const createStripePortalLink = (req, state: State): Promise<{url: string}> => callFunction(StripeCloudFunction.createStripePortalLink as unknown as CloudFunction, state, req)

export const inviteUser = (req: InviteUserRequest, state: State): Promise<unknown> => Promise.resolve() // callFunction(CloudFunction.inviteUser, state, req)

export const processExternalSession = (req: ExternalSession, state: State): Promise<ExternalSession> => callFunction(CloudFunction.processExternalSession, state, req)

export const createSessionForUnpayedSession = (id: string, state: State): Promise<ExternalSession> => callFunction(CloudFunction.createSessionForUnpayedSession, state, id)

export const getStripeSubscriptions = (state: State): Promise<Stripe.Subscription[]> => callFunction(CloudFunction.getStripeSubscriptions, state)

export const createAuthenticationTokenForUid = (uid: string, state: State): Promise<string> => callFunction(CloudFunction.createAuthenticationTokenForUid, state, uid)

export const deliverViteNOCaptions = (req: DeliverVitecNOCaptionsRequest, state: State): Promise<PutVitecNOImageResponse> => callFunction(CloudFunction.deliverViteNOCaptions, state, req)

export const getEstateForVitecNextOrder = (req: GetEstateForVitecNextOrderRequest, state: State): Promise<VitecNextEstate> => callFunction(CloudFunction.getEstateForVitecNextOrder, state, req)

export const fulfillLeopardOrder = (req: FulfillLeopardOrderRequest, state: State): Promise<unknown> => callFunction(CloudFunction.fulfillLeopardOrder, state, req)

export const areGazelleProductsEnabled = (req: AreGazelleProductsEnabledRequest, state: State) => callFunction(CloudFunction.areGazelleProductsEnabled, state, req)

export const tryCreateJaguarOrder = (req: CreateJaguarOrderRequest, state: State) => callFunction(CloudFunction.tryCreateJaguarOrder, state, req)

export const slackFeedback = (req: SlackFeedbackRequest, state: State) => callFunction(CloudFunction.slackFeedback, state, req)

export const createLeopardOrdersForVismaOrder = (req: VismaLandingQueryParams, state: State): Promise<CreateLeopardOrderForVismaOrderRequest> => callFunction(CloudFunction.createLeopardOrdersForVismaOrder, state, req)
