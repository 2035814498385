import { Texts } from '../../../../pure/libs/AppTexts'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import { mediumSpacing, reallyBigSpacing, regularSpacing, tinySpacing } from '../enums/Spacings'
import { shouldShowMail } from '../libs/SignInWithAuthProvidersHelper'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import Box from './Box'
import FigmaText from './FigmaText'
import SignInSignUpLayout from './SignInSignUpLayout'
import SignInSignUpWithEmailForm from './SignInSignUpWithEmailForm'
import { SignInWithAuthProviders } from './SignInWithAuthProviders'
import { GreyLine } from './StyledComponents'

export default function SignInLanding(props: SignInViewProps) {
  const headingTextkey = Texts.adCreatorWelcomeEmptyState
  const subheadingTextKey = HardCodedTexts.signInSubheading
  const { preferredLoginMethod } = props.signInState
  const _shouldShowMail = shouldShowMail(preferredLoginMethod)

  return (
    <SignInSignUpLayout headingTextKey={headingTextkey} subheadingTextKey={subheadingTextKey}>
      <Box fullWidth>
        <SignInWithAuthProviders {...props} />
        {_shouldShowMail && (
          <>
            <Box top bottom direction="row" align="center" fullWidth spacing={regularSpacing}>
              <GreyLine />
              <Box left right spacing={mediumSpacing} style={{ marginRight: tinySpacing }}>
                <FigmaText textKey={HardCodedTexts.allSeparatorOr} />
              </Box>
              <GreyLine />
            </Box>
            <Box fullWidth top bottom spacing={tinySpacing}>
              <SignInSignUpWithEmailForm {...props} />
            </Box>
          </>
        )}
      </Box>
    </SignInSignUpLayout>
  )
}
