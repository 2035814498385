import { AreGazelleProductsEnabledResponse } from '../../../../pure/leopard/AreGazelleProductsEnabledResponse'
import { Product, ValidatableProductNames } from '../../../../pure/leopard/Product'
import { Estate } from '../../../../pure/types/Estate'
import { JaguarEstate } from '../../../../pure/types/JaguarEstate'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextProductInfoItems } from '../components/VitecNextProductInfoItems'
import { DrawerContext, useDrawerContext } from '../components/useDrawerContext'
import { useLatestLeopardOrders } from '../components/useLatestLeopardOrders'
import {
  VitecNextItemsValidationArgs,
  VitecNextProductInfoItem,
  VitecNextProductInfoItemValidationItem
} from '../types/VitecNextProductTypes'
import { useEstate, useJaguarEstate } from './QueryHooks'
import { useAreProductsEnabledCache } from './useIsProductEnabled'
import { getJaguarResultsForLeopardOrderAndEstate } from './useJaguarResult'
import { getJaguarResultsObject } from '../../../../pure/libs/getJaguarResultsObject'
import { getVitecNextAdValidationItems, validateVitecNextProductInfoItem } from '../components/AdValidationClientHelper'

export const useVitecNextValidationItems = ({
  externalEstateId
}: {
  externalEstateId?: string
}): { data: VitecNextProductInfoItemValidationItem[]; isLoading: boolean } => {
  const drawerContext = useDrawerContext('ad_validation')
  const { data: leopardOrders = [] } = useLatestLeopardOrders(externalEstateId)

  const { data: estate } = useEstate(externalEstateId)

  const { data: jaguarEstate } = useJaguarEstate(externalEstateId)

  const { data: areProductsEnabled, isLoading: isLoadingProducts } = useAreProductsEnabledCache({
    externalEstateId: externalEstateId || ''
  })

  const isLoading = isLoadingProducts || false

  const validationItems = getValidationItems({ leopardOrders, jaguarEstate, estate, areProductsEnabled, drawerContext })

  return { data: validationItems, isLoading }
}

function getValidationItems({
  leopardOrders,
  jaguarEstate,
  estate,
  areProductsEnabled,
  drawerContext
}: {
  leopardOrders: LeopardOrder[]
  jaguarEstate: JaguarEstate | undefined
  estate: Estate | undefined
  areProductsEnabled: AreGazelleProductsEnabledResponse | undefined
  drawerContext: DrawerContext
}): VitecNextProductInfoItemValidationItem[] {
  const estateId = estate?.id
  if (!estateId) return []

  const rows = ValidatableProductNames.map((product) => ({
    leopardOrder: leopardOrders.find((order) => order?.product === product),
    product
  }))

  const jaguarResponses = getJaguarResultsForLeopardOrderAndEstate({ leopardOrders, jaguarEstate })

  const results = getJaguarResultsObject({ jaguarEstate, product: Product.AD_VALIDATION })

  const res = getVitecNextAdValidationItems({ estate, results, drawerContext })

  return res.concat(
    rows
      .map((row): VitecNextProductInfoItemValidationItem[] => {
        const { product } = row
        const leopardOrder = leopardOrders.find((order) => order?.product === product)
        const result = jaguarResponses.find((r) => r?.request_id === leopardOrder?.requestId)

        const results = getJaguarResultsObject({ jaguarEstate, product })

        const infoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[product]

        if (!infoItem) return []

        const props: VitecNextItemsValidationArgs = {
          product,
          externalEstateId: estate.id,
          estate,
          result,
          isVitecNextProductEnabled: areProductsEnabled?.[product],
          drawerContext,
          results
        }

        return validateVitecNextProductInfoItem?.(props) || []
      })
      .flat()
  )
}
