export const HardCodedTranslations = {
  landRegistryTranscript: {
    en: 'Landregistry Transcript',
    no: 'Grunnboksutskrift'
  },
  landRegistryTranscriptDescriptions: {
    en: 'Landregistry Transcript',
    no: 'Analyser grunnboksutskriften og opprett heftelser.'
  },
  kommunale_avgifter: {
    en: 'Community tax',
    no: 'Kommunale Avgifter'
  },
  kommunale_avgifter_year: {
    en: 'Community tax Year',
    no: 'Kommunale Avgifter År'
  },
  eiendomsskatt: {
    en: 'Property tax',
    no: 'Eiendomsskatt'
  },
  eiendomsskatt_year: {
    en: 'Property tax Year',
    no: 'Eiendomsskatt År'
  },
  primary_value: {
    en: 'Propertyvalue Primary Resident',
    no: 'Formuesverdi Primærbolig'
  },
  primary_value_year: {
    en: 'Propertyvalue Resident Year',
    no: 'Formuesverdi Primærbolig År'
  },
  secondary_value: {
    en: 'Propertyvalue Secondary Resident',
    no: 'Formuesverdi Sekundærbolig'
  },
  secondary_value_year: {
    en: 'Propertyvalue Secondary Resident Year',
    no: 'Formuesverdi Sekundærbolig År'
  },
  bra: {
    en: 'BRA – Bruksareal',
    no: 'BRA – Bruksareal'
  },
  braI: {
    en: 'BRA-I – Internt bruksareal',
    no: 'BRA-I – Internt bruksareal'
  },
  braB: {
    en: 'BRA-B – Balkong bruksareal',
    no: 'BRA-B – Balkong bruksareal '
  },
  braE: {
    en: 'BRA-E – Eksternt bruksreal',
    no: 'BRA-E – Eksternt bruksreal'
  },
  tba: {
    en: 'TBA - Terrasse og Balkongsareal',
    no: 'TBA - Terrasse og Balkongsareal'
  },
  pROM: {
    en: 'pROM',
    no: 'pROM'
  },
  sROM: {
    en: 'sROM',
    no: 'sROM'
  },
  none: {
    en: 'None',
    no: 'Ingen'
  },
  board_approval_explenation: {
    en: 'Board Approval Explanation',
    no: 'Styregodkjennelse Forklaring'
  },
  forkjøpsrett_explanation_explenation: {
    en: 'Preemptive Right Explanation',
    no: 'Forkjøpsrett Forklaring'
  },
  about_association: {
    en: 'About the association',
    no: 'Om boligselskapet (borettslaget, sameiet eller aksjeselskapet)'
  },
  // TODO: This one is hardcoded to only use no for now check validateEstate.ts for more info
  automatic_ad_validation: {
    en: 'AdValidation',
    no: 'Automatisk avvikskontroll'
  },
  continue: {
    en: 'Continue',
    no: 'Fortsett'
  }
}
