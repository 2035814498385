import {
  BasicAssociationFields,
  BasicAssociationResponseItemsKey,
  JaguarWorkflowBasicAssociationItemsPure
} from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import { JaguarWorkflowBasicAssociationItems } from './JaguarWorkflowBasicAssociationItems'

export const shouldShowBasicAssociationField = ({
  itemKey,
  fields
}: {
  itemKey: BasicAssociationResponseItemsKey
  fields?: BasicAssociationFields
}) => {
  if (!fields) return false
  if (!JaguarWorkflowBasicAssociationItems[itemKey]?.textKey) return false
  if (!fields[itemKey]) return false
  if (!fields[itemKey].value) return false
  if (JaguarWorkflowBasicAssociationItemsPure[itemKey].enabled === false) return false
  if (itemKey === 'styregodkjennelse' && !!fields['styregodkjennelse_explanation']?.value) return false
  if (itemKey === 'forkjøpsrett' && !!fields['forkjøpsrett_explanation']?.value) return false

  return true
}
