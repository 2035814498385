import { Product } from '../../../../pure/leopard/Product'
import { useNow } from '../hooks/useNow'
import Box from './Box'
import { VitecNextOrderItem } from './VitecNextOrderItem'

export function DashboardGrid({ externalEstateId }: { externalEstateId: string; now: string }) {
  const now = useNow()

  return (
    <Box fullWidth>
      <Box direction="row" fullHeight fullWidth justify="space-between">
        {[Product.IMAGE_CAPTIONS, Product.CONSTRUCTION_INFO, Product.DESCRIPTIONS]
          .filter((v) => !!v)
          .map((product, i) => (
            <VitecNextOrderItem key={i} product={product} externalEstateId={externalEstateId} now={now} />
          ))}
      </Box>
      <Box direction="row" fullHeight top fullWidth justify="space-between">
        {[Product.BASIC_ASSOCIATION, Product.SELF_DECLERATION, Product.AREA_DESCRIPTION]
          .filter((v) => !!v)
          .map((product, i) => (
            <VitecNextOrderItem key={i} product={product} externalEstateId={externalEstateId} now={now} />
          ))}
      </Box>
      <Box direction="row" fullHeight top fullWidth justify="space-between">
        {[Product.LAND_REGISTRY]
          .filter((v) => !!v)
          .map((product, i) => (
            <VitecNextOrderItem key={i} product={product} externalEstateId={externalEstateId} now={now} />
          ))}
      </Box>
    </Box>
  )
}
