import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { BuildingComponent } from '../../../../pure/leopard/JaguarTypesDerived'
import { HIGHLIGHT_COLOR_50 } from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_LEFT } from '../libs/HardCodedSizes'
import Box from './Box'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { StepThroughContentItem } from '../../../../pure/libs/StepThroughContentHelper'
import { NoWrapFigmaText } from './StyledComponents'
import { Texts } from '../libs/Texts'
import FigmaImageContainer from './FigmaImageContainer'
import Images from '../libs/Images'
import { IsSteppingThroughStatusContext } from '../types/IsSteppingThroughStatus'

export type ConstructionMethodItem = StepThroughContentItem<BuildingComponent>

export function VitecNextOrderProductConstructionInfoItem({
  text,
  item,
  selectedItem,
  onClickHighlight,
  isSteppingThroughStatusContext
}: {
  text: string
  item?: ConstructionMethodItem
  selectedItem?: ConstructionMethodItem
  onClickHighlight: (field: ConstructionMethodItem) => unknown
  isSteppingThroughStatusContext: IsSteppingThroughStatusContext
}) {
  const [isSteppingThroughStatus] = isSteppingThroughStatusContext || [false]
  const ref = useRef<HTMLDivElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const isSelected = selectedItem?.id === item?.id
  const hasSelectedSibling = !!selectedItem && selectedItem.id !== item?.id

  useEffect(() => {
    if (isSelected && isSteppingThroughStatus === 'started')
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [isSelected])

  const onClick = item && !!text ? () => onClickHighlight(item) : undefined

  return (
    <Box
      fullWidth
      direction="row"
      justify="space-between"
      align="center"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      pointer={!!onClick}
    >
      <TextContainer
        isSelected={isSelected}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        position="relative"
        opacity={hasSelectedSibling && isSteppingThroughStatus === 'started' ? 0.5 : 1}
        fullPadding={isSelected}
        spacing={tinySpacing}
        fullWidth
      >
        <Box
          ref={ref}
          style={{ position: 'absolute', top: -STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_LEFT }}
        />
        <FigmaTextWithStyle
          Component={FigmaTextWithLinebreaks as any}
          separator={<span />}
          separatorWhenEmptyContent={<br />}
          type="body1"
          text={text}
        />
      </TextContainer>

      <Box
        direction="row"
        align="center"
        visibility={!!text && isHovered && !isSelected ? 'visible' : 'hidden'}
        pointer
        left
        right
      >
        <Box left spacing={tinySpacing}>
          <NoWrapFigmaText textKey={Texts.SelfDeclerationSeeReference} />
        </Box>
        <Box left spacing={smallSpacing}>
          <FigmaImageContainer imageKey={Images.arrowForward} />
        </Box>
      </Box>
    </Box>
  )
}

export const TextContainer = styled(Box)<{ isSelected?: boolean; opacity?: number }>`
  display: inline-block;
  background-color: ${({ isSelected }) => (isSelected ? HIGHLIGHT_COLOR_50 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
  opacity: ${({ opacity }) => opacity};
`
