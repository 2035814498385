import {
  BasicAssociationKeysThatCanBeCantBeUploaded,
  BasicAssociationKeysThatCanBeUploaded,
  BasicAssociationResponseItemsKey
} from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import { isNoInNorwegian } from '../../../../pure/libs/isNoInNorwegian'
import { HardCodedTexts } from './HardCodedTexts'
import { HardCodedTranslations } from '../../../../pure/libs/HardCodedTranslations'
import { JaguarWorkflowAdValidationClientItem } from '../../../../pure/types/JaguarWorkflowAdValidationClientItem'
import { getFigmaText } from './TextRepository'
import { Texts } from './Texts'

export const JaguarWorkflowBasicAssociationItems: {
  [property in BasicAssociationResponseItemsKey]: JaguarWorkflowAdValidationClientItem
} = {
  felleskostnader_pr_mnd: {
    textKey: Texts.BasicAssociationBaRentPrMonth
  },
  andel_formue: {
    textKey: Texts.BasicAssociationBaAndelFormue
  },
  borettslaget: {
    textKey: Texts.BasicAssociationBaPartName
  },
  borettslagets_org_nr: {
    textKey: Texts.BasicAssociationBaPartOrgNumber
  },

  forkjøpsrettsgebyr: {
    textKey: Texts.BasicAssociationBaPreemtiveFee,
    format: ({ items }) => {
      if (isNoInNorwegian(items.forkjøpsrett.value as string))
        return getFigmaText({ ...HardCodedTexts.default, texts: HardCodedTranslations.none })

      return items.forkjøpsrettsgebyr.value as string
    }
  },
  noteringsgebyr: {
    textKey: Texts.BasicAssociationBaNoteringsGebyr
  },
  husdyrhold: {
    textKey: Texts.BasicAssociationBaAnimal
  },
  andel_fellesgjeld: {
    textKey: Texts.BasicAssociationBaCollectiveDebt
  },
  lånebetingelser_fellesgjeld: {
    textKey: Texts.BasicAssociationBaLoanTermsCollectiveDebt
  },

  sikringsordning_fellesgjeld: {
    textKey: Texts.BasicAssociationBaGuaranteeFund
  },
  borettslagets_forsikringsselskap: {
    textKey: Texts.BasicAssociationBaInsuranceCompany
  },
  polisenummer_felles_forsikring: {
    textKey: Texts.BasicAssociationBaInsurancePolicy
  },
  forretningsfører: {
    textKey: Texts.BasicAssociationBaBusinessManager
  },
  eierskiftegebyr: {
    textKey: Texts.BasicAssociationBaChangeOfOwnershipFee
  },
  styregodkjennelse: {
    textKey: { ...Texts.BasicAssociationBaBoardApproval, texts: HardCodedTranslations.board_approval_explenation }
  },
  styregodkjennelse_bool: {
    textKey: Texts.BasicAssociationBaBoardApproval
  },
  styregodkjennelse_explanation: {
    textKey: { ...Texts.BasicAssociationBaBoardApproval, texts: HardCodedTranslations.board_approval_explenation }
  },

  forkjøpsrett_bool: {
    textKey: Texts.BasicAssociationBaPreemptive
  },
  forkjøpsrett: {
    textKey: {
      ...Texts.BasicAssociationBaPreemptive,
      texts: HardCodedTranslations.forkjøpsrett_explanation_explenation
    }
  },
  forkjøpsrett_explanation: {
    textKey: {
      ...Texts.BasicAssociationBaPreemptive,
      texts: HardCodedTranslations.forkjøpsrett_explanation_explenation
    }
  },
  about_association: {
    textKey: {
      ...Texts.BasicAssociationBaPreemptive,
      texts: HardCodedTranslations.about_association
    },
    enableEdit: true
  }
}

export const BasicAssociationKeysInOrderInUI = [
  ...BasicAssociationKeysThatCanBeUploaded,
  ...BasicAssociationKeysThatCanBeCantBeUploaded
]
