import { MunicipalInfoItems } from '../leopard/JaguarTypesDerived'
import { AdValidationFieldTemplate } from '../types/AdValidationField'
import { AreaSizes } from '../leopard/JaguarTypesDerived'
import { JaguarWorkflowBasicAssociationItemsPure } from './JaguarWorkflowBasicAssociationItemsPure'

export const JaguarWorkflowMunicialItemsPure: {
  [property in keyof MunicipalInfoItems]: AdValidationFieldTemplate
} = {
  kommunale_avgifter: {
    getValueFromEstate: (estate) => estate?.estatePrice?.communityTax,
    isFloatNumber: true
  },
  kommunale_avgifter_year: {
    enabled: false,
    getValueFromEstate: (estate) => estate?.estatePrice?.communityTaxYear
  },
  eiendomsskatt: {
    getValueFromEstate: (estate) => estate?.valuationTax?.propertyTaxAmount,
    isFloatNumber: true
  },
  eiendomsskatt_year: {
    enabled: false,
    getValueFromEstate: (estate) => estate?.valuationTax?.propertyTaxYear
  },
  primary_value: {
    // Som primärbolig
    getValueFromEstate: (estate) => estate?.valuationTax?.primaryValue
  },
  primary_value_year: {
    enabled: false,
    getValueFromEstate: (estate) => estate?.valuationTax?.primaryYear
  },
  secondary_value: {
    // Som sekundærbolig
    getValueFromEstate: (estate) => estate?.valuationTax?.secondaryValue
  },
  secondary_value_year: {
    enabled: false,
    getValueFromEstate: (estate) => estate?.valuationTax?.secondaryYear
  }
}

export const JaguarWorkflowTillstansrapportItemsPure: {
  [property in keyof AreaSizes]: AdValidationFieldTemplate
} = {
  bra: {
    // Boarea
    getValueFromEstate: (estate) => estate?.sumArea?.bra
  },
  braI: {
    // Boarea internal
    getValueFromEstate: (estate) => estate?.sumArea?.braI
  },
  braB: {
    // Boarea enclosed balcone
    getValueFromEstate: (estate) => estate?.sumArea?.braB
  },
  braE: {
    // Boarea External
    getValueFromEstate: (estate) => estate?.sumArea?.braE
  },
  tba: {
    // Boarea non enclosed balcony
    getValueFromEstate: (estate) => estate?.sumArea?.tba
  },
  pROM: {
    enabled: false, // Boarea non enclosed balcony
    getValueFromEstate: (estate) => estate?.valuationTax?.primaryYear
  },
  sROM: {
    enabled: false,
    getValueFromEstate: (estate) => estate?.valuationTax?.secondaryValue
  }
}

export const AllAdValidationFieldTemplates: { [key: string]: AdValidationFieldTemplate } = {
  ...JaguarWorkflowMunicialItemsPure,
  ...JaguarWorkflowTillstansrapportItemsPure,
  ...JaguarWorkflowBasicAssociationItemsPure
}
