import styled from '@emotion/styled'
import { LightBlue, LightBlue50 } from '../../../../pure/libs/Colors'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { SMALL_SPACING_PX, bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { IsSteppingThroughStatusContext } from '../types/IsSteppingThroughStatus'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { ProgressBarView } from './ProgressBar'
import { ConstructionMethodItem } from './VitecNextOrderProductConstructionInfoItem'
import {
  IS_STEPPING_THROUGH_NEXT_BUTTON_VERTICAL_SPACING,
  VitecNextOrderProductStepThroughContentFulfillButton
} from './VitecNextOrderProductStepThroughContentFulfillButton'
import FigmaTextWithStyleOverrides from './FigmaTextWithStyleOverrides'

export const VitecNextOrderProductStepThroughContent = (
  props: VitecNextOrderProductProps & {
    onClickNext: () => unknown
    onClickPrevious: () => unknown
    selectedIndex: number
    items: ConstructionMethodItem[]
    isSteppingThroughStatusContext: IsSteppingThroughStatusContext
  }
) => {
  const { items, onClickNext, onClickPrevious, selectedIndex, isSteppingThroughStatusContext } = props
  const [isSteppingThroughStatus] = isSteppingThroughStatusContext
  const hasNoSelectedItem = selectedIndex === -1

  const progress = isSteppingThroughStatus === 'done' ? 100 : ((selectedIndex + 1) / items.length) * 100

  const textElement =
    isSteppingThroughStatus === 'done' ? (
      <FigmaTextWithStyleOverrides textKeys={{ en: Texts.validatePdfGreatJobYouveValidatedAllParts }} />
    ) : (
      <FigmaText
        textKey={Texts.validatePdfToUploadToYouNeedToValidate}
        text={formatText(getFigmaText(Texts.validatePdfToUploadToYouNeedToValidate), ['Next'])}
      />
    )

  return (
    <OuterContainer fullWidth backgroundColor={LightBlue}>
      <Container fullPadding fullWidth direction="row" align="center">
        <Box fullWidth direction="row" align="center">
          <Box>
            <FigmaImageContainer imageKey={Images.uploadArrowGrey} width={SMALL_SPACING_PX * 4} />
          </Box>
          <Box width="850px" left style={{ marginTop: `-${tinySpacing}` }}>
            <Box>{textElement}</Box>
            <Box fullWidth top spacing={tinySpacing} position="relative">
              <Box position="absolute" style={{ right: 0, top: -SMALL_SPACING_PX }}>
                <FigmaText textKey={Texts.validatePdfxxxOfxxx} text={`${progress.toFixed(0)}%`} />
              </Box>
              <ProgressBarView color="secondary" progress={progress} sx={{ height: 5 }} />
            </Box>
          </Box>
          <Box left spacing={bigSpacing} fullWidth align="flex-end">
            <Box direction="row" align="center">
              <Box visibility={hasNoSelectedItem ? 'hidden' : 'visible'}>
                <Button
                  buttonType="secondary"
                  spacing={smallSpacing}
                  verticalSpacing={IS_STEPPING_THROUGH_NEXT_BUTTON_VERTICAL_SPACING}
                  variant="outlined"
                  onClick={onClickPrevious}
                >
                  <FigmaText textKey={Texts.validatePdfButtonPrevious} />
                </Button>
              </Box>
              <Box left spacing={smallSpacing}>
                {isSteppingThroughStatus !== 'done' && (
                  <Button
                    buttonType="secondary"
                    spacing={hasNoSelectedItem ? undefined : smallSpacing}
                    onClick={onClickNext}
                  >
                    <FigmaText
                      textKey={Texts.validatePdfButtonNext}
                      text={hasNoSelectedItem ? 'Start ➪' : getFigmaText(Texts.validatePdfButtonNext)}
                    />
                  </Button>
                )}
                {isSteppingThroughStatus === 'done' && (
                  <VitecNextOrderProductStepThroughContentFulfillButton {...props} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </OuterContainer>
  )
}

const OuterContainer = styled(Box)`
  background-color: ${LightBlue};
  border-radius: ${BorderRadixes.regular};
`

const Container = styled(Box)`
  background-color: ${LightBlue50};
  border-radius: ${BorderRadixes.regular};
`
