/**
 * Format: int32
 * @description
 *
 *
 *     0 = none (Ingen verdier skal lagres)
 *
 *
 *     1 = accountingBudgeting (Regnskap/budsjett/kostnadsøkninger)
 *
 *
 *     2 = amountYearsInstallmentFree (Avdragsfrie år)
 *
 *
 *     3 = animals (Husdyrhold)
 *
 *
 *     4 = boardApproval (Styregodkjennelse)
 *
 *
 *     5 = communityFee (Kommentar kommunale avgifter)
 *
 *
 *     6 = constructionMethod (Byggemåte)
 *
 *
 *     7 = guaranteeFund (Sikringsordning fellesgjeld)
 *
 *
 *     8 = insuranceCompany (Borettslagets forsikringsselskap)
 *
 *
 *     9 = insurancePolicy (Polisenummer felles forsikring)
 *
 *
 *     10 = jointCostAfterGracePeriod (Felleskostnader etter avdragsfri periode)
 *
 *
 *     11 = jointDebtDescription (Kommentar fellesgjeld)
 *
 *
 *     12 = jointDebtInstallment (Avdrag fellesgjeld)
 *
 *
 *     13 = jointDebtInterest (Rentekostnader fellesgjeld)
 *
 *
 *     14 = loanTermsCollectiveDebt (Lånebetingelser fellesgjeld)
 *
 *
 *     15 = partAbout (Om boligselskapet (borettslaget, sameiet eller aksjeselskapet))
 *
 *
 *     16 = preemptive (Forkjøpsrett)
 *
 *
 *     17 = rentIncludes (Felleskostnader inkluderer)
 *
 *
 *     18 = residentCommitments (Beboernes forpliktelser og dugnader)
 *
 *
 *     19 = statutes (Vedtekter/husordensregler)
 *
 *
 *     20 = valuationType (Type rapport)
 *
 *
 *     21 = estateCorporationPartialBondNumber (Partialobligasjonsnummer)
 *
 *
 *     22 = partNumber (Andelsnummer)
 *
 *
 *     23 = shareJointDebtYear (Andel fellesgjeld år)
 *
 *
 *     24 = changeOfOwnershipFee (Eierskiftegebyr)
 *
 *
 *     25 = collectiveDebt (Andel fellesgjeld)
 *
 *
 *     26 = communityTax (Kommunale avgifter)
 *
 *
 *     27 = deposit (Innskudd)
 *
 *
 *     28 = estateCorporationPartialDenominatedBond (Partialobligasjon pålydende)
 *
 *
 *     29 = estimatedValue (Verditakst)
 *
 *
 *     30 = loanFare (Lånetakst)
 *
 *
 *     31 = preemptiveFee (Gebyr forkjøpsrett)
 *
 *
 *     32 = rentPrMonth (Felleskostnader per måned)
 *
 *
 *     33 = shareJointCapital (Andel fellesformue)
 *
 *
 *     34 = hasBoardApproval (Har styregodkjennelse)
 *
 *
 *     35 = hasPreemptiveRight (Har forkjøpsrett)
 *
 *
 *     36 = preemptiveDeadline (Forkjøpsfrist)
 *
 *
 *     37 = shareJointCapitalDate (Andel felles formue per dato)
 *
 *
 *     38 = shareJointDebtToDate (Andel fellesgjeld per dato)
 *
 *
 *     39 = valuationDate (Rapportdato)
 *
 *
 *     40 = estateHousingCooperativeStockHousingUnitNumber (Aksjenummer)
 *
 *
 *     41 = valuationTypeEnum (Takstrapporttype som enum-verdi, erstatter valuationType (20))
 *
 *
 *     42 = plotSize (Tomteareal)
 *
 *
 *     43 = plotDescription (Tomtebeskrivelse)
 *
 *
 *     44 = standard (Standard)
 *
 *
 *     45 = nonMeasurableAreas (Ikke målbare arealer)
 *
 *
 *     46 = commonAreasAndRightsToUse (Fellesarealer og rettigheter til bruk)
 *
 *
 *     47 = valuatorCommentsToArea (Bygningssakkyndiges kommentar til arealoppmåling)
 *
 *
 *     48 = areas (Arealbeskrivelser)
 *
 *
 *     49 = deleteDocument (Referanse til dokument som skal slettes)
 *
 *
 *     50 = solarConditions (Solforhold for eiendom)
 *
 *
 *     51 = localAreaName (Områdebenevnelse)
 *
 *
 *     52 = apartmentNumber (Leilighetsnummer)
 *
 *
 *     53 = yearlyLeaseFee (Årlig festeavgift)
 *
 *
 *     54 = leasingContractDate (Festekontrakt datert)
 *
 *
 *     55 = regulationOfLeaseExpense (Regulering av festeavgift)
 *
 *
 *     56 = leasePeriod (Festetid)
 *
 *
 *     57 = leasingPartyTransportFee (Bortfesters transportgebyr)
 *
 *
 *     58 = redemptionTerms (Innløsningsvilkår festekontrakt)
 *
 *
 *     59 = locationDescription (Beliggenhet)
 *
 *
 *     60 = directions (Adkomst)
 *
 *
 *     61 = nurserySchoolLeisure (Barnehage/skole/fritid)
 *
 *
 *     62 = schoolDistrict (Skolekrets)
 *
 *
 *     63 = publicTransport (Offentlig kommunikasjon)
 *
 *
 *     64 = sellersDeclaration (Sammendrag selgers egenerklæring)
 *
 *
 *     65 = yearOfConstruction (Byggeår)
 *
 *
 *     66 = amountOfFloors (Etasje)
 *
 *
 *     67 = amountOfBedrooms (Soverom)
 *
 *
 *     68 = amountOfRooms (Antall rom)
 *
 *
 *     69 = amountOfBathRooms (Antall bad)
 *
 *
 *     70 = heading (Overskrift)
 *
 *
 *     71 = about (Kort om eiendommen)
 *
 *
 *     72 = contents (Innhold)
 *
 *
 *     73 = movableProperty (Innbo og løsøre)
 *
 *
 *     74 = whiteGoods (Hvitevarer)
 *
 *
 *     75 = renovatedYear (Modernisert/påkostet år)
 *
 *
 *     76 = renovated (Moderniseringer og påkostninger)
 *
 *
 *     77 = tvInternetBroadband (TV/Internett/Bredbånd)
 *
 *
 *     78 = parking (Parkering)
 *
 *
 *     79 = radonMeasurement (Radonmåling)
 *
 *
 *     80 = various (Diverse)
 *
 *
 *     81 = heating (Oppvarming)
 *
 *
 *     82 = powerConsumption (Info strømbruk)
 *
 *
 *     83 = energyClassLetter (Energikarakter (A-G))
 *
 *
 *     84 = energyClassNumber (Oppvarmingskarakter (1-5))
 *
 *
 *     85 = energy (Info energiklasse)
 *
 *
 *     86 = municipalTaxesYear (Kommunale avgifter år)
 *
 *
 *     87 = propertyTax (Eiendomsskatt)
 *
 *
 *     88 = propertyTaxYear (Eiendomsskatt år)
 *
 *
 *     89 = propertyTaxInformation (Info eiendomsskatt)
 *
 *
 *     90 = assetValuePrimary (Formuesverdi primær)
 *
 *
 *     91 = assetValuePrimaryYear (Formuesverdi primær år)
 *
 *
 *     92 = assetValueSecondary (Formuesverdi sekundær)
 *
 *
 *     93 = assetValueSecondaryYear (Formuesverdi sekundær år)
 *
 *
 *     94 = assetValueInformation (Info formuesverdi)
 *
 *
 *     95 = otherFees (Andre avgifter)
 *
 *
 *     96 = welfareAssociationFee (Årlig velavgift)
 *
 *
 *     97 = welfareAssociation (Velforening)
 *
 *
 *     98 = waterTax (Vannavgift)
 *
 *
 *     99 = waterTaxYear (Vannavgift år)
 *
 *
 *     100 = waterTaxInformation (Info vannavgift)
 *
 *
 *     101 = loanFinancingOffer (Tilbud på lånefinansering)
 *
 *
 *     102 = completionReportDated (Ferdigattest/bruktstillatelse datert)
 *
 *
 *     103 = landOwnerEstateDocumentDate (Grunnboksdato)
 *
 *
 *     104 = completionReport (Ferdigattest/brukstillatelse)
 *
 *
 *     105 = roadWaterSewage (Vei, vann og avløp)
 *
 *
 *     106 = regulationAndAreaPlans (Regulerings- og arealplaner)
 *
 *
 *     107 = accessToRental (Adgang til utleie)
 *
 *
 *     108 = publicLawOrder (Offentligrettslig pålegg)
 *
 *
 *     109 = easementsAndDeclarations (Tinglyste heftelser og rettigheter)
 *
 *
 *     110 = protectionClassSefrak (Verneklasse/Sefrak)
 *
 *
 *     111 = legalPledge (Legalpant)
 *
 *
 *     112 = commentConcession (Kommentar konsesjon)
 *
 *
 *     113 = commentLiveDuty (Kommentar boplikt)
 *
 *
 *     114 = commentManagementDuty (Kommentar driveplikt)
 *
 *
 *     115 = commentPropertyRights (Kommentar odelsrett)
 *
 *
 *     116 = takeOver (Overtakelse)
 *
 *
 *     117 = purchaseCosts (Omkostninger kjøper beskrivelse)
 *
 *
 *     118 = homeBuyerInsurance (Boligkjøperforsikring)
 *
 *
 *     119 = bidding (Budgivning)
 *
 *
 *     120 = purchaseCostsAmount (Kjøpsomkostninger beløp)
 *
 *
 *     121 = moneyLaunderingRules (Hvitvaskingsreglene)
 *
 *
 *     122 = salesProspectusAttachment (Vedlegg salgsoppgave)
 *
 *
 *     123 = homeSellerInsurance (Boligselgerforsikring)
 *
 *
 *     124 = estateOfDeceased (Dødsbo)
 *
 *
 *     125 = otherInsurances (Andre forsikringer)
 */

export enum VitecNextImportType {
  None = 0,
  AccountingBudgeting = 1,
  AmountYearsInstallmentFree = 2,
  Animals = 3,
  BoardApproval = 4,
  CommunityFee = 5,
  ConstructionMethod = 6,
  GuaranteeFund = 7,
  InsuranceCompany = 8,
  InsurancePolicy = 9,
  JointCostAfterGracePeriod = 10,
  JointDebtDescription = 11,
  JointDebtInstallment = 12,
  JointDebtInterest = 13,
  LoanTermsCollectiveDebt = 14,
  PartAbout = 15,
  Preemptive = 16,
  RentIncludes = 17,
  ResidentCommitments = 18,
  Statutes = 19,
  ValuationType = 20,
  EstateCorporationPartialBondNumber = 21,
  PartNumber = 22,
  ShareJointDebtYear = 23,
  ChangeOfOwnershipFee = 24,
  CollectiveDebt = 25,
  CommunityTax = 26,
  Deposit = 27,
  EstateCorporationPartialDenominatedBond = 28,
  EstimatedValue = 29,
  LoanFare = 30,
  PreemptiveFee = 31,
  RentPrMonth = 32,
  ShareJointCapital = 33,
  HasBoardApproval = 34,
  HasPreemptiveRight = 35,
  PreemptiveDeadline = 36,
  ShareJointCapitalDate = 37,
  ShareJointDebtToDate = 38,
  ValuationDate = 39,
  EstateHousingCooperativeStockHousingUnitNumber = 40,
  ValuationTypeEnum = 41,
  PlotSize = 42,
  PlotDescription = 43,
  Standard = 44,
  NonMeasurableAreas = 45,
  CommonAreasAndRightsToUse = 46,
  ValuatorCommentsToArea = 47,
  Areas = 48,
  DeleteDocument = 49,
  SolarConditions = 50,
  LocalAreaName = 51,
  ApartmentNumber = 52,
  YearlyLeaseFee = 53,
  LeasingContractDate = 54,
  RegulationOfLeaseExpense = 55,
  LeasePeriod = 56,
  LeasingPartyTransportFee = 57,
  RedemptionTerms = 58,
  LocationDescription = 59,
  Directions = 60,
  NurserySchoolLeisure = 61,
  SchoolDistrict = 62,
  PublicTransport = 63,
  SellersDeclaration = 64,
  YearOfConstruction = 65,
  AmountOfFloors = 66,
  AmountOfBedrooms = 67,
  AmountOfRooms = 68,
  AmountOfBathRooms = 69,
  Heading = 70,
  About = 71,
  Contents = 72,
  MovableProperty = 73,
  WhiteGoods = 74,
  RenovatedYear = 75,
  Renovated = 76,
  TvInternetBroadband = 77,
  Parking = 78,
  RadonMeasurement = 79,
  Various = 80,
  Heating = 81,
  PowerConsumption = 82,
  EnergyClassLetter = 83,
  EnergyClassNumber = 84,
  Energy = 85,
  MunicipalTaxesYear = 86,
  PropertyTax = 87,
  PropertyTaxYear = 88,
  PropertyTaxInformation = 89,
  AssetValuePrimary = 90,
  AssetValuePrimaryYear = 91,
  AssetValueSecondary = 92,
  AssetValueSecondaryYear = 93,
  AssetValueInformation = 94,
  OtherFees = 95,
  WelfareAssociationFee = 96,
  WelfareAssociation = 97,
  WaterTax = 98,
  WaterTaxYear = 99,
  WaterTaxInformation = 100,
  LoanFinancingOffer = 101,
  CompletionReportDated = 102,
  LandOwnerEstateDocumentDate = 103,
  CompletionReport = 104,
  RoadWaterSewage = 105,
  RegulationAndAreaPlans = 106,
  AccessToRental = 107,
  PublicLawOrder = 108,
  EasementsAndDeclarations = 109,
  ProtectionClassSefrak = 110,
  LegalPledge = 111,
  CommentConcession = 112,
  CommentLiveDuty = 113,
  CommentManagementDuty = 114,
  CommentPropertyRights = 115,
  TakeOver = 116,
  PurchaseCosts = 117,
  HomeBuyerInsurance = 118,
  Bidding = 119,
  PurchaseCostsAmount = 120,
  MoneyLaunderingRules = 121,
  SalesProspectusAttachment = 122,
  HomeSellerInsurance = 123,
  EstateOfDeceased = 124,
  OtherInsurances = 125
}
