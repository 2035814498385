export enum CloudFunction {
  deleteAccount = 'deleteAccount',
  createAuthenticationTokenForLoginToken = 'createAuthenticationTokenForLoginToken',
  createLoginWithCodeToken = 'createLoginWithCodeToken',
  processExternalSession = 'processExternalSession',
  createSessionForUnpayedSession = 'createSessionForUnpayedSession',
  getStripeSubscriptions = 'getStripeSubscriptions',
  createAuthenticationTokenForUid = 'createAuthenticationTokenForUid',
  deliverViteNOCaptions = 'deliverViteNOCaptions',
  isVitecNextProductEnabled = 'isVitecNextProductEnabled',
  getEstateForVitecNextOrder = 'getEstateForVitecNextOrder',
  fulfillLeopardOrder = 'fulfillLeopardOrder',
  onVitecNextOrderCreated = 'onVitecNextOrderCreated',
  areGazelleProductsEnabled = 'areGazelleProductsEnabled',
  onTryCreateJaguarOrderAsAdmin = 'onTryCreateJaguarOrderAsAdmin',
  tryCreateJaguarOrder = 'tryCreateJaguarOrder',
  onJaguarEstateWorkflowUpdated = 'onJaguarEstateWorkflowUpdated',
  slackFeedback = 'slackFeedback',
  createLeopardOrdersForVismaOrder = 'createLeopardOrdersForVismaOrder',
  onNewVitecNextDocumentDownloaded = 'onNewVitecNextDocumentDownloaded'
}

export enum StripeCloudFunction {
  createStripePortalLink = 'ext-firestore-stripe-payments-createPortalLink'
}

export default CloudFunction
