import styled from '@emotion/styled'
import { TextareaAutosize as MaterialTextAreaAutoSize } from '@mui/base'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import React from 'react'
import { VitecNextProductPureInfoItems } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Colors, { LightGrey } from '../../../../pure/libs/Colors'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import Box from './Box'
import DiffLines from './DiffLines'
import { LoadingPage } from './LoadingPage'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export const VitecNextOrderProductDiff: React.FC<VitecNextOrderProductProps> = (props) => {
  const { product: vitecNextProduct } = props
  const item = VitecNextProductInfoItems[vitecNextProduct]
  const newPureItem = VitecNextProductPureInfoItems[vitecNextProduct]

  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))
  const { data: jaguarResults, isLoading } = useJaguarResult(leopardOrder)

  if (isLoading) return <LoadingPage />
  if (!newPureItem) return <Box />
  if (!newPureItem.vitecNextProductExperimental) return <Box />

  const newItem = VitecNextProductInfoItems[newPureItem.vitecNextProductExperimental]

  const base = item.getText?.({ jaguarResults }) || ''
  const newText = newItem.getText?.({ jaguarResults }) || ''

  return <DiffLines base={base.trim()} newText={newText.trim()} />
}

export const Textarea = (props: TextFieldProps) => {
  return <TextareaAutoSize autoFocus {...props} />
}

const TextareaAutoSize = styled(MaterialTextAreaAutoSize)({
  border: `1px solid ${LightGrey}`,
  outline: 'none',
  resize: 'none',
  borderRadius: BorderRadixes.regular,
  color: Colors.primary100
}) as any
