import { useEffect } from 'react'
import { AreGazelleProductsEnabledResponse } from '../../../../pure/leopard/AreGazelleProductsEnabledResponse'
import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { Product } from '../../../../pure/leopard/Product'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import { getFromMemoryCache, withMemoryCache } from '../../../../pure/libs/withMemoryCache'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { areGazelleProductsEnabled } from '../libs/CloudFunctionsApiHandler'
import { captureException } from '../libs/ErrorHelper'
import useAppState, { State } from './useAppState'
import { useState } from './useState'

type IsProductsEnabledResponse = {
  [key in Product]: IsVitecNextProductEnabledResponse | undefined
}

export function useAreGazelleProductsEnabled({
  externalEstateId,
  enable = true
}: {
  externalEstateId?: string
  enable?: boolean
}) {
  const { state } = useAppState()

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<IsProductsEnabledResponse>(undefined)

  useEffect(() => {
    if (!externalEstateId) return
    if (!enable) return

    createAreGazelleProductsEnabledFunction({
      externalEstateId,
      state
    })()
      .then((result) => {
        setData(result)
        eventEmitter.emit(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED)
      })
      .catch((error) => captureException(error))
      .finally(() => setIsLoading(false))
  }, [enable, externalEstateId])

  return { data, isLoading }
}

export function useAreProductsEnabledCache({ externalEstateId }: { externalEstateId: string }) {
  const { state } = useAppState()
  const [data, setData] = useState<AreGazelleProductsEnabledResponse | undefined>(undefined)
  useEffect(() => {
    if (!externalEstateId) return

    setData(getFromMemoryCache(getAreGazelleProductsEnabledCacheKey(externalEstateId)))

    eventEmitter.on(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED, () =>
      createAreGazelleProductsEnabledFunction({ externalEstateId, state })().then((result) => setData(result))
    )

    return () => {
      eventEmitter.removeAllListeners(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED)
    }
  }, [externalEstateId])

  return { data, isLoading: false }
}

export function useIsProductEnabled(props: VitecNextOrderProductProps) {
  const { state } = useAppState()
  const { externalEstateId, product } = props
  const areProductsEnabled: AreGazelleProductsEnabledResponse | undefined = getFromMemoryCache(
    getAreGazelleProductsEnabledCacheKey(props.externalEstateId)
  )
  const [data, setData] = useState<IsVitecNextProductEnabledResponse | undefined>(areProductsEnabled?.[props.product])

  useEffect(() => {
    if (!externalEstateId) return
    const listener = () => {
      createAreGazelleProductsEnabledFunction({ externalEstateId, state })().then((result) =>
        setData(result[props.product])
      )
    }

    eventEmitter.on(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED, listener)

    return () => {
      eventEmitter.removeListener(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED, listener)
    }
  }, [product, externalEstateId])

  return { data, isLoading: false }
}

function createAreGazelleProductsEnabledFunction({
  externalEstateId,
  state
}: {
  externalEstateId: string
  state: State
}) {
  return withMemoryCache(
    () => areGazelleProductsEnabled({ externalEstateId }, state),
    getAreGazelleProductsEnabledCacheKey(externalEstateId)
  )
}

function getAreGazelleProductsEnabledCacheKey(externalEstateId: string): string {
  return `areGazelleProductsEnabled_${externalEstateId}`
}
