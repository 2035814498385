import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import Box from './Box'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import Loader from './Loader'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export function DashboardListItemDetailsTextComponent<T>(props: VitecNextOrderProductProps) {
  const { externalEstateId, product } = props
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId({ externalEstateId, product }))

  const { data: jaguarResults, isLoading: isLoadingJaguarResults } = useJaguarResult(leopardOrder)

  const text = VitecNextProductInfoItems[props.product].getText?.({
    jaguarResults,
    leopardOrder
  })

  if (isLoadingJaguarResults) return <Loader />
  if (!leopardOrder) return <Box />

  return (
    <FigmaTextWithStyle
      Component={FigmaTextWithLinebreaks as any}
      separator={<span />}
      separatorWhenEmptyContent={<br />}
      type="body1"
      text={text?.trim() || ''}
    />
  )
}
