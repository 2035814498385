import styled from '@emotion/styled'
import { LightBlue, PrimaryColor } from '../../../../pure/libs/Colors'
import { StepThroughContentField, StepThroughContentItem } from '../../../../pure/libs/StepThroughContentHelper'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { IsSteppingThroughStatusContext } from '../types/IsSteppingThroughStatus'
import Box from './Box'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export function VitecNextPdfDrawerTopComponent(
  props: {
    selectedItem: StepThroughContentItem<StepThroughContentField>
    isSteppingThroughStatusContext: IsSteppingThroughStatusContext
  } & VitecNextOrderProductProps
) {
  const { selectedItem } = props
  if (!selectedItem) return null
  const [isSteppingThroughStatus] = props.isSteppingThroughStatusContext
  if (isSteppingThroughStatus === 'done') return null

  return (
    <LabelContainer fullPadding spacing={smallSpacing}>
      <FigmaTextWithStyle text={`${selectedItem?.label || selectedItem.id} `} type="h4" />
    </LabelContainer>
  )
}

const LabelContainer = styled(Box)`
  background-color: ${LightBlue};
  border-radius: ${BorderRadixes.regular};
  border: 1px solid ${PrimaryColor};
`
