import { useParams } from 'react-router-dom'
import { formatRoutePathForExternalEstateId } from '../../../../pure/libs/RoutePath.js'
import { Layout } from '../components/Layout.js'
import MenuVitecNext from '../components/MenuVitecNext.js'
import { WithAdValidationPdfDrawer } from '../components/WithAdValidationPdfDrawer.js'
import { useDrawerContext } from '../components/useDrawerContext.js'
import { useNavigate } from '../hooks/useNavigate.js'

export const AdValidationPage = () => {
  const { externalEstateId } = useParams()

  const navigate = useNavigate({ enablePreserveQueryParams: false })
  const drawerContext = useDrawerContext('ad_validation', {
    onClose: () => {
      externalEstateId && navigate(formatRoutePathForExternalEstateId({ externalEstateId }))
    }
  })

  if (!externalEstateId) return <Layout enableMenu />

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <WithAdValidationPdfDrawer externalEstateId={externalEstateId} drawerContext={drawerContext} autoOpen />
    </Layout>
  )
}
