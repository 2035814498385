import { PropsWithChildren, useEffect } from 'react'
import { getValidationItemsForResults } from '../../../../pure/libs/AdValidationHelper'
import { AdValidationQueryParams } from '../../../../pure/types/QueryParamTypes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useDefaultPdfViewerPropsForAdValidation } from '../hooks/useDefaultPdfViewerPropsForAdValidation'
import { useJaguarResultsForAdValiation } from '../hooks/useJaguarResults'
import { useVitecNextValidationItems } from '../hooks/useVitecNextValidationItems'
import { inStatusTypeOrder } from '../libs/inStatusTypeOrder'
import useQueryParams from '../libs/useQueryParams'
import Box from './Box'
import { DashboardStatusItemViewPopperRow } from './DashboardStatusItemViewPopperRow'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useClickUpDown } from './useClickUpDown'
import { useDrawerContext } from './useDrawerContext'

export const WithAdValidationPdfDrawer = (
  props: PropsWithChildren<{
    externalEstateId: string
    drawerContext: ReturnType<typeof useDrawerContext>
    autoOpen?: boolean
  }>
) => {
  const { externalEstateId, children, drawerContext, autoOpen = false } = props
  const { drawerState } = drawerContext
  const qp = useQueryParams<AdValidationQueryParams>()

  const { data: pdfViewerPropSets = [] } = useDefaultPdfViewerPropsForAdValidation(externalEstateId)

  const { data: results, isLoading: loading } = useJaguarResultsForAdValiation(externalEstateId)

  const highlightedFieldEntry = Object.entries(getValidationItemsForResults(results) || {}).find(
    ([id, v]) => id === qp.highlightedId
  )

  const highlightedField = highlightedFieldEntry
    ? { id: highlightedFieldEntry[0], bounding_boxes: highlightedFieldEntry[1].bounding_boxes || [] }
    : undefined

  let { data: validationItems = [] } = useVitecNextValidationItems({ externalEstateId })

  validationItems = validationItems.sort(inStatusTypeOrder)

  const LeftComponent =
    validationItems.length > 0
      ? () => (
          <Box>
            {validationItems.sort(inStatusTypeOrder).map((item, key) => (
              <DashboardStatusItemViewPopperRow
                key={key}
                item={item}
                items={validationItems}
                isHighlighted={item.id === drawerState.highlightedField?.id}
                enableCTA={false}
                style={{ padding: item.type === 'success' ? tinySpacing : smallSpacing }}
              />
            ))}
          </Box>
        )
      : undefined

  useEffect(() => {
    // Highlitning a field is broken if validationItems is rerendered, hence highlitning need to be done after
    if (validationItems.length === 0) return
    if (!qp.highlightedId) return
    if (!highlightedField) return
    drawerContext.highlight(highlightedField)
  }, [qp.highlightedId, highlightedField?.id, validationItems.length])

  useEffect(() => {
    if (autoOpen) drawerContext.open()
  }, [autoOpen])

  const items = validationItems.filter((i) => !!i.onClick)
  const selectedId = highlightedField?.id || ''

  useClickUpDown({
    items,
    selectedId,
    onClickDown: (items, currentIndex) => items[currentIndex + 1].onClick?.(),
    onClickUp: (items, currentIndex) => items[currentIndex - 1].onClick?.()
  })

  return (
    <WithPdfDrawer pdfViewerPropSets={pdfViewerPropSets} drawerContext={drawerContext} LeftComponent={LeftComponent}>
      {drawerContext.drawerState.isOpen ? null : children}
    </WithPdfDrawer>
  )
}
