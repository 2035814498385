import dayjs from 'dayjs'
import React from 'react'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import { getAddress } from '../../../../pure/libs/EstateFunctionsPure'
import { getDebugInfoAddress } from '../../../../pure/libs/getDebugInfoAddress'
import { bigSpacing, reallyBigSpacing, tinySpacing } from '../enums/Spacings'
import { useEstate, useJaguarEstate } from '../hooks/QueryHooks'
import useAppStateSession from '../hooks/useAppStateSession'
import useIsMobile from '../hooks/useIsMobile'
import { useAreGazelleProductsEnabled } from '../hooks/useIsProductEnabled'
import { DASHBOARD_MIN_WIDTH } from '../libs/HardCodedSizes'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { DashboardGrid } from './DashboardGrid'
import { DefaultDashboardLayout } from './DashboardLayoutSelectionButton'
import { DashboardList } from './DashboardList'
import { DashboardPreviewDeprecated } from './DashboardPreviewDeprecated'
import { DashboardStatus } from './DashboardStatus'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { Layout } from './Layout'
import MenuVitecNext from './MenuVitecNext'

export const Dashboard: React.FC<{ externalEstateId: string }> = ({ externalEstateId }) => {
  const isMobile = useIsMobile()
  const enablePreview = !isMobile
  const now = dayjs().format()
  const dashboardWidth = Math.min(window.innerWidth, DASHBOARD_MIN_WIDTH)
  const { data: jaguarEstate } = useJaguarEstate(externalEstateId)

  const { data: estate } = useEstate(externalEstateId)
  const { state } = useAppStateSession()
  const { dashboardLayout = DefaultDashboardLayout } = state

  // This is the main call to the backend dont remove
  const { isLoading: isLoadingAreProductsEnabled } = useAreGazelleProductsEnabled({
    externalEstateId
  })

  const isLoading = isLoadingAreProductsEnabled

  let address: string | undefined = getDebugInfoAddress(getAddress({ estate }))
  if (address.includes('Eksempel')) address = undefined

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth align="center" spacing={bigSpacing} top bottom>
        <Box width={`${dashboardWidth}px`} left right spacing={isMobile ? tinySpacing : reallyBigSpacing}>
          <Box>
            <FigmaText textKey={HardCodedTexts.orderViewHeader} text={address} />
            {!!address && (
              <FigmaTextWithStyle
                type="h9"
                color="white"
                text={`🗓️ ${dayjs(jaguarEstate?.updated_at?.toMillis()).format(YYYYMMDD_HHmm)}`}
              />
            )}
          </Box>
          <Box top fullWidth direction="row" align="center" justify="space-between">
            <Box>
              <FigmaText textKey={Texts.dashboardFeaturesHeader} />
            </Box>
            {enablePreview && (
              <Box>
                <DashboardPreviewDeprecated />
              </Box>
            )}
          </Box>
          <Box top fullWidth>
            <DashboardStatus externalEstateId={externalEstateId} isLoading={isLoading} />
          </Box>
          <Box fullWidth top>
            {dashboardLayout === 'grid' && <DashboardGrid externalEstateId={externalEstateId} now={now} />}
            {dashboardLayout === 'list' && <DashboardList externalEstateId={externalEstateId} now={now} />}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
