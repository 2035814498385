import { SelfDeclarationResultsResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { formatSelfDeclarationItem } from '../libs/SelfDeclarationHelper'
import { Texts } from '../libs/Texts'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import Box from './Box'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'

export const DashboardListItemDetailsSelfDeclarationComponent = (props: VitecNextOrderProductProps) => {
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))
  const { data: jaguarResults } = useJaguarResult<SelfDeclarationResultsResponse>(leopardOrder)

  const items = jaguarResults?.structured_outputs?.items || []

  return (
    <Box fullWidth>
      {items.map((item, i) => {
        const { answer, question } = formatSelfDeclarationItem(item)

        if (!answer) return null

        return (
          <Box top fullWidth key={i} spacing={tinySpacing}>
            <Box left spacing={smallSpacing} fullWidth>
              <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} text={question} />
              <Box>
                <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={answer} />
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
