import styled from '@emotion/styled'
import {
  ConstructionMethodWarning,
  JaguarResultResponse
} from '../../../../pure/leopard/JaguarTypesDerived'
import { Yellow } from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

type JaguarWarning = ConstructionMethodWarning

export const VitecNextOrderProductStatusJaguarWarning = (props: { results?: JaguarResultResponse }) => {
  const { results } = props
  const warnings = results?.warnings as JaguarWarning[]

  if (!warnings || warnings.length === 0) return null

  return (
    <Container fullWidth fullPadding spacing={smallSpacing} direction="row">
      <Box fullPadding spacing={tinySpacing}>
        <FigmaImageContainer imageKey={Images.whiteExclamation} />
      </Box>
      <Box fullWidth left spacing={smallSpacing}>
        <FigmaTextWithStyle type="body3" text={getFigmaText(Texts.dashboardPartialResult)} />
        {warnings.map((warning, key) => (
          <FigmaText
            key={key}
            styleTextKey={Texts.jaguarWarningsbuildingComponents}
            text={`• ${getTextForWarning(warning)}`}
          />
        ))}
      </Box>
    </Container>
  )
}

const getTextForWarning = (warning: JaguarWarning) => {
  const textKey = getTextKeyForWarning(warning)
  // TODO WRITE TEST: This is a temporary fix for the missing text keys
  const defaultWarning = warning.replace(/_failed/g, '')
  if (!textKey) return defaultWarning

  return getFigmaText(textKey) || defaultWarning
}

const getTextKeyForWarning = (warning: JaguarWarning) => {
  switch (warning) {
    case 'building_components_failed':
      return Texts.jaguarWarningsbuildingComponents
    case 'electrical_system_failed':
      return Texts.jaguarWarningselectricalSystem
    case 'condition_failed':
      return Texts.jaguarWarningscondition
  }
}

const Container = styled(Box)`
  background-color: ${Yellow};
  border-radius: ${BorderRadixes.regular};
`
