/**
 * Format: int32
 * @description
 *
 *
 *     1 = accountingBudgeting (Regnskap/budsjett/kostnadsøkninger)
 *
 *
 *     2 = amountYearsInstallmentFree (Avdragsfrie år)
 *
 *
 *     3 = animals (Husdyrhold)
 *
 *
 *     4 = boardApproval (Styregodkjennelse)
 *
 *
 *     5 = communityFee (Kommentar kommunale avgifter)
 *
 *
 *     6 = constructionMethod (Byggemåte)
 *
 *
 *     7 = guaranteeFund (Sikringsordning fellesgjeld)
 *
 *
 *     8 = insuranceCompany (Borettslagets forsikringsselskap. MaxLength(255))
 *
 *
 *     9 = insurancePolicy (Polisenummer felles forsikring. MaxLength(255))
 *
 *
 *     10 = jointCostAfterGracePeriod (Felleskostnader etter avdragsfri periode)
 *
 *
 *     11 = jointDebtDescription (Kommentar fellesgjeld)
 *
 *
 *     12 = jointDebtInstallment (Avdrag fellesgjeld)
 *
 *
 *     13 = jointDebtInterest (Rentekostnader fellesgjeld)
 *
 *
 *     14 = loanTermsCollectiveDebt (Lånebetingelser fellesgjeld)
 *
 *
 *     15 = partAbout (Om boligselskapet (borettslaget, sameiet eller aksjeselskapet))
 *
 *
 *     16 = preemptive (Forkjøpsrett)
 *
 *
 *     17 = rentIncludes (Felleskostnader inkluderer)
 *
 *
 *     18 = residentCommitments (Beboernes forpliktelser og dugnader)
 *
 *
 *     19 = statutes (Vedtekter/husordensregler)
 *
 *
 *     20 = valuationType (Type rapport. Deprecated. Erstattet av enumFields f.eks. "enumFields": [{"type":1, "reportType":6}])
 *
 *
 *     21 = plotDescription (Tomtebeskrivelse)
 *
 *
 *     22 = standard (Standard)
 *
 *
 *     23 = nonMeasurableAreas (Ikke målbare arealer)
 *
 *
 *     24 = commonAreasAndRightsToUse (Fellesarealer og rettigheter til bruk)
 *
 *
 *     25 = valuatorCommentsToArea (Bygningssakkyndiges kommentar til arealoppmåling)
 *
 *
 *     26 = deleteDocument (Referanse til dokument som skal slettes)
 *
 *
 *     27 = solarConditions (Solforhold for eiendom)
 *
 *
 *     28 = localAreaName (Områdebenevnelse. MaxLength(40))
 *
 *
 *     29 = apartmentNumber (Leilighetsnummer. MaxLength(15))
 *
 *
 *     30 = regulationOfLeaseExpense (Regulering av festeavgift)
 *
 *
 *     31 = leasePeriod (Festetid)
 *
 *
 *     32 = redemptionTerms (Innløsningsvilkår festekontrakt)
 *
 *
 *     33 = locationDescription (Beliggenhet)
 *
 *
 *     34 = directions (Adkomst)
 *
 *
 *     35 = nurserySchoolLeisure (Barnehage/skole/fritid)
 *
 *
 *     36 = schoolDistrict (Skolekrets)
 *
 *
 *     37 = publicTransport (Offentlig kommunikasjon)
 *
 *
 *     38 = sellersDeclaration (Sammendrag selgers egenerklæring)
 *
 *
 *     39 = heading (Overskrift. MaxLength(255))
 *
 *
 *     40 = about (Kort om eiendommen)
 *
 *
 *     41 = contents (Innhold)
 *
 *
 *     42 = movableProperty (Innbo og løsøre)
 *
 *
 *     43 = whiteGoods (Hvitevarer)
 *
 *
 *     44 = renovatedYear (Modernisert/påkostet år)
 *
 *
 *     45 = renovated (Moderniseringer og påkostninger)
 *
 *
 *     46 = tvInternetBroadband (TV/Internett/Bredbånd)
 *
 *
 *     47 = parking (Parkering)
 *
 *
 *     48 = radonMeasurement (Radonmåling)
 *
 *
 *     49 = various (Diverse)
 *
 *
 *     50 = heating (Oppvarming)
 *
 *
 *     51 = powerConsumption (Info strømforbruk)
 *
 *
 *     52 = energy (Info energiklasse)
 *
 *
 *     53 = propertyTaxInformation (Info eiendomsskatt)
 *
 *
 *     54 = assetValueInformation (Info formuesverdi)
 *
 *
 *     55 = otherFees (Andre avgifter)
 *
 *
 *     56 = welfareAssociation (Velforening)
 *
 *
 *     57 = waterTaxInformation (Info vannavgift)
 *
 *
 *     58 = loanFinancingOffer (Tilbud på lånefinansering)
 *
 *
 *     59 = completionReport (Ferdigattest/brukstillatelse)
 *
 *
 *     60 = roadWaterSewage (Vei, vann og avløp)
 *
 *
 *     61 = regulationAndAreaPlans (Regulerings- og arealplaner)
 *
 *
 *     62 = accessToRental (Adgang til utleie)
 *
 *
 *     63 = publicLawOrder (Offentligrettslig pålegg)
 *
 *
 *     64 = easementsAndDeclarations (Tinglyste heftelser og rettigheter)
 *
 *
 *     65 = protectionClassSefrak (Verneklasse/Sefrak)
 *
 *
 *     66 = legalPledge (Legalpant)
 *
 *
 *     67 = commentConcession (Kommentar konsesjon)
 *
 *
 *     68 = commentLiveDuty (Kommentar boplikt)
 *
 *
 *     69 = commentManagementDuty (Kommentar driveplikt)
 *
 *
 *     70 = commentPropertyRights (Kommentar odelsrett)
 *
 *
 *     71 = takeOver (Overtakelse)
 *
 *
 *     72 = purchaseCosts (Omkostninger kjøper beskrivelse)
 *
 *
 *     73 = homeBuyerInsurance (Boligkjøperforsikring)
 *
 *
 *     74 = bidding (Budgivning)
 *
 *
 *     75 = moneyLaunderingRules (Hvitvaskingsreglene)
 *
 *
 *     76 = salesProspectusAttachment (Vedlegg salgsoppgave)
 *
 *
 *     77 = homeSellerInsurance (Boligselgerforsikring)
 *
 *
 *     78 = estateOfDeceased (Dødsbo)
 *
 *
 *     79 = otherInsurances (Andre forsikringer)*/

export enum VitecNextImportTextType {
  AccountingBudgeting = 1,
  AmountYearsInstallmentFree = 2,
  Animals = 3,
  BoardApproval = 4,
  CommunityFee = 5,
  ConstructionMethod = 6,
  GuaranteeFund = 7,
  InsuranceCompany = 8,
  InsurancePolicy = 9,
  JointCostAfterGracePeriod = 10,
  JointDebtDescription = 11,
  JointDebtInstallment = 12,
  JointDebtInterest = 13,
  LoanTermsCollectiveDebt = 14,
  PartAbout = 15,
  Preemptive = 16,
  RentIncludes = 17,
  ResidentCommitments = 18,
  Statutes = 19,
  ValuationType = 20,
  PlotDescription = 21,
  Standard = 22,
  NonMeasurableAreas = 23,
  CommonAreasAndRightsToUse = 24,
  ValuatorCommentsToArea = 25,
  DeleteDocument = 26,
  SolarConditions = 27,
  LocalAreaName = 28,
  ApartmentNumber = 29,
  RegulationOfLeaseExpense = 30,
  LeasePeriod = 31,
  RedemptionTerms = 32,
  LocationDescription = 33,
  Directions = 34,
  NurserySchoolLeisure = 35,
  SchoolDistrict = 36,
  PublicTransport = 37,
  SellersDeclaration = 38,
  Heading = 39,
  About = 40,
  Contents = 41,
  MovableProperty = 42,
  WhiteGoods = 43,
  RenovatedYear = 44,
  Renovated = 45,
  TvInternetBroadband = 46,
  Parking = 47,
  RadonMeasurement = 48,
  Various = 49,
  Heating = 50,
  PowerConsumption = 51,
  Energy = 52,
  PropertyTaxInformation = 53,
  AssetValueInformation = 54,
  OtherFees = 55,
  WelfareAssociation = 56,
  WaterTaxInformation = 57,
  LoanFinancingOffer = 58,
  CompletionReport = 59,
  RoadWaterSewage = 60,
  RegulationAndAreaPlans = 61,
  AccessToRental = 62,
  PublicLawOrder = 63,
  EasementsAndDeclarations = 64,
  ProtectionClassSefrak = 65,
  LegalPledge = 66,
  CommentConcession = 67,
  CommentLiveDuty = 68,
  CommentManagementDuty = 69,
  CommentPropertyRights = 70,
  TakeOver = 71,
  PurchaseCosts = 72,
  HomeBuyerInsurance = 73,
  Bidding = 74,
  MoneyLaunderingRules = 75,
  SalesProspectusAttachment = 76,
  HomeSellerInsurance = 77,
  EstateOfDeceased = 78,
  OtherInsurances = 79
}
