import { useEffect } from 'react'
import { VismaLandingQueryParams } from '../../../../pure/types/QueryParamTypes'
import { LayoutLoadingPage } from '../components/LoadingPage'
import useAppState from '../hooks/useAppState'
import { createLeopardOrdersForVismaOrder } from '../libs/CloudFunctionsApiHandler'
import useQueryParams from '../libs/useQueryParams'
import { formatRoutePathForExternalEstateId } from '../../../../pure/libs/RoutePath'

export const VismaCoreLandingPage = () => {
  const { state } = useAppState()
  const qp = useQueryParams<VismaLandingQueryParams>()

  useEffect(() => {
    createLeopardOrdersForVismaOrder(qp, state).then(
      ({ externalEstateId }) => (window.location.href = formatRoutePathForExternalEstateId({ externalEstateId }))
    )
  }, [])

  return <LayoutLoadingPage />
}
