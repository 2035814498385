import invariant from 'invariant'
import { BasicAssociationResultsResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { groupBy } from '../../../../pure/libs/Common'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { PdfViewerProps } from '../components/PdfViewer'

export function getPdfViewerPropSetsForBasicAssociationResults({
  result,
  leopardOrder
}: {
  leopardOrder?: LeopardOrder
  result?: BasicAssociationResultsResponse
}): Partial<PdfViewerProps>[] {
  if (!result?.structured_outputs?.basic_association_info) return []

  const boundingBoxes = [
    ...Object.values(result?.structured_outputs?.basic_association_info || {}).flatMap((v) => v?.bounding_boxes || []),
    ...Object.values(result?.structured_outputs?.about_association?.items || {}).flatMap((v) => v?.bounding_boxes || [])
  ]

  const items = Object.entries(groupBy(boundingBoxes, 'filename'))

  if (!!result && items.length === 0)
    return Object.values(leopardOrder?.documentUrls || {}).map((gsUrl) => ({
      gsUrl,
      boundingBoxes: []
    }))

  return items.map(([fileName, boundingBoxes]) => {
    const gsUrl = result?.input_files?.find(({ uri }) => !!fileName && uri.includes(fileName))?.uri
    invariant(gsUrl, '!gsUrl')
    return {
      gsUrl,
      boundingBoxes
    }
  })
}
