import { isProcessingUpload } from '../libs/VitecNextOrderItemHelper'
import { WorkflowId } from '../types/JaguarEstate'
import { JaguarResultResponse } from './JaguarTypesDerived'
import { Product } from './Product'

type VitexNextProductPureInfoItem = {
  enableSendOnDoneEmail?: boolean
  vitecNextProductExperimental?: Product
  workflowIds: WorkflowId[]
}

export const VitecNextProductPureInfoItems: {
  [key in Product]: VitexNextProductPureInfoItem
} = {
  [Product.AREA_DESCRIPTION]: {
    workflowIds: ['area-description']
  },
  [Product.DESCRIPTIONS]: {
    workflowIds: ['descriptions'],
    enableSendOnDoneEmail: true
  },
  [Product.IMAGE_CAPTIONS]: {
    workflowIds: ['image-captions'],
    enableSendOnDoneEmail: true
  },
  [Product.CONSTRUCTION_INFO]: {
    workflowIds: ['construction-method']
  },
  [Product.BASIC_ASSOCIATION]: {
    workflowIds: ['basic-association'],
    enableSendOnDoneEmail: true
  },
  [Product.SELF_DECLERATION]: {
    workflowIds: ['self-declaration']
  },
  [Product.LAND_REGISTRY]: {
    workflowIds: ['land-registry']
  },
  [Product.AD_VALIDATION]: {
    workflowIds: ['municipal-info', 'tilstandsrapport', 'basic-association']
  }
}

export function shouldShowLongWaitMessage({ results, product }: { results?: JaguarResultResponse; product: Product }) {
  return shouldSendOnUploadDoneInfoEmail({ product }) && isProcessingUpload(results)
}

export function shouldSendOnUploadDoneInfoEmail({ product }: { product: Product }) {
  return VitecNextProductPureInfoItems[product]?.enableSendOnDoneEmail
}
