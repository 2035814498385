import invariant from 'invariant'
import { getValidatableItems, isValidAdValidationValue } from '../../../../pure/libs/AdValidationHelper'
import { createUniqueId } from '../../../../pure/libs/Common'
import RoutePath, { formatUrlForAdValidationItem } from '../../../../pure/libs/RoutePath'
import { Estate } from '../../../../pure/types/Estate'
import { JaguarResultsObject } from '../../../../pure/types/JaguarResultsObject'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledInfo'
import { JaguarWorkflowAdValidationItems } from '../libs/JaguarWorkflowAdValiationItems'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextItemsValidationArgs, VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { DrawerContext } from './useDrawerContext'

export const getVitecNextAdValidationItems = (props: {
  estate?: Estate
  drawerContext: DrawerContext
  results: JaguarResultsObject
}): VitecNextProductInfoItemValidationItem[] => {
  const { estate, drawerContext, results } = props

  // Add early return for VISMA_CORE source system
  if (estate?.sourceSystem === 'VISMA_CORE') {
    return []
  }

  const vitecNextEstate = estate?.metaData?.vitecNextEstate

  const entries = getValidatableItems({ results })

  const missingFields: VitecNextProductInfoItemValidationItem[] = entries
    .filter(({ adValidationItem, value }) => adValidationItem.getValueFromEstate && !value?.value)
    .filter(({ id }) => !!JaguarWorkflowAdValidationItems[id])
    .map(({ id }) => {
      const infoItem = JaguarWorkflowAdValidationItems[id]

      invariant(infoItem?.textKey, `Cannot find textkey for key ${id}`)
      // TODO WRITE TEST, should warn about missing fields
      return {
        id,
        type: 'warning',
        header: getFigmaText(infoItem.textKey),
        message: formatText(getFigmaText(HardCodedTexts.componentsValidationMessagesDocumentNotFound), [
          getFigmaText(infoItem.textKey)
        ]),
        onClick: undefined,
        ctaText: getFigmaText(Texts.SelfDeclerationSeeReference)
      }
    })

  const invalidFields: VitecNextProductInfoItemValidationItem[] = entries
    .filter(({ id }) => !!JaguarWorkflowAdValidationItems[id])

    // TODO REMOVE TECH DEBT The Following line is also duplicated in triggerAdValidation function on serverside
    .filter(({ value }) => !!value?.value)
    .map((entry) => {
      const { adValidationItem, id, value, items } = entry
      const messageText = getFigmaText(HardCodedTexts.dashboardDashboardListValidationMessageText)
      const infoItem = JaguarWorkflowAdValidationItems[id]
      const estateValue = adValidationItem.getValueFromEstate?.(vitecNextEstate)?.toString()
      const fieldValue = value?.value?.toString()

      const isValid = isValidAdValidationValue({
        entry,
        estate
      })

      invariant(fieldValue, `!fieldValue`)
      invariant(infoItem?.textKey, `Cannot find textKey for key ${id}`)

      const fieldFileName = 'Doc'

      // TODO WRITE TEST, should format the field value before displaying it
      const formattedFieldValue = infoItem?.format?.({ items, estate }) || fieldValue
      const systemName = estate?.sourceSystem === 'VISMA_CORE' ? 'Visma' : 'Next'
      return {
        id,
        type: isValid ? 'success' : 'error',
        message: `${messageText} ${systemName}: ${
          estateValue || ''
        }\n${messageText} ${fieldFileName}: ${formattedFieldValue}`,
        value: formattedFieldValue,
        header: getFigmaText(infoItem.textKey),
        onClick: () => {
          if (!estate) return
          drawerContext.highlight({ id, bounding_boxes: value?.bounding_boxes || [] })

          if (!window.location.href.includes(RoutePath.AD_VALIDATION.split('/')[1]))
            window.location.href = formatUrlForAdValidationItem({ externalEstateId: estate.id, highlightedId: id })
        }
      }
    })

  return missingFields.concat(invalidFields)
}

export const validateVitecNextProductInfoItem: (
  args: VitecNextItemsValidationArgs
) => VitecNextProductInfoItemValidationItem[] = (props) => {
  const { isVitecNextProductEnabled, result, product } = props
  const { code } = isVitecNextProductEnabled || {}
  if (result?.status === 'success') return []
  const header = getFigmaText(VitecNextProductInfoItems[product].textKey)

  if (!!code)
    return [
      {
        id: createUniqueId(),
        type: 'warning',
        header,
        message: IsVitecNextProductEnabledInfo[code].getText(props),
        onClick: undefined
      }
    ]

  return []
}
