import styled from '@emotion/styled'
import dayjs from 'dayjs'
import invariant from 'invariant'
import { useState } from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import Colors from '../../../../pure/libs/Colors'
import { CAST } from '../../../../pure/libs/Consts'
import { DescriptionTypes, DescriptionsFields } from '../../../../pure/libs/JaguarWorkflowDescriptionsItemsPure'
import {
  StepThroughContentField,
  StepThroughContentItem,
  StepThroughContentItemBase,
  getStepThroughContentItems
} from '../../../../pure/libs/StepThroughContentHelper'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useEstate } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { useDescriptionsFieldsState } from '../hooks/useDescriptionsFieldsState'
import { getGsUrlForLeopardOrder } from '../hooks/useDownloadUrl'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { fulfillLeopardOrder } from '../libs/CloudFunctionsApiHandler'
import { STEP_THROUGH_CONTENT_HEIGHT, VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { DescriptionKeysAll, JaguarWorkflowDescriptionsItems } from '../libs/JaguarWorkflowDescriptionsItems'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { IsSteppingThroughStatus } from '../types/IsSteppingThroughStatus'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { JsonView } from './JsonView'
import { PdfViewerProps } from './PdfViewer'
import { VitecNextPdfDrawerBottomComponent } from './VitecNextContructionInfoPdfDrawerBottomComponent'
import { VitecNextPdfDrawerTopComponent } from './VitecNextContructionInfoPdfDrawerTopComponent'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductLeopardField } from './VitecNextOrderProductDescriptionsItem'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextOrderProductStatus } from './VitecNextOrderProductStatus'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useClickPreviousNext } from './useClickPreviousNext'
import { STEP_THROUGH_ITEMS_DRAWER_CONTEXT_EXTRA, useDrawerContext } from './useDrawerContext'

export const VitecNextOrderProductDescriptions = (props: VitecNextOrderProductProps) => {
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)
  const { state } = useAppState()
  const { externalEstateId, product } = props
  const item = VitecNextProductInfoItems[product]
  const drawerContext = useDrawerContext('descriptions', {
    onClose: () => {
      setSelectedIndex(-1)
      setIsSteppingThroughStatus('idle')
    },
    extra: STEP_THROUGH_ITEMS_DRAWER_CONTEXT_EXTRA
  })

  const { data: estate } = useEstate(externalEstateId)

  const [selectedIndex, setSelectedIndex] = useState(-1)

  const isSteppingThroughStatusContext = useState<IsSteppingThroughStatus>('idle')
  const [isSteppingThroughStatus, setIsSteppingThroughStatus] = isSteppingThroughStatusContext

  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { data: jaguarResult } = useJaguarResult(leopardOrder)

  const { fields, setFields } = useDescriptionsFieldsState(externalEstateId)

  const successText = getFigmaText(Texts.standardNotificationUploadComplete)

  const stepThroughItems = getStepThroughContentItems(
    Object.values(fields || {}).flatMap((x) => x.stepThroughItems || [])
  )

  const gsUrl = getGsUrlForLeopardOrder(leopardOrder)
  const pdfViewerPropSets: Partial<PdfViewerProps>[] = [
    {
      gsUrl,
      boundingBoxes: stepThroughItems
        .map((i) => {
          if (!i.field?.bounding_boxes) return []
          return i.field.bounding_boxes
        })
        .flat()
    }
  ]

  const onClickHighlight = (newIndex: number, items: StepThroughContentItem<StepThroughContentField>[]) => {
    if (newIndex >= items.length) {
      setSelectedIndex(-1)
      return
    }

    setIsSteppingThroughStatus('started')
    setSelectedIndex(newIndex)
    const item = items[newIndex]
    invariant(item, '!item')
    drawerContext.highlight({ id: item.id, bounding_boxes: item.field?.bounding_boxes || [] })
  }

  const selectedItem = stepThroughItems[selectedIndex]
  const onClickNext = () => onClickHighlight(selectedIndex + 1, stepThroughItems)

  useClickPreviousNext({
    items: stepThroughItems,
    index: selectedIndex,
    onClickNewIndex: (newIndex) => onClickHighlight(newIndex, stepThroughItems)
  })

  if (!leopardOrder) return <Box />

  const elements =
    isSteppingThroughStatus && drawerContext.drawerState.isOpen
      ? {
          TopElement: (
            <VitecNextPdfDrawerTopComponent
              {...props}
              selectedItem={selectedItem}
              isSteppingThroughStatusContext={isSteppingThroughStatusContext}
            />
          ),
          BottomElement: (
            <VitecNextPdfDrawerBottomComponent
              {...props}
              onClick={() => onClickNext()}
              isSteppingThroughStatusContext={isSteppingThroughStatusContext}
            />
          )
        }
      : {}

  const onClickHighlightItem = (item: StepThroughContentItemBase): void =>
    onClickHighlight(
      stepThroughItems.findIndex((i) => i.id === item.id),
      stepThroughItems
    )

  const onClickUploadKey = (key: string): Promise<unknown> => onClickUploadKeys([key as DescriptionTypes])

  const onClickUploadKeys = async (keys: DescriptionTypes[]) => {
    let checkedFields = keys.reduce((a, k) => {
      const v = fields?.[k]
      invariant(v, 'Cant find field for key %s', k)
      if (v.checked === false) return a
      return {
        ...a,
        [k]: {
          ...v,
          fulfilledAt: null,
          isFulfilling: true
        }
      }
    }, {} as DescriptionsFields)

    setFields({ ...fields, ...checkedFields })

    await fulfillLeopardOrder(
      {
        externalEstateId: leopardOrder.externalEstateId,
        product,
        descriptionsFulfillRequest: {
          fields: checkedFields
        }
      },
      state
    )

    checkedFields = Object.entries(checkedFields || {}).reduce(
      (a, [k, v]) => ({
        ...a,
        [k]: {
          ...v,
          fulfilledAt: dayjs().format(),
          isFulfilling: false
        }
      }),
      {} as DescriptionsFields
    )

    setFields({ ...fields, ...checkedFields })
  }

  return (
    <WithPdfDrawer pdfViewerPropSets={pdfViewerPropSets} drawerContext={drawerContext} {...elements}>
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton {...props} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader {...props} headerTextKey={item.textKey} />
          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons
              {...props}
              fulfillMentSuccessText={successText}
              enableFulfillment={Object.values(fields || {}).some(({ checked }) => checked)}
              enableCopy={false}
              fulfill={async () =>
                onClickUploadKeys(
                  Object.values(fields || {})
                    .filter(({ checked }) => checked)
                    .map(({ key }) => key as DescriptionTypes)
                )
              }
            />
            <Box fullWidth top>
              <VitecNextOrderProductStatus {...props} drawerContext={drawerContext} />
            </Box>
          </Box>
          <Box fullWidth>
            <Box fullWidth top>
              <Container fullWidth fullPadding position="relative">
                <Box
                  fullWidth
                  style={{
                    height: isSteppingThroughStatus === 'started' ? STEP_THROUGH_CONTENT_HEIGHT : undefined,
                    overflow: 'auto'
                  }}
                >
                  <Box direction="row" align="center">
                    <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                  </Box>
                  {DescriptionKeysAll.map((key, i) => {
                    return (
                      <Box top fullWidth key={i}>
                        <VitecNextOrderProductLeopardField
                          fields={fields}
                          setFields={(fields) => setFields(fields as DescriptionsFields)}
                          itemKey={key as DescriptionTypes}
                          enableUpload
                          selectedItem={selectedItem}
                          FieldTemplates={JaguarWorkflowDescriptionsItems}
                          onClickHighlight={onClickHighlightItem}
                          onClickUpload={onClickUploadKey}
                        />
                      </Box>
                    )
                  })}
                </Box>

                {enableDebug && <JsonView data={jaguarResult || {}} />}
              </Container>
            </Box>
          </Box>
        </Box>
        <Box visibility="hidden">
          <VitecNextOrderProductBackButton {...props} />
        </Box>
      </Box>
    </WithPdfDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
