import styled from '@emotion/styled'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { Product } from '../../../../pure/leopard/Product'
import {
  VitecNextProductPureInfoItems,
  shouldShowLongWaitMessage
} from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Colors from '../../../../pure/libs/Colors'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { smallSpacing } from '../enums/Spacings'

import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useIsProductEnabled } from '../hooks/useIsProductEnabled'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaText from './FigmaText'
import { VitecNextOrderItemButton } from './VitecNextOrderItemButton'
import { VitecNextOrderItemIconView } from './VitecNextOrderItemIconView'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useEstate } from '../hooks/QueryHooks'
import dayjs from 'dayjs'

export const VitecNextOrderItem = (props: VitecNextOrderProductProps) => {
  const { externalEstateId, product, now = dayjs().format() } = props
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[product]
  const disableSendOnUploadInfoDone = useFeatureFlag(FeatureFlag.disableSendOnUploadInfoDone)
  const {
    textKey,
    descriptionKey,
    buttons,
    StatusComponent = VitecNextOrderItemStatus,
    DescriptionSuffixComponent = () => null
  } = vitecNextProductInfoItem

  const { data: estate } = useEstate(externalEstateId)
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId({ externalEstateId, product }))

  const { data: results } = useJaguarResult(leopardOrder)

  const { data: isProductEnabled, isLoading: isLoadingProduct } = useIsProductEnabled(props)

  return (
    <Container fullWidth align="center" fullHeight>
      <VitecNextOrderItemIconView product={product} />
      <InnerContainer
        key={vitecNextProductInfoItem.slug}
        spacing={smallSpacing}
        fullPadding
        align="center"
        fullWidth
        fullHeight
        justify="space-between"
      >
        <Box fullWidth align="center" fullPadding spacing={smallSpacing}>
          <Box fullWidth align="center" fullHeight>
            <Box fullWidth>
              <FigmaText textKey={textKey} />
              <Box top spacing={smallSpacing}>
                <FigmaText textKey={descriptionKey} />
                <DescriptionSuffixComponent />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box fullWidth>
          <Box fullWidth>
            <StatusComponent isProductEnabled={isProductEnabled} {...props} now={now} />
          </Box>

          {shouldShowLongWaitMessage({ product, results }) && (
            <Box top spacing={smallSpacing}>
              <VitecNextOrderItemLongWaitFigmaText estate={estate} />
            </Box>
          )}
          <Box pointer alignSelf="flex-start" direction="row" align="center" fullWidth>
            {buttons.map((button, i) => (
              <Box key={i} top spacing={smallSpacing}>
                <VitecNextOrderItemButton
                  externalEstateId={externalEstateId}
                  button={button}
                  product={product}
                  isProductEnabled={isProductEnabled}
                  index={i}
                  now={now}
                  isLoading={isLoadingProduct}
                />
              </Box>
            ))}
          </Box>
          {VitecNextProductPureInfoItems[product].enableSendOnDoneEmail && disableSendOnUploadInfoDone && (
            <Box top spacing={smallSpacing}>
              <FigmaText
                textKey={Texts.standardNotificationLongJobEmail}
                text={'No Email will be sent to end customer'}
              />
            </Box>
          )}
        </Box>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  box-shadow: ${Shadows.large};
  width: 400px;
  background-color: ${Colors.white};
`

const InnerContainer = styled(Box)`
  background-color: ${Colors.neutral30};
  border-bottom-left-radius: ${BorderRadixes.regular};
  border-bottom-left-radius: ${BorderRadixes.regular};
`
