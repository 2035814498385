// @flow
import { createTheme } from '@mui/material/styles'
import Colors, { PrimaryColor, SecondaryColor, SuccessColor } from '../../../../pure/libs/Colors'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

export const DefaultMaterialTheme = {
  palette: {
    primary: createColor(PrimaryColor),
    secondary: createColor(SecondaryColor),
    neutral30: createColor(Colors.neutral30),
    white: createColor(Colors.white),
    blue: createColor('#0052B4'),
    success: createColor(SuccessColor)
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  }
}

const theme = createTheme(DefaultMaterialTheme)

export default theme
