import { TextareaAutosize as MaterialTextAreaAutoSize } from '@mui/base'
import { TextFieldProps, styled } from '@mui/material'
import { BorderRadixes } from '../enums/BorderRadixes'
import Colors from '../../../../pure/libs/Colors'

export const VitecNextOrderProductDescriptionsTextField = (props: TextFieldProps) => {
  return <TextareaAutoSize {...props} />
}

const TextareaAutoSize = styled(MaterialTextAreaAutoSize)({
  border: 'none',
  outline: 'none',
  resize: 'none',
  borderRadius: BorderRadixes.regular,
  color: Colors.primary100
}) as any
