import React, { useState, useRef, useEffect, useCallback } from 'react'
import { set } from 'react-hook-form'

interface HighlightableListProps {
  items: string[]
}

const HighlightableList: React.FC<HighlightableListProps> = ({ items }) => {
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const listRef = useRef<HTMLUListElement>(null)
  const [isScrolling, setIsScrolling] = useState<boolean>(false)

  const handleWheel = useCallback(
    (event: WheelEvent) => {
      event.preventDefault() // Prevent default scrolling
      if (isScrolling) return
      if (Math.abs(event.deltaY) < 4) return
      setIsScrolling(true)
      setTimeout(() => setIsScrolling(false), 300)

      if (event.deltaY > 0) {
        // Scrolling down
        setHighlightedIndex((prevIndex) => (prevIndex < items.length - 1 ? prevIndex + 1 : items.length - 1))
      } else {
        // Scrolling up
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0))
      }
    },
    [items.length, isScrolling]
  )

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('wheel', handleWheel, { passive: false }) // passive: false to allow preventDefault
      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener('wheel', handleWheel)
        }
      }
    }
  }, [handleWheel])

  return (
    <ul ref={listRef} style={{ overflow: 'hidden', height: '1000px' }}>
      {items.map((item, index) => (
        <li
          key={index}
          style={{
            backgroundColor: highlightedIndex === index ? 'lightblue' : 'transparent',
            padding: '8px',
            listStyleType: 'none'
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  )
}

// Example usage:
const App: React.FC = () => {
  const itemList = [
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6'
  ]

  return (
    <div>
      <HighlightableList items={itemList} />
    </div>
  )
}

export default App
