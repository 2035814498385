import { SMALL_SPACING, SMALL_SPACING_PX } from '../enums/Spacings'

export const PRODUCT_PAGE_WIDTH = 700
export const HOME_FORM_MAX_WIDTH = 600
export const HOME_FORM_MAX_WIDTH_EXPERIMENTAL = 1000

export const DASHBOARD_ADS_MAX_WIDTH = 500

export const KEYRESULT_PROGRESS_BAR_WIDTH = 200

export const ONBOARDING_MAX_WIDTH = '480px'

export const USER_WIDGET_SIZE = 40

export const MENU_ARROW_DOWN_SIZE = 20 // taken from figma but increased it a bit

export const UPSELL_MODAL_MAX_WIDTH = '300px'

export const EDIT_VISION_STATEMENT_MODAL_MIN_WIDTH = '350px'
export const REORDER_OBJECTIVES_MODAL_MIN_WIDTH = '450px'

export const PERIOD_SELECTOR_MODAL_MIN_WIDTH = 500

export const OBJECTIVE_PROGRESS_BAR_HEIGHT = '35px'

export const HEADER_ACTION_CONTAINERSIZE = '24px'

export const NOTIFICATION_WIDTH = '486px'

export const GRAPH_POPOVER_HEIGHT = 170
export const GRAPH_POPOVER_WIDTH = 430

export const GRAPH_MARGINS = { right: 10, top: 10 }

export const PROGRSS_CONTAINER_MIN_WIDTH = '50px'
export const PROGRESS_STATUS_CIRCLE_SIZE = '11px'

export const OBJECTIVE_DIALOG_KEY_RESULT_BOX_WIDTH = '75px'

export const START_PAGE_LEFT_SIDEBAR_WIDTH = '300px'

export const FOCUSED_OBJECTIVE_MIN_HEIGHT = '70px'

export const FOCUSED_OBJECTIVE_MAX_HEIGHT = '300px'

export const FOCUES_OBJECTIVE_SMALL_WIDTH = 200
export const FOCUES_OBJECTIVE_BIG_WIDTH = 300

export const VIDEO_PLAYER_HEIGHT = 500

export const GROWTH_INDICATOR_VALUE_INPUT_WIDTH = 50

export const MUI_LINE_HEIGHT = 1.43

export const GROWTH_VIEW_INDICATOR_NAME_MAX_SIZE = 155

export const PERSONAL_SETTINGS_MAX_WIDTH = 350

export const PERSONAL_PHOTO_CONTAINER_SIZE = 120

export const ONBOARDING_EMAIL_ICON_SIZE = 150

export const LEAD_USER_CONATINER_SIZE = SMALL_SPACING_PX / SMALL_SPACING // about 16 as in figma

export const ROADMAP_MAX_HEIGHT = 500

export const ROADMAP_OBJECTIVE_NAME_LENGTH = 75

export const DASHBOARD_OBJECTIVES_TREE_ITEM_PADDING = SMALL_SPACING_PX

export const YELLOW_BOX_HEIGHT = 33

export const ONBOARDING_NAME_MOBILE_PICTURE_OPACITY = 20
export const ONBOARDING_NAME_MOBILE_PICTURE_POSITION = 6.5

export const MOBILE_YELLOW = '#F2C002' // For some reason this color is not picked up by figma bot

export const MENU_WIDTH = 152
export const MENU_WIDTH_COLLAPSED = 50

export const UNIT_BUTTON_WIDTH = 100 // taken from figma

export const PROGRESS_BAR_HEIGHT = 11
export const PROGRESS_BAR_HEIGHT_KEY_RESULT = 7

export const MIN_WIDTH_PRESENTATION_VIEW = 640

export const SEARCH_INPUT_SIZE = 160

export const RICHTEXT_LINK_POPOVER_HEIGHT = 200

export const RICHTEXT_EDITOR_TOOLBAR_HEIGHT = 40
export const RICHTEXT_LINK_POPOVER_WIDTH = 235

export const INDICATOR_DASHBOARD_SELECT_WIDTH = 450

export const ACTION_BOARD_COLUMN_MIN_WIDTH = 150
export const ACTION_BOARD_COLUMN_MAX_WIDTH = 250

export const TIDIO_CHAT_PADDING = `${SMALL_SPACING_PX * 10}px` // used to get space for tidio chat icon

export const DASHBOARD_INDICATOR_GRAPH_WIDTH = 600
export const STRATEGY_VIEW_OBJECTIVE_INDICATOR_GRAPH_WIDTH = 500

export const TINY_GRAPH_HEIGHT = 200
export const TINY_GRAPH_MIN_WIDTH = 200

export const StrategyViewObjectiveListRowDetailBodyIndicator_MAX_GRAPH_WIDTH = 1000
export const StrategyViewObjectiveListRowDetailBodyIndicator_GRAPH_HEIGHT = 250
export const StrategyViewObjectiveListRowDetailBodyIndicator_FULL_SCREEN_GRAPH_HEIGHT = 500

export const GRAPH_ACTIVITY_LIST_CARD_MIN_WIDRH = ACTION_BOARD_COLUMN_MAX_WIDTH

export const SIGN_IN_LANDING_TOP_PADDING = 200

export const MENU_LOGO_WIDTH = 100

export const PricingPageProductContainerWidth = 410
export const PricingPageProductContainerHeight = 640
export const PricingPageProductContainerHeightBig = 750
export const PricingPageDashboardProductContainerHeight = 400

export const MyOrganizationAIAdWidth = 300
export const AdCreatorLatestAdMaxWidth = 400
export const AdCreatorFormMaxWidth = 870
export const AdCreatorFormUpsellMaxWidth = 300

export const BUTTON_CONTAINER_WIDTH = 300 // used to center middle container when content of left/right components differ

export const UploadLeftContainerWidth = 515

export const AdCreatorFormSectionAddKeywordsButtonMaxWidth = 350

export const KeywordDrawerWidth = 540
export const KeywordDrawerWidthMobile = 250

export const MENU_HEIGHT = 52

export const AD_CREATOR_FORM_RADIO_BUTTON_CONTAINER_MIN_WIDTH = 150

export const SIGN_IN_LANDING_FIGMA_HEIGHT = 954 // https://www.figma.com/file/DscmAoEh9YRMblS4PFmZNA/Gazelle?type=design&node-id=4885-98657&mode=design&t=OJVjLkfeI5nGORJn-4

export const VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH = 750

export const VITEC_NEXT_ORDER_PRODUCT_FEEDBACK_CONTAINER_WIDTH = 1280

export const ONBOARDING_BUTTON_HEIGHT = '40px'

export const HIGHLIGHTED_TOP_ABSOLUTE_POSITION = 150

export const STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_LEFT = 50
export const STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_RIGHT = 450

export const DASHBOARD_MIN_WIDTH = 1440

export const VITEC_NEXT_IMAGE_ASPECT_RATIO = '3/2' // Most common aspect ratio for images accordging to chatgpt

export const DASHBOARD_STATUS_ITEM_VIEW_POPOVER_WIDTH = 400

export const STEP_THROUGH_CONTENT_HEIGHT = 540
