import styled from '@emotion/styled'
import { TextareaAutosize as MaterialTextAreaAutoSize } from '@mui/base'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Colors, { LightGrey } from '../../../../pure/libs/Colors'
import { formatRoutePathForProduct } from '../../../../pure/libs/RoutePath'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, minSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { slackFeedback } from '../libs/CloudFunctionsApiHandler'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { VITEC_NEXT_ORDER_PRODUCT_FEEDBACK_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { success } from '../libs/ToastHelper'
import { VitecNextOrderRedirectPage } from '../pages/DashboardPage'
import Box from './Box'
import DiffLines from './DiffLines'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { ThumbDown, ThumbUp, VitecNextOrderArrowBackIcon } from './Icons'
import { Layout } from './Layout'
import MenuVitecNext from './MenuVitecNext'
import { NoWrapFigmaText } from './StyledComponents'
import { VitecNextOrderProductButtonsFeedback } from './VitecNextOrderProductButtonsFeedback'
import { VitecNextOrderProductButtonsFulfill } from './VitecNextOrderProductButtonsFulfill'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export const VitecNextOrderProductFeedbackViewExperimental: React.FC<VitecNextOrderProductProps> = (props) => {
  const { product } = props
  const item = VitecNextProductInfoItems[product]
  const { enableDiffInFeedbackView = true } = item
  const { state } = useAppState()

  const navigate = useNavigate()

  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { data: jaguarResults } = useJaguarResult(leopardOrder)

  const jaguarText = item.getText?.({ leopardOrder, jaguarResults }) || ''

  const defaultText = leopardOrder?.feedbackText || jaguarText
  const [feedbackText, setFeedbackText] = React.useState(defaultText)

  const onClickBack = () => navigate(formatRoutePathForProduct(props))

  const upsertFeedback = () => leopardOrder && upsertLeopardOrder({ ...leopardOrder, feedbackText })
  const onPreFulfillment = async () => {
    await upsertFeedback()
    await slackFeedback(props, state)
  }

  const onClickThumb = async (feedback: LeopardOrder['feedback']) =>
    leopardOrder &&
    upsertLeopardOrder({ ...leopardOrder, feedback }).then(() => success(getFigmaText(Texts.standardFeedbackSent)))

  useEffect(() => {
    setFeedbackText(defaultText), [defaultText]
    return () => {
      upsertFeedback()
    }
  }, [defaultText])

  if (!item) return <VitecNextOrderRedirectPage {...props} />
  if (!leopardOrder) return <Box />

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth>
        <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
          <Box>
            <Box direction="row" link onClick={onClickBack}>
              <Box>
                <VitecNextOrderArrowBackIcon />
              </Box>
              <Box left spacing={smallSpacing}>
                <NoWrapFigmaText textKey={Texts.standardReturnToDashboard} />
              </Box>
            </Box>
          </Box>
          <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_FEEDBACK_CONTAINER_WIDTH}px`}>
            <VitecNextOrderProductHeader
              {...props}
              headerTextKey={Texts.feedbackFeedbackSectionHeader}
              enableExperimentalVersion={false}
            />
            <Box top>
              <FigmaImageContainer imageKey={Images.gazelleInCircle} />
            </Box>
            <Box top fullWidth>
              <Container fullWidth fullPadding>
                <Box fullWidth direction="row" justify="space-between" align="center">
                  <Box fullWidth direction="row" align="center">
                    {item.enableFulfillment && (
                      <VitecNextOrderProductButtonsFulfill
                        {...props}
                        onPreFulfill={onPreFulfillment}
                        externalEstateId={leopardOrder.externalEstateId}
                      />
                    )}
                    {!item.enableFulfillment && (
                      <VitecNextOrderProductButtonsFeedback
                        {...props}
                        feedbackText={feedbackText}
                        externalEstateId={leopardOrder.externalEstateId}
                      />
                    )}
                  </Box>
                </Box>
              </Container>
            </Box>

            <Box top />
            <Container fullWidth fullPadding>
              <Box direction="row" fullWidth align="center" pointer>
                <Box
                  style={{ opacity: leopardOrder?.feedback === 'bad' ? 0.5 : 1 }}
                  onClick={() => onClickThumb('good')}
                >
                  <ThumbUp />
                </Box>
                <Box
                  left
                  spacing={smallSpacing}
                  style={{ opacity: leopardOrder?.feedback === 'bad' ? 1 : 0.5 }}
                  onClick={() => onClickThumb('bad')}
                >
                  <ThumbDown />
                </Box>
              </Box>
              <Box direction="row" fullWidth top spacing={smallSpacing}>
                <Box fullWidth fullPadding spacing={minSpacing}>
                  <Box>
                    <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                  </Box>
                  <Box top spacing={smallSpacing} fullWidth>
                    <Textarea value={jaguarText} autoFocus disabled />
                  </Box>
                </Box>
                <Box fullWidth fullPadding spacing={minSpacing}>
                  <Box>
                    <FigmaText styleTextKey={Texts.ConstructionCiTextHeader} textKey={Texts.feedbackBetterTextHeader} />
                  </Box>
                  <Box top spacing={smallSpacing} fullWidth>
                    <Textarea value={feedbackText} autoFocus onChange={(e) => setFeedbackText(e.currentTarget.value)} />
                  </Box>
                </Box>
              </Box>
              {enableDiffInFeedbackView && (
                <Box top fullWidth>
                  <DiffLines base={jaguarText} newText={feedbackText} />
                </Box>
              )}
            </Container>
          </Box>
          <Box />
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

export const Textarea = (props: TextFieldProps) => {
  return <TextareaAutoSize autoFocus {...props} />
}

const TextareaAutoSize = styled(MaterialTextAreaAutoSize)({
  border: `1px solid ${LightGrey}`,
  outline: 'none',
  resize: 'none',
  borderRadius: BorderRadixes.regular,
  color: Colors.primary100
}) as any
