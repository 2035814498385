import { smallSpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'
import Tooltip from './Tooltip'
import { VitecNextOrderProductButtonsFulfillProps } from './VitecNextOrderProductButtonsFulfill'
import { IsSteppingThroughStatusContext } from '../types/IsSteppingThroughStatus'

type Props = VitecNextOrderProductButtonsFulfillProps & {
  isSteppingThroughStatusContext: IsSteppingThroughStatusContext
}

export const VitecNextOrderProductButtonsFulfillConstructionInfo = (props: Props) => {
  const { isSteppingThroughStatusContext } = props
  const [isSteppingThroughStatus, setIsSteppingThroughStatus] = isSteppingThroughStatusContext

  const onClick = async () => setIsSteppingThroughStatus('started')
  return (
    <Box>
      <Tooltip
        title={
          isSteppingThroughStatus === 'started'
            ? formatText(getFigmaText(Texts.validatePdfToUploadToYouNeedToValidate), ['Next'])
            : ''
        }
      >
        <Button
          textKey={Texts.validatePdfValidateAndUploadToNext}
          text={formatText(getFigmaText(Texts.validatePdfValidateAndUploadToNext), ['Next'])}
          spacing={smallSpacing}
          onClick={onClick}
          imageKey={Images.uploadArrowWhite}
          disabled={isSteppingThroughStatus !== 'idle'}
        />
      </Tooltip>
    </Box>
  )
}
