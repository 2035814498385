import {
  BasicAssociationItem,
  BasicAssociationResultsResponse,
  JaguarBoundingBox,
  JaguarResultResponse,
  MunicipalInfoResultsResponse,
  TilstandsrapportResultsResponse
} from '../leopard/JaguarTypesDerived'
import { AdValidationFieldTemplate } from '../types/AdValidationField'
import { Estate } from '../types/Estate'
import { JaguarResultsObject } from '../types/JaguarResultsObject'
import { AllAdValidationFieldTemplates } from './JaguarWorkflowAdValidationItemsPure'

export function getValidatableItems({ results }: { results?: JaguarResultsObject }): AdValidationEntry[] {
  const AllAdValidationFieldTemplateEntries = Object.entries(AllAdValidationFieldTemplates)

  const items = getValidationItemsForResults(results)

  const fieldAndTemplates = AllAdValidationFieldTemplateEntries.filter(([id]) => !!items[id]).map(
    ([id, adValidationItem]) => ({
      id,
      adValidationItem,
      value: items[id] as { value?: string | number | null; bounding_boxes: JaguarBoundingBox[] } | undefined,
      items: items as Record<string, { value?: string | number | null }>
    })
  )

  return (
    Object.entries(fieldAndTemplates)
      // TODO WRITE TEST, onlny allow the ones that are enabled
      .filter(([k, { adValidationItem }]) => adValidationItem.enabled !== false)
      .filter(([k, { adValidationItem, value }]) => adValidationItem.getValueFromEstate)
      .map(([k, v]) => v)
  )
}

export function getValidationItemsForResults(results?: JaguarResultsObject) {
  return Object.values(results || {}).reduce(
    (a, v) => {
      if (!v) return a
      return { ...a, ...getValidationItemsForResult(v) }
    },
    {} as Record<string, BasicAssociationItem>
  )
}

export function getValidationItemsForResult(result: JaguarResultResponse) {
  /********  TODO REMOVE WHEN GTB-344 is fixed **********/
  if ((result as TilstandsrapportResultsResponse).structured_outputs?.area_sizes)
    Object.keys((result as TilstandsrapportResultsResponse).structured_outputs?.area_sizes || {}).forEach((k) => {
      //@ts-ignore
      result.structured_outputs.area_sizes[k].value = result.structured_outputs.area_sizes[k].size.toString()
    })
  /******************************** */

  return {
    ...(result as BasicAssociationResultsResponse).structured_outputs?.basic_association_info,
    ...(result as MunicipalInfoResultsResponse).structured_outputs?.municipal_info,
    ...(result as TilstandsrapportResultsResponse).structured_outputs?.area_sizes
  } as Record<string, BasicAssociationItem>
}

export type AdValidationEntry = {
  id: string
  adValidationItem: AdValidationFieldTemplate
  value:
    | {
        value?: string | number | null | undefined
        bounding_boxes?: JaguarBoundingBox[]
      }
    | undefined
  items: Record<
    string,
    {
      value?: string | number | null
    }
  >
}

export function isValidAdValidationValue({ entry, estate }: { entry: AdValidationEntry; estate?: Estate }) {
  const { adValidationItem, value, items } = entry
  const vitecNextEstate = estate?.metaData?.vitecNextEstate
  const fieldValue = value?.value?.toString()
  const esetateValue = adValidationItem.getValueFromEstate?.(vitecNextEstate)?.toString()

  if (!!adValidationItem.shouldValidate && adValidationItem.shouldValidate?.({ items, estate }) === false) return true

  if (!!adValidationItem.isFloatNumber)
    return Math.abs(parseFloat(esetateValue || '0') - parseFloat(fieldValue || '0')) < 1

  if (!fieldValue || !esetateValue) return false

  return esetateValue === fieldValue
}
