import { BasicAssociationItems, JaguarBoundingBox } from '../leopard/JaguarTypesDerived'
import { AdValidationFieldTemplate } from '../types/AdValidationField'
import { VitecNextImportBooleanType } from '../types/VitecNextImportBooleanType'
import { VitecNextImportDoubleType } from '../types/VitecNextImportNumberType'
import { VitecNextImportTextType } from '../types/VitecNextImportTextType'
import { VitecNextImportType } from '../types/VitecNextImportType'

import { getListingFeeFromEstate } from './JaguarWorkflowBasicAssociationItemsPureHelper'
import { LeopardField } from './LeopardField'
import { VitecNextUploadFieldTemplate } from './VitecNextUploadFieldTemplate'

export type BasicAssociationResponseItemsKey = keyof BasicAssociationItems | 'about_association'

export type HighlightedField = {
  id: string
  bounding_boxes: JaguarBoundingBox[]
}

export type BasicAssociationField = LeopardField &
  HighlightedField & {
    key: BasicAssociationResponseItemsKey
  }

export type BasicAssociationFields = Record<BasicAssociationResponseItemsKey, BasicAssociationField>

export function replaceNewlineWithSpaces(str: string): string {
  return str.toString().replace(/\n\s+/g, '\n')
}

export const JaguarWorkflowBasicAssociationItemsPure: {
  [property in BasicAssociationResponseItemsKey]: AdValidationFieldTemplate & VitecNextUploadFieldTemplate
} = {
  felleskostnader_pr_mnd: {
    vitecNextImportType: VitecNextImportType.RentPrMonth,
    isFloatNumber: true,
    vitecNextImportDoubleType: VitecNextImportDoubleType.RentPrMonth,
    getValueFromEstate: (e) => e?.estatePrice?.rent?.rentPrMonth
  },
  borettslaget: {},
  borettslagets_org_nr: {},
  forretningsfører: {},
  forkjøpsrettsgebyr: {
    vitecNextImportType: VitecNextImportType.PreemptiveFee,
    isFloatNumber: true,
    vitecNextImportDoubleType: VitecNextImportDoubleType.PreemptiveFee,
    getValueFromEstate: (estate) => estate?.partOwnership?.preEmptiveFee,
    shouldValidate: ({ items }) => !!items?.forkjøpsrett_bool?.value
  },
  husdyrhold: {
    vitecNextImportType: VitecNextImportType.Animals,
    vitecNextImportTextType: VitecNextImportTextType.Animals
  },
  andel_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.CollectiveDebt,
    isFloatNumber: true,
    vitecNextImportDoubleType: VitecNextImportDoubleType.CollectiveDebt,
    getValueFromEstate: (e) => e?.estatePrice?.collectiveDebt,
    shouldValidate: ({ items, estate }) => {
      if (items.andel_fellesgjeld.value === '0' && !estate?.metaData?.vitecNextEstate?.estatePrice?.collectiveDebt)
        return false
      return true
    }
  },
  andel_formue: {
    vitecNextImportType: VitecNextImportType.ShareJointCapital,
    isFloatNumber: true,
    vitecNextImportDoubleType: VitecNextImportDoubleType.ShareJointCapital,
    getValueFromEstate: (e) => e?.partOwnership?.shareJointCapital
  },
  sikringsordning_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.GuaranteeFund,
    vitecNextImportTextType: VitecNextImportTextType.GuaranteeFund,
    format: replaceNewlineWithSpaces
  },
  borettslagets_forsikringsselskap: {
    vitecNextImportType: VitecNextImportType.InsuranceCompany,
    vitecNextImportTextType: VitecNextImportTextType.InsuranceCompany
  },
  polisenummer_felles_forsikring: {
    vitecNextImportType: VitecNextImportType.InsurancePolicy,
    vitecNextImportTextType: VitecNextImportTextType.InsurancePolicy
  },
  eierskiftegebyr: {
    vitecNextImportType: VitecNextImportType.ChangeOfOwnershipFee,
    isFloatNumber: true,
    vitecNextImportDoubleType: VitecNextImportDoubleType.ChangeOfOwnershipFee,
    getValueFromEstate: (estate) => estate?.partOwnership?.changeOfOwnershipFee
  },
  lånebetingelser_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.LoanTermsCollectiveDebt,
    vitecNextImportTextType: VitecNextImportTextType.LoanTermsCollectiveDebt,
    format: replaceNewlineWithSpaces
  },
  styregodkjennelse_bool: {
    vitecNextImportType: VitecNextImportType.HasBoardApproval,
    vitecNextImportBooleanType: VitecNextImportBooleanType.HasBoardApproval
  },
  styregodkjennelse_explanation: {
    vitecNextImportType: VitecNextImportType.BoardApproval,
    vitecNextImportTextType: VitecNextImportTextType.BoardApproval
  },
  forkjøpsrett_bool: {
    vitecNextImportType: VitecNextImportType.HasPreemptiveRight,
    vitecNextImportBooleanType: VitecNextImportBooleanType.HasPreemptiveRight
  },
  forkjøpsrett_explanation: {
    vitecNextImportType: VitecNextImportType.Preemptive,
    vitecNextImportTextType: VitecNextImportTextType.Preemptive
  },
  noteringsgebyr: {
    getValueFromEstate: getListingFeeFromEstate,
    shouldValidate: ({ estate }) => false // !!estate?.metaData?.vitecNextEstate?.saleItem
  },
  styregodkjennelse: {
    enabled: false,
    vitecNextImportType: VitecNextImportType.BoardApproval,
    vitecNextImportTextType: VitecNextImportTextType.BoardApproval
  },
  forkjøpsrett: {
    enabled: false,
    vitecNextImportType: VitecNextImportType.Preemptive,
    vitecNextImportTextType: VitecNextImportTextType.Preemptive
  },
  about_association: {
    vitecNextImportType: VitecNextImportType.PartAbout,
    vitecNextImportTextType: VitecNextImportTextType.PartAbout
  }
}

export const BasicAssociationKeysThatCanBeCantBeUploaded: BasicAssociationResponseItemsKey[] = Object.entries(
  JaguarWorkflowBasicAssociationItemsPure
)
  .filter(([_, v]) => !v.vitecNextImportType)
  .map(([k, _]) => k as BasicAssociationResponseItemsKey)

export const BasicAssociationKeysAll = Object.keys(JaguarWorkflowBasicAssociationItemsPure)

export const BasicAssociationKeysThatCanBeUploaded: BasicAssociationResponseItemsKey[] = BasicAssociationKeysAll.filter(
  (k) => !BasicAssociationKeysThatCanBeCantBeUploaded.includes(k as BasicAssociationResponseItemsKey)
) as BasicAssociationResponseItemsKey[]
