import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

import dayjs from 'dayjs'
import { DASHBOARD_LIST_ITEM_STATUS_VIEW_SIZE } from '../../../../pure/libs/Consts'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { regularSpacing, tinySpacing } from '../enums/Spacings'
import { useEstate } from '../hooks/QueryHooks'
import { useIsProductEnabled } from '../hooks/useIsProductEnabled'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledInfo'
import Box from './Box'
import { DashboardListItemStatusView } from './DashboardListItemStatusView'
import { VitecNextOrderLandingItemStatusPlayCircleIcon } from './Icons'
import { LoaderGreen } from './LoaderGreen'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'

export const DashboardListItemStatus = (props: VitecNextOrderProductProps) => {
  const { product, externalEstateId, now = dayjs().format() } = props
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[product]
  const { StatusComponent = VitecNextOrderItemStatus } = vitecNextProductInfoItem
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))
  const { data: results } = useJaguarResult(leopardOrder)

  const { data: isProductEnabled, isLoading: isLoadingProduct } = useIsProductEnabled(props)

  const { data: estate } = useEstate(externalEstateId)

  if (isLoadingProduct) return null

  const orderPlaced = estate?.metaData?.vitecNextOrder?.orderPlaced || leopardOrder?.createdAt

  if (
    !!isProductEnabled?.isEnabled &&
    (results?.status === 'processing' ||
      // better loading experience
      (!results?.status && dayjs().diff(dayjs(orderPlaced), 'minutes') < 2))
  ) {
    return (
      <Box right spacing={tinySpacing}>
        <LoaderGreen size={regularSpacing} />
      </Box>
    )
  }

  if (isProductEnabled?.isEnabled && !results?.status)
    return <VitecNextOrderLandingItemStatusPlayCircleIcon size={DASHBOARD_LIST_ITEM_STATUS_VIEW_SIZE * 2} />

  if (!isLoadingProduct && !isProductEnabled?.isEnabled)
    return (
      <DashboardListItemStatusView
        type="warning"
        statusProps={props}
        text={IsVitecNextProductEnabledInfo[isProductEnabled?.code || '']?.getText({
          ...props,
          isVitecNextProductEnabled: isProductEnabled
        })}
      />
    )

  return (
    <StatusComponent
      {...props}
      isProductEnabled={isProductEnabled}
      ViewComponent={DashboardListItemStatusView}
      enableLoadingFilesWidget={false}
      now={now}
    />
  )
}
