import { Product } from '../leopard/Product'
import { AdValidationQueryParams } from '../types/QueryParamTypes'
import { addQueryParamsToUrl } from './QueryParamHelper'

enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',
  DASHBOARD = '/dashboard',
  PRICING = '/pricing',
  PRICING_WEB_IFRAME = '/pricing-web-iframe',
  SIGN_UP = '/signup',
  TERMS_AND_CPNDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  SETTINGS = '/settings',
  EXTERNAL_SESSION = '/external-session',
  VITEC_NEXT_ORDER_LANDING = '/vitec-next-order-landing',
  VITEC_NEXT_ORDER = '/vitec-next-order',
  AD_VALIDATION = '/ad-validation/:externalEstateId',
  ESTATE = '/estate/:externalEstateId',
  ESTATE_PRODUCT = '/estate/:externalEstateId/:product',
  ESTATE_PRODUCT_FEEDBACK = '/estate/:externalEstateId/:product/feedback',
  VITEC_NEXT_ORDER_PRODUCT = '/vitec-next-order/:vitecNextOrderId/:product',
  VITEC_NEXT_ORDER_PRODUCT_FEEDBACK = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/feedback',
  VITEC_NEXT_ORDER_PRODUCT_DIFF = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/diff',
  VITEC_NEXT_FAQ = '/vitec-next-faq',
  VISMA_ORDER_LANDING = '/visma-order-landing',

  // App legacy
  RESEND_SECURE_LINK = '/resend-secure-link',
  CREATE_AD = '/create-ad',
  PRODUCTS = '/products'
}

export function formatUrlForAdValidationItem({
  externalEstateId,
  highlightedId
}: {
  externalEstateId: string
  highlightedId: string
}): string {
  return addQueryParamsToUrl<AdValidationQueryParams>(
    formatRoutePathForExternalEstateId({ externalEstateId, routePath: RoutePath.AD_VALIDATION }),
    {
      highlightedId
    }
  )
}

export function formatRoutePathForExternalEstateId({
  externalEstateId,
  routePath = RoutePath.ESTATE
}: {
  externalEstateId: string
  routePath?: RoutePath
}) {
  return formatRoutePath(routePath, { externalEstateId })
}

export function formatRoutePathForProduct(p: { externalEstateId: string; product: Product; routePath?: RoutePath }) {
  const { routePath = RoutePath.ESTATE_PRODUCT } = p
  return formatRoutePath(routePath, p)
}

const formatRoutePath = (r: RoutePath, o: object) =>
  Object.entries(o).reduce((acc, [k, v]) => acc.replace(`:${k}`, v), r as string)

export default RoutePath
