import { smallSpacing } from '../enums/Spacings.js'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes.js'
import Box from './Box.js'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems.js'

import dayjs from 'dayjs'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId.js'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps.js'
import { useIsProductEnabled } from '../hooks/useIsProductEnabled.js'
import { useJaguarResult } from '../hooks/useJaguarResult.js'
import { useLeopardOrder } from '../hooks/useLeopardOrder.js'
import { VitecNextOrderItemButton } from './VitecNextOrderItemButton.js'

export const DashboardListItemDetailsEmptyState = (props: VitecNextOrderProductProps) => {
  const { product, externalEstateId } = props
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[product]
  const { buttons } = vitecNextProductInfoItem

  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { data: result } = useJaguarResult(leopardOrder)

  const { data: isProductEnabled } = useIsProductEnabled(props)

  return (
    <Box fullWidth align="center">
      <Box>
        <Box pointer alignSelf="flex-start" direction="row" align="center" fullWidth>
          {buttons.map((button, i) => (
            <Box key={i} top spacing={smallSpacing}>
              <VitecNextOrderItemButton
                button={button}
                {...props}
                isProductEnabled={isProductEnabled}
                index={i}
                now={dayjs().format()}
                isLoading={!!leopardOrder && !leopardOrder.error && !result} /** When there is a result **/
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
