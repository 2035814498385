import { BasicAssociationResultsResponse } from '../leopard/JaguarTypesDerived'
import {
  BasicAssociationField,
  BasicAssociationFields,
  BasicAssociationKeysThatCanBeUploaded,
  BasicAssociationResponseItemsKey,
  JaguarWorkflowBasicAssociationItemsPure
} from './JaguarWorkflowBasicAssociationItemsPure'
import { StepThroughContentField, StepThroughContentItem } from './StepThroughContentHelper'

export function getBasicAssociationFieldsFromJaguarResults(result: BasicAssociationResultsResponse | undefined) {
  return Object.entries(result?.structured_outputs?.basic_association_info || {}).reduce(
    (a, [key, v]) => {
      const fieldTemplatePure = JaguarWorkflowBasicAssociationItemsPure[key as BasicAssociationResponseItemsKey]

      if (!fieldTemplatePure) return a

      const { enabled = true } = fieldTemplatePure || {}
      if (!enabled) return a

      let _v: string = v?.value as unknown as string

      // TODO WRITE TEST, should convert "12.0" to "12"
      if (!isNaN(parseFloat(_v))) _v = parseFloat(_v).toFixed(2).replace('.00', '') || ''

      // TODO WRITE TEST, should convert 12.00 to "12"
      if (typeof _v === 'number') _v = (_v as number).toFixed(2).replace('.00', '') || ''

      if (typeof _v === 'boolean') _v = _v ? 'Ja' : 'Nei'

      let value: string = _v?.trim() || ''

      value = fieldTemplatePure?.format?.(value) || value

      a[key] = {
        key,
        value,
        isFulfilling: false,
        checked:
          BasicAssociationKeysThatCanBeUploaded.includes(key as BasicAssociationResponseItemsKey) && value !== '',
        fulfilledAt: null,
        stepThroughItems: [
          {
            id: key,
            text: value,
            label: '',
            field: {
              bounding_boxes: v?.bounding_boxes
            },
            pageNumber: v?.bounding_boxes?.[0]?.page.toString()
          }
        ]
      } as BasicAssociationField

      return a
    },
    getAboutAssociationFields(result) as BasicAssociationFields
  )
}

function getAboutAssociationFields(result?: BasicAssociationResultsResponse) {
  const aboutAssociationItems = result?.structured_outputs?.about_association?.items || []

  const stepThroughItems: StepThroughContentItem<StepThroughContentField>[] = aboutAssociationItems.flatMap((f) => [
    {
      id: f.topic,
      text: `${f.topic}: ${f.info}`,
      label: f.topic,
      field: {
        bounding_boxes: f.bounding_boxes || []
      }
    },
    {
      id: f.topic + 'new-line',
      text: ''
    }
  ])

  return {
    about_association: {
      key: 'about_association',
      value: stepThroughItems.map((i) => i.text).join('\n\n'),
      stepThroughItems
    }
  }
}
