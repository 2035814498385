import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { SuccessColor, Yellow50 } from '../../../../pure/libs/Colors'
import { ESC_KEYCODE, YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import { LeopardFields } from '../../../../pure/libs/LeopardField'
import { StepThroughContentItemBase } from '../../../../pure/libs/StepThroughContentHelper'
import { JaguarWorkflowAdValidationClientItems } from '../../../../pure/types/JaguarWorkflowAdValidationClientItem'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_LEFT } from '../libs/HardCodedSizes'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { onCopyText } from '../libs/onClickCopy'
import Box from './Box'
import { Checkbox } from './Checkbox'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { Check, Edit } from './Icons'
import Loader from './Loader'
import Tooltip from './Tooltip'
import { TextContainer } from './VitecNextOrderProductConstructionInfoItem'
import { VitecNextOrderProductDescriptionsTextField } from './VitecNextOrderProductDescriptionsTextField'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'

export function VitecNextOrderProductLeopardField({
  itemKey,
  fields,
  setFields,
  enableUpload = true,
  selectedItem,
  onClickHighlight,
  FieldTemplates,
  onClickUpload: _onClickUpload
}: {
  itemKey: string
  fields?: LeopardFields
  setFields: (fields: LeopardFields) => void
  enableUpload?: boolean
  selectedItem?: StepThroughContentItemBase
  onClickHighlight: (field: StepThroughContentItemBase) => unknown
  FieldTemplates: JaguarWorkflowAdValidationClientItems
  onClickUpload: (itemKey: string) => Promise<unknown>
}) {
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')
  const fieldTemplate = FieldTemplates[itemKey]

  if (!fieldTemplate) return null

  const { enableEdit } = fieldTemplate

  const field = fields?.[itemKey]

  if (!field) return null

  const { checked = true, fulfilledAt, isFulfilling, value, stepThroughItems = [] } = field

  const formattedValue = value.replaceAll('\n\n', '\n')

  // Standard and Innhold is formatted with <br> tags, so we need to replace \n\n with \n

  const isLoading = loading || isFulfilling

  const onClickChecked = (checked: boolean, key: string, fields: LeopardFields) =>
    setFields({ ...fields, [key]: { ...fields[key], checked, isFulfilling: false } } as LeopardFields)

  const onClickUpload = (itemKey) =>
    Promise.resolve(setLoading(true))
      .then(() => _onClickUpload(itemKey))
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))

  const onBlur = async () => {
    setIsEditing(false)
    eventEmitter.emit(Events.IS_EDITING_FEEDBACK_TEXT, false)
    if (!feedbackText) return

    setFields({ ...fields, [itemKey]: { ...fields[itemKey], feedbackText } } as LeopardFields)
  }

  const onFocus = () => {
    setIsEditing(true)
    if (!feedbackText) setFeedbackText(formattedValue)
    eventEmitter.emit(Events.IS_EDITING_FEEDBACK_TEXT, true)
  }

  return (
    <Container direction="row" fullWidth justify="space-between">
      <Box fullWidth direction="row">
        {fields && enableUpload && (
          <Box onClick={() => onClickChecked(!checked, itemKey, fields)} pointer top spacing={minSpacing}>
            <Checkbox value={checked} />
          </Box>
        )}
        <Box left spacing={smallSpacing} fullWidth>
          <Box fullWidth>
            <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} textKey={fieldTemplate.textKey} />
            {feedbackText && (
              <VitecNextOrderProductDescriptionsTextField
                onBlur={onBlur}
                onChange={(e) => setFeedbackText(e.target.value)}
                value={feedbackText}
                onKeyDown={(e) => e.keyCode === ESC_KEYCODE && onBlur()}
                disabled={!isEditing}
              />
            )}
            {!feedbackText && (
              <Box fullWidth>
                {value && !stepThroughItems?.length && (
                  <VitecNextProductDescriptionItemTextView value={feedbackText || value} />
                )}
                {stepThroughItems.map((stepThroughItem, i) => {
                  const hasSelectedSibling = !!selectedItem && selectedItem.id !== stepThroughItem?.id

                  return (
                    <Box fullWidth onClick={() => onClickHighlight?.(stepThroughItem)} key={i} pointer>
                      <VitecNextProductDescriptionItemTextView
                        value={stepThroughItem.text}
                        isSelected={stepThroughItem.id === selectedItem?.id}
                        hasSelectedSibling={hasSelectedSibling}
                      />
                    </Box>
                  )
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box direction="row">
        {enableUpload && (
          <Box left spacing={smallSpacing} pointer>
            <Tooltip
              title={
                fulfilledAt
                  ? formatText(getFigmaText(Texts.standardCtaUploadedToNextAt), [
                      dayjs(fulfilledAt).format(YYYYMMDD_HHmm)
                    ])
                  : getFigmaText(Texts.standardCtaUploadToNext)
              }
              placement="top"
              backgroundColor={fulfilledAt ? SuccessColor : undefined}
            >
              <Box onClick={() => onClickUpload(itemKey)}>
                {isLoading && <Loader size={Images.uploadArrow.width} />}
                {!isLoading && (
                  <FigmaImageContainer imageKey={fulfilledAt ? Images.uploadedArrow : Images.uploadArrow} />
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
        <Box left spacing={smallSpacing} pointer onClick={() => onCopyText(formattedValue)}>
          <Tooltip title={getFigmaText(Texts.standardCtaCopyText)} placement="top">
            <FigmaImageContainer imageKey={Images.copyBlack} />
          </Tooltip>
        </Box>

        {enableEdit && (
          <Box left spacing={tinySpacing} pointer>
            <Tooltip title={'Edit'} placement="top">
              <Box>
                {!isEditing && (
                  <Box onClick={onFocus}>
                    <Edit size={Images.copyBlack.width} />
                  </Box>
                )}
                {isEditing && (
                  <Box onClick={onBlur}>
                    <Check size={Images.copyBlack.height} />
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? Yellow50 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`

function VitecNextProductDescriptionItemTextView({
  value,
  isSelected,
  hasSelectedSibling
}: {
  value: string
  isSelected?: boolean
  hasSelectedSibling?: boolean
}) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isSelected) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [isSelected])

  return (
    <Box position="relative">
      <Box ref={ref} style={{ position: 'absolute', top: -STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_LEFT }} />
      <TextContainer
        isSelected={isSelected}
        fullPadding={isSelected}
        spacing={tinySpacing}
        opacity={hasSelectedSibling ? 0.5 : 1}
      >
        <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={value} separator={<br />} />
      </TextContainer>
    </Box>
  )
}
