import { BasicAssociationResultsResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { BasicAssociationFields } from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { getBasicAssociationFieldsFromJaguarResultsClient } from '../libs/LeopardFieldsHelper'
import { useJaguarResult } from './useJaguarResult'
import { useLeopardOrder } from './useLeopardOrder'
import { useState } from './useState'

export const useBasicAssociaionFieldsState = (externalEstateId: string) => {
  const vitecNextProduct = Product.BASIC_ASSOCIATION

  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId({ externalEstateId, product: vitecNextProduct }))

  const { data: jaguarResult } = useJaguarResult<BasicAssociationResultsResponse>(leopardOrder)

  const [fields, setFields] = useState<BasicAssociationFields>(
    getBasicAssociationFieldsFromJaguarResultsClient(jaguarResult),
    { enabled: !!jaguarResult! }
  )

  return { fields, setFields }
}
