import { LiveProductNames, LiveProducts } from '../../../../pure/leopard/Product'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { tinySpacing } from '../enums/Spacings'
import { useJaguarEstate } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { getJaguarResultsForLeopardOrderAndEstate } from '../hooks/useJaguarResult'
import { isFinished } from '../libs/JaguarHelper'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import Loader from './Loader'
import { ProgressBarView } from './ProgressBar'
import { useLatestLeopardOrders } from './useLatestLeopardOrders'

export const WIDTH = 200
export const all = LiveProductNames.length

export function DashboardStatusTextCreated({ externalEstateId }: { externalEstateId: string }) {
  const { data: leopardOrders = [], isLoading } = useLatestLeopardOrders(externalEstateId)
  const { data: jaguarEstate } = useJaguarEstate(externalEstateId)
  const results = getJaguarResultsForLeopardOrderAndEstate({ leopardOrders, jaguarEstate })

  const completedOrders = leopardOrders
    .filter((v) => LiveProducts[v.product])
    .filter((v) => !v.id.includes('EXPERIMENTAL'))
    .map((v) => {
      const result = results.find((r) => r?.request_id === v.requestId)

      return {
        leopardOrder: v,
        result
      }
    })
    .filter((r) => isFinished(r.result))
    .map((r) => r.leopardOrder)

  if (isLoading) return <Loader />

  if (!completedOrders) return <Loader />

  const percentage = (completedOrders.length / all) * 100

  return (
    <Box style={{ marginTop: -10 /* Lineheight issue with below textkey */ }}>
      <Box direction="row" justify="space-between" align="center" fullWidth>
        <FigmaText textKey={Texts.dashboardDashboardListTextsGeneratedLabel} />
        <FigmaText textKey={Texts.cssDashboardListStatusScore} text={`${percentage.toFixed(0)}%`} />
      </Box>
      <Box width={`${WIDTH}px`} top spacing={tinySpacing}>
        <DashboardStatusProgressBarView progress={percentage} />
      </Box>
    </Box>
  )
}

const DashboardStatusProgressBarView = (props) => (
  <ProgressBarView
    sx={{
      'background-color': 'white'
    }}
    {...props}
  />
)
