import {
  BasicAssociationResultsResponse,
  DescriptionsResultsResponse
} from '../../../../pure/leopard/JaguarTypesDerived'
import { BasicAssociationResponseItemsKey } from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import { LeopardFields } from '../../../../pure/libs/LeopardField'
import { getBasicAssociationFieldsFromJaguarResults } from '../../../../pure/libs/getBasicAssociationFieldsFromJaguarResults'
import { getDescriptionFieldsFromJaguarResults } from '../../../../pure/libs/getDescriptionFieldsFromJaguarResults'
import { JaguarWorkflowAdValidationClientItems } from '../../../../pure/types/JaguarWorkflowAdValidationClientItem'
import { JaguarWorkflowBasicAssociationItems } from './JaguarWorkflowBasicAssociationItems'
import { JaguarWorkflowDescriptionsItems } from './JaguarWorkflowDescriptionsItems'
import { getFigmaText } from './TextRepository'

export const getBasicAssociationFieldsFromJaguarResultsClient = (
  jaguarResult: BasicAssociationResultsResponse | undefined
) =>
  getClientLeopardFields({
    leopardFields: getBasicAssociationFieldsFromJaguarResults(jaguarResult),
    FieldTemplates: JaguarWorkflowBasicAssociationItems
  })

export const getDescriptionFieldsFromJaguarResultsClient = (results: DescriptionsResultsResponse | undefined) =>
  getClientLeopardFields({
    leopardFields: getDescriptionFieldsFromJaguarResults(results),
    FieldTemplates: JaguarWorkflowDescriptionsItems
  })

function getClientLeopardFields<T extends LeopardFields>({
  leopardFields,
  FieldTemplates
}: {
  leopardFields: T
  FieldTemplates: JaguarWorkflowAdValidationClientItems
}) {
  return Object.entries(leopardFields).reduce((acc, [key, value]) => {
    const textKey = FieldTemplates[key as BasicAssociationResponseItemsKey]?.textKey

    if (!textKey) return acc

    return {
      ...acc,
      [key]: {
        ...value,
        stepThroughItems:
          value?.stepThroughItems?.map((item) => ({ ...item, label: item.label || getFigmaText(textKey) })) || []
      }
    }
  }, {} as T)
}
