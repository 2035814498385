import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing } from '../enums/Spacings'
import { useVitecNextValidationItems } from '../hooks/useVitecNextValidationItems'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import { DashboardStatusItemView } from './DashboardStatusItemView'
import FigmaText from './FigmaText'
import { VitecNextOrderItemStatusViewType } from './VitecNextOrderItemStatusView'

const ValidationStatusViewTypes: VitecNextOrderItemStatusViewType[] = ['success', 'warning', 'error']

export function DashboardStatusValidation({
  enableLabel = true,
  externalEstateId
}: {
  externalEstateId?: string
  enableLabel?: boolean
}) {
  const { data: validationItems = [] } = useVitecNextValidationItems({ externalEstateId })

  if (validationItems.length === 0) return <Box />

  return (
    <Box direction="row" align="center" fullWidth>
      {enableLabel && (
        <Box>
          <FigmaText textKey={Texts.dashboardDashboardListTextsValidated} />
        </Box>
      )}

      <Box left spacing={smallSpacing} />
      {ValidationStatusViewTypes.map((type, i) => (
        <DashboardStatusValidationItem key={i} type={type} validationItems={validationItems} />
      ))}
    </Box>
  )
}
function DashboardStatusValidationItem(props: {
  validationItems: VitecNextProductInfoItemValidationItem[]
  type: VitecNextOrderItemStatusViewType
}) {
  const { type } = props
  const validationItems = props.validationItems.filter((v) => v.type === type)
  return (
    <Box left={validationItems.length > 0} spacing={smallSpacing}>
      <DashboardStatusItemView type={type} validationItems={validationItems} />
    </Box>
  )
}
