import { Product } from '../../../../pure/leopard/Product'
import { tinySpacing } from '../enums/Spacings'
import { useEstate } from '../hooks/QueryHooks'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import Box from './Box'
import DashboardListItem from './DashboardListItem'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'

export function DashboardList(props: { externalEstateId: string; now: string }) {
  const { externalEstateId, now } = props
  const { data: estate } = useEstate(externalEstateId)

  if (!externalEstateId) return null

  return (
    <Box fullWidth>
      {[
        Product.IMAGE_CAPTIONS,
        Product.AREA_DESCRIPTION,
        Product.CONSTRUCTION_INFO,
        Product.DESCRIPTIONS,
        Product.SELF_DECLERATION,
        Product.BASIC_ASSOCIATION,
        Product.LAND_REGISTRY
      ]
        .filter((v) => !!v)
        .map((product, i) => (
          <Box key={i} top spacing={tinySpacing} fullWidth>
            <DashboardListItem {...props} product={product} />
          </Box>
        ))}
      <Box align="center" fullWidth top>
        <VitecNextOrderItemLongWaitFigmaText
          estate={estate}
          figmaTextProps={{ styleTextKey: HardCodedTexts.dashboardLongwaitText }}
        />
      </Box>
    </Box>
  )
}
