import { useEffect, useState } from 'react'
import { Events } from '../../../../pure/libs/EventEmitter'
import eventEmitter from '../../../../pure/libs/EventEmitter'

const PREVIOUS_KEYS = ['ArrowUp', 'ArrowLeft']
const NEXT_KEYS = ['ArrowDown', 'ArrowRight']

export function useClickPreviousNext({
  items,
  index,
  onClickNewIndex
}: {
  items: { id: string; pageNumber?: string }[]
  index: number
  onClickNewIndex: (index: number) => unknown
}) {
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (isEditing) return
    if (items.length === 0) return
    const handleKeyDown = (event: KeyboardEvent) => {
      if (NEXT_KEYS.includes(event.key) && index < items.length - 1) {
        onClickNewIndex(index + 1)
      } else if (PREVIOUS_KEYS.includes(event.key) && index > 0) {
        onClickNewIndex(index - 1)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [items, index, isEditing])

  useEffect(() => {
    eventEmitter.on(Events.IS_EDITING_FEEDBACK_TEXT, (isEditing: boolean) => setIsEditing(isEditing))
  }, [])
}
