import { VismaCoreCase } from '../leopard/VismaCoreCaseTypesDerived'
import { Address } from '../types/Address'

export function getDebugInfoAddress(address?: Address) {
  if (!address) return ''
  return getEstateStreeetAddress(address).trim()
}

export function getDebugInfoAddressForVismaCoreCase(estate?: VismaCoreCase) {
  if (!estate?.Street) return ''
  return `${estate.Street} ${estate.ApartmentNumber || ''}`
}

export function getEstateStreeetAddress(address: Address) {
  if (!address) return ''
  return `${address?.streetAdress} ${address?.apartmentNumber || ''}`
}

export function getEstateFullAddress(address?: Address) {
  if (!address) return ''
  return `${address.streetAdress} ${address.apartmentNumber || ''} ${address.zipCode || ''} ${address.city || ''}`
}
