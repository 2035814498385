import {
  IsVitecNextProductEnabledResponse,
  IsVitecNextProductEnabledResponseCode
} from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { Product } from '../../../../pure/leopard/Product'
import { toMB } from '../../../../pure/libs/Common'
import { Estate } from '../../../../pure/types/Estate'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { formatText, getFigmaText } from './TextRepository'
import { Texts } from './Texts'

export const IsVitecNextProductEnabledInfo: {
  [property in IsVitecNextProductEnabledResponseCode]: {
    getText: (
      args: VitecNextOrderProductProps & {
        estate?: Estate
        isVitecNextProductEnabled?: IsVitecNextProductEnabledResponse
      }
    ) => string
  }
} = {
  DOCUMENT_NOT_FOUND: {
    getText: ({ product }) => {
      if (product === Product.IMAGE_CAPTIONS) return getFigmaText(Texts.ImageTextsNotificationItNoImgages)

      if (product === Product.CONSTRUCTION_INFO) return getFigmaText(Texts.ConstructionNotificationCiMissingDocument)

      if (product === Product.DESCRIPTIONS) return getFigmaText(Texts.ConstructionNotificationCiMissingDocument)

      return getFigmaText(Texts.standardNotificationMissingDocument)
    }
  },
  DOCUMENT_TOO_BIG: {
    getText: ({ isVitecNextProductEnabled }) =>
      formatText(getFigmaText(Texts.standardTooBigDocument), [
        toMB(isVitecNextProductEnabled?.extra?.bytes || 0).toFixed(0)
      ])
  },
  ADDRESS_NOT_FOUND: {
    getText: ({ estate }) => formatText(getFigmaText(Texts.AreaDescriptionMissingArea), [estate?.address || ''])
  },
  TOO_MANY_IMAGES: {
    getText: ({ isVitecNextProductEnabled }) =>
      formatText(getFigmaText(Texts.ImageTextsErrorImageTextsToMany), [isVitecNextProductEnabled?.noOfDocuments || ''])
  }
}
