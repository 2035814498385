import styled from '@emotion/styled'
import { Error, Success, Warning } from '../../../../pure/libs/ColorsExperimental'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { Texts } from '../libs/Texts'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import { VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { DashboardStatusItemViewPopperRowForwardArrow } from './Icons'
import { NoWrapFigmaText } from './StyledComponents'
import Colors from '../../../../pure/libs/Colors'
import { DashboardStatusItemViewPopperRowSuccess } from './DashboardStatusItemViewPopperRowSuccess'

export function DashboardStatusItemViewPopperRow(props: {
  item: VitecNextProductInfoItemValidationItem
  items: VitecNextProductInfoItemValidationItem[]
  isHighlighted?: boolean
  enableCTA?: boolean
  style?: React.CSSProperties
}) {
  const { item, isHighlighted, enableCTA, style } = props
  const { type, ctaText } = item

  const BadgeContainer = type === 'success' ? SuccessContainer : type === 'error' ? ErrorContainer : WarningContainer

  if (type === 'success') return <DashboardStatusItemViewPopperRowSuccess {...props} />

  return (
    <Container fullWidth onClick={item.onClick} pointer={!!item.onClick} isHighlighted={isHighlighted} style={style}>
      <Box direction="row" align="center" justify="space-between" fullWidth>
        <Box direction="row" align="center" fullWidth>
          <Box>
            <BadgeContainer fullPadding spacing={tinySpacing} />
          </Box>
          <Box left spacing={smallSpacing} fullWidth>
            <NoWrapFigmaText styleTextKey={Texts.cssValidationMessageHeader} text={item.header} />
          </Box>
        </Box>
        <Box visibility={item.onClick && enableCTA ? 'visible' : 'hidden'} fullWidth align="flex-end" left>
          <Button buttonType="secondary" spacing={smallSpacing} verticalSpacing={'0px'}>
            <Box direction="row" align="center">
              <Box>
                <FigmaText textKey={HardCodedTexts.componentsValidationMessagesCtaReviewDocument} text={ctaText} />
              </Box>
              <Box>
                <DashboardStatusItemViewPopperRowForwardArrow />
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
      <Box fullWidth>
        <FigmaTextWithLinebreaks textKey={Texts.cssValidationMessageText} text={item.message} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isHighlighted?: boolean }>`
  ${({ isHighlighted }) => isHighlighted && `background-color: ${Colors.neutral30};`}
`

const StatusContainer = styled(Box)`
  border-radius: 50%;
`
const SuccessContainer = styled(StatusContainer)`
  background-color: ${Success};
`
const WarningContainer = styled(StatusContainer)`
  background-color: ${Warning};
`
const ErrorContainer = styled(StatusContainer)`
  background-color: ${Error};
`
