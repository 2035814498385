import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import {
  JaguarBoundingBox as BoundingBox,
  SelfDeclarationItem,
  SelfDeclarationResultsResponse
} from '../../../../pure/leopard/JaguarTypesDerived'
import Colors from '../../../../pure/libs/Colors'
import { HighlightedField } from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { getGsUrlForLeopardOrder } from '../hooks/useDownloadUrl'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getSelfDeclarationItems } from '../libs/SelfDeclarationHelper'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { JsonView } from './JsonView'
import Loader from './Loader'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextOrderProductSelfDeclarationItem } from './VitecNextOrderProductSelfDeclarationItem'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { VitecNextProductSourceFileInfoDeprecated } from './VitecNextProductSourceFileInfoDeprecated'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useClickUpDown } from './useClickUpDown'
import { useDrawerContext } from './useDrawerContext'

export const VitecNextOrderProductSelfDeclaration = (props: VitecNextOrderProductProps) => {
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)
  const drawerContext = useDrawerContext('self_declaration')

  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState<string | undefined>(undefined)

  const item = VitecNextProductInfoItems[props.product]
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))
  const gsUrl = getGsUrlForLeopardOrder(leopardOrder)

  const { data: jaguarResults, isLoading: isLoadingJaguarResults } =
    useJaguarResult<SelfDeclarationResultsResponse>(leopardOrder)

  const status = jaguarResults?.status

  const isLoading = isLoadingJaguarResults

  const selfDeclarationItems = getSelfDeclarationItems(jaguarResults)

  const defaultBoundingBoxes =
    (selfDeclarationItems?.flatMap((item) => item.bounding_boxes).filter(Boolean) as BoundingBox[]) || []

  const [boundingBoxes, setBoundingBoxes] = useState<BoundingBox[]>(defaultBoundingBoxes)

  useEffect(() => {
    if (boundingBoxes.length === 0 && defaultBoundingBoxes.length > 0) setBoundingBoxes(defaultBoundingBoxes)
  }, [jaguarResults])

  useClickUpDown({
    items: selfDeclarationItems.map((item) => ({ ...item, id: item.question_number.toString() })) || [],
    selectedId: selectedQuestionNumber || '',
    onClickDown: (items, currentIndex) => {
      onClickHighlight(items[currentIndex + 1] as any)
    },
    onClickUp: (items, currentIndex) => {
      onClickHighlight(items[currentIndex - 1] as any)
    }
  })

  if (!leopardOrder) return <Box />

  const onClickHighlight = (i: SelfDeclarationItem) => {
    setSelectedQuestionNumber(i.question_number.toString() as string)
    drawerContext.highlight({ id: i.question_number, bounding_boxes: i.bounding_boxes } as HighlightedField)
  }

  return (
    <WithPdfDrawer pdfViewerPropSets={[{ gsUrl, boundingBoxes }]} drawerContext={drawerContext}>
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton {...props} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader {...props} headerTextKey={item.textKey} />

          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>

          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons {...props} />
          </Box>

          <Box fullWidth fullPadding>
            <Box fullWidth onClick={() => drawerContext.open()}>
              <VitecNextProductSourceFileInfoDeprecated leopardOrder={leopardOrder} />
            </Box>
          </Box>

          {status === 'processing' && <LoadingPage />}
          {status === 'success' && (
            <Box fullWidth>
              <Box fullWidth>
                <Container fullWidth fullPadding position="relative">
                  <Box direction="row" align="center">
                    <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                  </Box>
                  <Box top>
                    <VitecNextOrderProductFeedbackButton {...props} />
                  </Box>
                  {isLoading && (
                    <Box fullWidth align="center" fullPadding>
                      <Loader />
                    </Box>
                  )}
                  <Box fullWidth top spacing={smallSpacing}>
                    {selfDeclarationItems?.map((item, i) => (
                      <VitecNextOrderProductSelfDeclarationItem
                        key={i}
                        item={item}
                        onClickHighlight={() => onClickHighlight(item)}
                        isSelected={selectedQuestionNumber === item.question_number.toString()}
                      />
                    ))}
                  </Box>
                  {enableDebug && <JsonView data={jaguarResults || {}} />}
                </Container>
              </Box>
            </Box>
          )}
        </Box>
        <Box />
      </Box>
    </WithPdfDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
