import { L } from 'vitest/dist/chunks/reporters.C4ZHgdxQ'

export enum Product {
  IMAGE_CAPTIONS = 'IMAGE_CAPTIONS',
  CONSTRUCTION_INFO = 'CONSTRUCTION_INFO',
  BASIC_ASSOCIATION = 'BASIC_ASSOCIATION',
  SELF_DECLERATION = 'SELF_DECLERATION',
  DESCRIPTIONS = 'DESCRIPTIONS',
  AREA_DESCRIPTION = 'AREA_DESCRIPTION',
  LAND_REGISTRY = 'LAND_REGISTRY',
  AD_VALIDATION = 'AD_VALIDATION'
}

export const LiveProducts: { [key in Product]: boolean } = {
  IMAGE_CAPTIONS: true,
  CONSTRUCTION_INFO: true,
  DESCRIPTIONS: true,
  BASIC_ASSOCIATION: true,
  AREA_DESCRIPTION: true,
  SELF_DECLERATION: true,
  LAND_REGISTRY: true,
  AD_VALIDATION: false
}

export const LiveProductNames = Object.entries(LiveProducts)
  .filter(([_, value]) => value)
  .map(([key]) => key as Product)

export const ValidatableProductNames = [...LiveProductNames, Product.AD_VALIDATION]
export const OnVitecNextOrderCreatedProductNames = [...LiveProductNames, Product.AD_VALIDATION]
