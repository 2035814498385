import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import {
  AreaDescriptionResultsResponse,
  BasicAssociationResultsResponse,
  ConstructionMethodResultsResponse,
  DescriptionsResultsResponse,
  LandRegistryResultsResponse,
  SelfDeclarationResultsResponse
} from '../../../../pure/leopard/JaguarTypesDerived'
import { Product } from '../../../../pure/leopard/Product'

import { FREMTIND_EX_PDF_URL } from '../../../../pure/libs/Consts'
import { getBasicAssociationFieldsFromJaguarResults } from '../../../../pure/libs/getBasicAssociationFieldsFromJaguarResults'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import { HardCodedTranslations } from '../../../../pure/libs/HardCodedTranslations'
import Images from '../libs/Images'
import { getDescriptionFieldsFromJaguarResultsClient } from '../libs/LeopardFieldsHelper'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { formatSelfDeclarationItem, getSelfDeclarationItems } from '../libs/SelfDeclarationHelper'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { getConstructionMethodItems } from '../libs/getConstructionMethodItems'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import { DashboardListItemDetailsBasicAssociationComponent } from './DashboardListItemDetailsBasicAssociationComponent'
import { DashboardListItemDetailsImageCaptionsComponent } from './DashboardListItemDetailsImageCaptionsComponent'
import { DashboardListItemDetailsSelfDeclarationComponent } from './DashboardListItemDetailsSelfDeclarationComponent'
import FigmaText from './FigmaText'
import { VitecNextOrderProductAreaDescription } from './VitecNextOrderProductAreaDescription'
import { VitecNextOrderProductBasicAssociation } from './VitecNextOrderProductBasicAssociation'
import { VitecNextOrderProductConstructionInfo } from './VitecNextOrderProductConstructionInfo'
import { VitecNextOrderProductDescriptions } from './VitecNextOrderProductDescriptions'
import { VitecNextOrderProductImageCaptionsExperimental } from './VitecNextOrderProductImageCaptionsExperimental'
import { VitecNextOrderProductSelfDeclaration } from './VitecNextOrderProductSelfDeclaration'

export const VitecNextProductInfoItems: {
  [property in Product]: VitecNextProductInfoItem
} = {
  [Product.IMAGE_CAPTIONS]: {
    textKey: Texts.dashboardAutoCaptioningTitleCaptioningTitleg,
    descriptionKey: Texts.dashboardAutoCaptioningDescr,
    imageKey: Images.imageCaptionIcon,
    slug: 'bildetekst',
    PreviewComponent: VitecNextOrderProductImageCaptionsExperimental,
    // StatusComponent: VitecNextOrderItemStatusImageCaptionsDeprecated,
    DashboardListItemDetailsComponent: DashboardListItemDetailsImageCaptionsComponent,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsCtaGenImageTexts,
        getButtonText: (r?: IsVitecNextProductEnabledResponse) =>
          formatText(getFigmaText(Texts.ImageTextsCtaGenImageTexts), [r?.noOfDocuments || '0']),
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getSuccessText: ({ isVitecNextProductEnabled: r }) => {
      if (!r) return ''
      return formatText(getFigmaText(Texts.ImageTextsNotificationItCompleted), [r.noOfDocuments])
    }
  },
  [Product.CONSTRUCTION_INFO]: {
    textKey: Texts.dashboardConstructionInfoTitle,
    descriptionKey: Texts.dashboardConstructionInfoDescr,
    imageKey: Images.constructionInfoIcon,
    slug: 'byggemate',
    PreviewComponent: VitecNextOrderProductConstructionInfo,
    enableFulfillment: true,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) =>
      getConstructionMethodItems(jaguarResults as ConstructionMethodResultsResponse)
        .map(({ text }) => text)
        .join('\n')
  },
  [Product.BASIC_ASSOCIATION]: {
    textKey: Texts.dashboardBasicAssociationTitle,
    descriptionKey: Texts.dashboardBasicAssociationDesc,
    imageKey: Images.basicAssiciationIcon,
    slug: 'basic-association',
    PreviewComponent: VitecNextOrderProductBasicAssociation,
    DashboardListItemDetailsComponent: DashboardListItemDetailsBasicAssociationComponent,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (props) => createLeopardOrder(props)
      }
    ],
    getText: ({ jaguarResults }) => {
      const fields = getBasicAssociationFieldsFromJaguarResults(jaguarResults as BasicAssociationResultsResponse)
      const basicFields = Object.entries(fields || {}).reduce((a, [k, v]) => {
        return {
          ...a,
          [k]: {
            ...v,
            fulfilledAt: undefined,
            isFulfilling: undefined,
            checked: undefined,
            key: undefined
          }
        }
      }, {})

      return JSON.stringify(basicFields, null, 2)
    }
  },
  [Product.SELF_DECLERATION]: {
    textKey: Texts.SelfDeclerationSdOrderItemHeader,
    descriptionKey: Texts.SelfDeclerationSdOrderItemDescrition,
    DescriptionSuffixComponent: () => (
      <Box link>
        <FigmaText
          textKey={Texts.SelfDeclerationSdOrderItemDescrition}
          text="Ex link"
          anchorHref={FREMTIND_EX_PDF_URL}
        />
      </Box>
    ),
    imageKey: Images.adValidationIcon,
    slug: 'selfdecleration',
    PreviewComponent: VitecNextOrderProductSelfDeclaration,
    DashboardListItemDetailsComponent: DashboardListItemDetailsSelfDeclarationComponent,
    enableFulfillment: false,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) => {
      if (!jaguarResults) return ''
      const selfDeclerationResults = jaguarResults as SelfDeclarationResultsResponse

      return (
        getSelfDeclarationItems(selfDeclerationResults)
          .map((r) => formatSelfDeclarationItem(r))
          .join('\n\n') || ''
      )
    }
  },
  [Product.AREA_DESCRIPTION]: {
    textKey: Texts.dashboardAreaDescriptionTItle,
    descriptionKey: Texts.dashboardAreaDescriptionDescr,
    imageKey: Images.areaDescriptionIcon,
    slug: 'descriptions',
    PreviewComponent: VitecNextOrderProductAreaDescription,
    enableFulfillment: true,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) =>
      (jaguarResults as AreaDescriptionResultsResponse)?.structured_outputs?.area_description || ''
  },
  [Product.DESCRIPTIONS]: {
    textKey: HardCodedTexts.DescriptionsDOrderItemHeader,
    descriptionKey: Texts.DescriptionsDOrderItemDescription,
    imageKey: Images.standardIIcon,
    slug: 'descriptions',
    PreviewComponent: VitecNextOrderProductDescriptions,
    getText: ({ jaguarResults }) => {
      const r = getDescriptionFieldsFromJaguarResultsClient(jaguarResults as DescriptionsResultsResponse)
      const standard = r?.standard.value.replaceAll('\n\n', '\n')

      return `<b>${r?.short_description.value}</b>\n\n${r?.long_description.value}\n\n<b>Innhold</b>\n\n${r?.innhold.value}\n\n<b>Standard</b>\n\n${standard}`
    },
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsSelectPinpointText,
        onClick: (props) =>
          createLeopardOrder({
            ...props,
            extra: {
              ...props.extra,
              longShortDescription: {
                longShortDescriptionType: 'bullets'
              }
            }
          })
      },
      {
        buttonTextKey: Texts.ImageTextsSelectFloatingText,
        onClick: (props) =>
          createLeopardOrder({
            ...props,
            extra: { ...props.extra, longShortDescription: { longShortDescriptionType: 'long' } }
          })
      }
    ]
  },
  [Product.LAND_REGISTRY]: {
    textKey: { ...HardCodedTexts.DescriptionsDOrderItemHeader, texts: HardCodedTranslations.landRegistryTranscript },
    descriptionKey: {
      ...Texts.DescriptionsDOrderItemDescription,
      texts: HardCodedTranslations.landRegistryTranscriptDescriptions
    },
    // PreviewComponent: VitecNextOrderProductLandRegistry,
    imageKey: Images.headerAndShortDescriptionIcon,
    slug: 'descriptions',
    enableFulfillment: false,
    enableBetaUX: true,
    getText: ({ jaguarResults }) => {
      const results = jaguarResults

      return (
        (results as LandRegistryResultsResponse)?.structured_outputs?.land_registry?.heftelser
          .map(({ header, text }) => `<b>${header}</b>\n\n${text}`)
          .join('\n\n') || ''
      )
    },
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ]
  },
  [Product.AD_VALIDATION]: {
    textKey: HardCodedTexts.DescriptionsDOrderItemHeader,
    descriptionKey: Texts.DescriptionsDOrderItemDescription,
    imageKey: Images.standardIIcon,
    slug: 'ad-validation',
    getText: () => '',
    buttons: []
  }
}
