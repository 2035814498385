import React from 'react'
import Box from './Box'
import { Layout } from './Layout'
import { VitecNextOrderRedirectPage } from '../pages/DashboardPage'
import MenuVitecNext from './MenuVitecNext'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { GoToLocalDevEnvironment } from './GoToLocalDevEnvironment'
import { VitecNextOrderProductPreview } from './VitecNextOrderProductPreview'

export const VitecNextOrderProduct: React.FC<VitecNextOrderProductProps> = (props) => {
  const { externalEstateId, product } = props
  const item = VitecNextProductInfoItems[product]

  if (!item) return <VitecNextOrderRedirectPage externalEstateId={externalEstateId} />

  const { PreviewComponent = VitecNextOrderProductPreview } = item

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth>
        <PreviewComponent {...props} />
      </Box>
      <GoToLocalDevEnvironment />
    </Layout>
  )
}
