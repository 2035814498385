import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { JaguarResultsObject } from '../../../../pure/types/JaguarResultsObject'
import { useJaguarEstate } from './QueryHooks'
import { ReducerState } from './useFirestoreQueryHelper'
import { Product } from '../../../../pure/leopard/Product'
import { getJaguarResultsObject } from '../../../../pure/libs/getJaguarResultsObject'

export const useJaguarResultsForAdValiation = (externalEstateId?: string) =>
  useJaguarResults({ externalEstateId, product: Product.AD_VALIDATION } as LeopardOrder)

export function useJaguarResults<T extends JaguarResultsObject>(l?: LeopardOrder): ReducerState<T> {
  const q = useJaguarEstate(l?.externalEstateId)

  const data = getJaguarResultsObject({ jaguarEstate: q.data, product: l?.product })
  const query = { ...q, data } as ReducerState<T>
  return query
}
