import styled from '@emotion/styled'
import { SecondaryColor30 } from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import useIsMobile from '../hooks/useIsMobile'
import Box from './Box'
import { DashboardStatusTextCreated } from './DashboardStatusTextCreated'
import { DashboardStatusValidation } from './DashboardStatusValidation'
import { DEFAULT_ICON_SIZE } from './Icon'
import Loader from './Loader'
import useQueryParams from '../libs/useQueryParams'
import { AdValidationQueryParams } from '../../../../pure/types/QueryParamTypes'

export function DashboardStatus(props: { isLoading?: boolean; externalEstateId: string }) {
  const isMobile = useIsMobile()
  const enableStatusTextCreated = !isMobile
  const enableLabel = !isMobile
  const { highlightedId } = useQueryParams<AdValidationQueryParams>()

  // This loading is nessesary to get deeplinks to AdValidationDrawer to work
  if (props.isLoading && !highlightedId)
    return (
      <Container fullWidth align="center" fullPadding>
        <Loader size={DEFAULT_ICON_SIZE} />
      </Container>
    )
  return (
    <Container fullWidth direction="row" align="center" fullPadding justify="space-between">
      <Box>{enableStatusTextCreated && <DashboardStatusTextCreated {...props} />}</Box>
      <Box>
        <DashboardStatusValidation {...props} enableLabel={enableLabel} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${SecondaryColor30};
  border-radius: ${BorderRadixes.regular};
`
