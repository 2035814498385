import styled from '@emotion/styled'
import invariant from 'invariant'
import { useState } from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { ConstructionMethodResultsResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import Colors from '../../../../pure/libs/Colors'
import { getStepThroughContentItems } from '../../../../pure/libs/StepThroughContentHelper'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { getGsUrlForLeopardOrder } from '../hooks/useDownloadUrl'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { STEP_THROUGH_CONTENT_HEIGHT, VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { getConstructionMethodItems } from '../libs/getConstructionMethodItems'
import { IsSteppingThroughStatus } from '../types/IsSteppingThroughStatus'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { JsonView } from './JsonView'
import { LoadingPage } from './LoadingPage'
import { PdfViewerProps } from './PdfViewer'
import { VitecNextPdfDrawerBottomComponent } from './VitecNextContructionInfoPdfDrawerBottomComponent'
import { VitecNextContructionInfoPdfDrawerMiddleComponent } from './VitecNextContructionInfoPdfDrawerMiddleComponent'
import { VitecNextPdfDrawerTopComponent } from './VitecNextContructionInfoPdfDrawerTopComponent'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductButtonsFulfillConstructionInfo } from './VitecNextOrderProductButtonsFulfillConstructionInfo'
import {
  ConstructionMethodItem,
  VitecNextOrderProductConstructionInfoItem
} from './VitecNextOrderProductConstructionInfoItem'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextOrderProductStatus } from './VitecNextOrderProductStatus'
import { VitecNextOrderProductStepThroughContent } from './VitecNextOrderProductStepThroughContent'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useClickPreviousNext } from './useClickPreviousNext'
import { STEP_THROUGH_ITEMS_DRAWER_CONTEXT_EXTRA, useDrawerContext } from './useDrawerContext'
import Button from './Button'
import { HardCodedTexts } from '../libs/HardCodedTexts'

export const VitecNextOrderProductConstructionInfo = (props: VitecNextOrderProductProps) => {
  const leopardOrderId = getLeopardOrderId(props)
  const { data: leopardOrder } = useLeopardOrder(leopardOrderId)
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)

  const drawerContext = useDrawerContext('construction_info', {
    onClose: () => {
      if (isSteppingThroughStatus !== 'done') setIsSteppingThroughStatus('idle')
      setSelectedIndex(-1)
    },
    extra: STEP_THROUGH_ITEMS_DRAWER_CONTEXT_EXTRA
  })
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  const isSteppingThroughStatusContext = useState<IsSteppingThroughStatus>('idle')
  const [isSteppingThroughStatus, setIsSteppingThroughStatus] = isSteppingThroughStatusContext

  const { data: jaguarResult } = useJaguarResult<ConstructionMethodResultsResponse>(leopardOrder)

  const items: ConstructionMethodItem[] = getConstructionMethodItems(jaguarResult)

  const onClickStepThrough = (newIndex: number, items: ConstructionMethodItem[]) => {
    if (newIndex >= upDownItems.length) {
      setIsSteppingThroughStatus('done')
      setSelectedIndex(-1)
      return
    }

    setIsSteppingThroughStatus('started')
    onClickHighlight(newIndex, items)
  }

  const onClickHighlight = (newIndex: number, items: ConstructionMethodItem[]) => {
    setSelectedIndex(newIndex)
    const item = items[newIndex]
    invariant(item, '!item')
    drawerContext.highlight({ id: item.id, bounding_boxes: item.field?.bounding_boxes || [] })
  }

  const upDownItems = getStepThroughContentItems(items)

  const selectedItem = upDownItems[selectedIndex]

  const onClickPrevious = () => onClickStepThrough(selectedIndex - 1, upDownItems)

  const onClickNext = () => onClickStepThrough(selectedIndex + 1, upDownItems)

  useClickPreviousNext({
    items: upDownItems,
    index: selectedIndex,
    onClickNewIndex: (newIndex) => {
      onClickStepThrough(newIndex, upDownItems)
    }
  })

  const gsUrl = getGsUrlForLeopardOrder(leopardOrder)
  const pdfViewerPropSets: Partial<PdfViewerProps>[] = [
    {
      gsUrl,
      boundingBoxes: upDownItems
        .map((i) => {
          if (!i.field?.bounding_boxes) return []
          return i.field.bounding_boxes
        })
        .flat()
    }
  ]

  const status = jaguarResult?.status

  if (!leopardOrder) return <Box />

  if (!jaguarResult?.structured_outputs) return <Box />

  const elements =
    isSteppingThroughStatus && drawerContext.drawerState.isOpen
      ? {
          TopElement: (
            <VitecNextPdfDrawerTopComponent
              {...props}
              selectedItem={selectedItem}
              isSteppingThroughStatusContext={isSteppingThroughStatusContext}
            />
          ),
          MiddleElement: (
            <VitecNextContructionInfoPdfDrawerMiddleComponent
              {...props}
              selectedItem={selectedItem}
              isSteppingThroughStatusContext={isSteppingThroughStatusContext}
            />
          ),
          BottomElement: (
            <VitecNextPdfDrawerBottomComponent
              {...props}
              onClick={() => onClickNext()}
              isSteppingThroughStatusContext={isSteppingThroughStatusContext}
            />
          )
        }
      : {}

  const onClickDone = (items: ConstructionMethodItem[]) => {
    onClickStepThrough(items.length, items)

    setTimeout(() => {
      document.getElementById('menu')?.scrollIntoView({ behavior: 'smooth' })
    }, 0)
  }
  return (
    <WithPdfDrawer pdfViewerPropSets={pdfViewerPropSets} drawerContext={drawerContext} {...elements}>
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton {...props} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader {...props} headerTextKey={Texts.ConstructionCiHeaderText} />
          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons
              {...props}
              fulfillMentSuccessText={getFigmaText(Texts.standardNotificationUploadComplete)}
              enableFulfillment={true}
              enableRegenerate={isSteppingThroughStatus === 'idle'}
              VitecNextOrderProductButtonsFulfillComponent={
                isSteppingThroughStatus !== 'done'
                  ? (props) => (
                      <VitecNextOrderProductButtonsFulfillConstructionInfo
                        {...props}
                        isSteppingThroughStatusContext={isSteppingThroughStatusContext}
                      />
                    )
                  : undefined
              }
            />
          </Box>

          {isSteppingThroughStatus === 'started' && (
            <Box fullWidth top>
              <VitecNextOrderProductStepThroughContent
                {...props}
                onClickNext={onClickNext}
                onClickPrevious={onClickPrevious}
                items={upDownItems}
                selectedIndex={selectedIndex}
                isSteppingThroughStatusContext={isSteppingThroughStatusContext}
              />
            </Box>
          )}

          <Box fullWidth top>
            <VitecNextOrderProductStatus {...props} drawerContext={drawerContext} />
          </Box>

          {!status && <LoadingPage />}

          <Box fullWidth top>
            <Box fullWidth>
              <Container fullWidth fullPadding position="relative">
                <Box direction="row" align="center">
                  <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                </Box>
                {isSteppingThroughStatus === 'idle' && (
                  <Box top>
                    <VitecNextOrderProductFeedbackButton {...props} />
                  </Box>
                )}
                <Box
                  top
                  fullWidth
                  style={{
                    height: isSteppingThroughStatus === 'started' ? STEP_THROUGH_CONTENT_HEIGHT : undefined,
                    overflow: 'auto'
                  }}
                >
                  {items.map((item, i) => {
                    const index = upDownItems.findIndex((i) => i.id === item.id)
                    return (
                      <VitecNextOrderProductConstructionInfoItem
                        key={i}
                        text={item.text}
                        onClickHighlight={() => onClickHighlight(index, upDownItems)}
                        item={item}
                        selectedItem={selectedItem}
                        isSteppingThroughStatusContext={isSteppingThroughStatusContext}
                      />
                    )
                  })}

                  {isSteppingThroughStatus === 'started' && (
                    <Box fullWidth align="center" top>
                      <Button
                        buttonType="secondary"
                        text={`${getFigmaText(HardCodedTexts.continue)} ➪`}
                        onClick={() => onClickDone(upDownItems)}
                      />
                    </Box>
                  )}
                </Box>

                {enableDebug && <JsonView data={jaguarResult || {}} />}
              </Container>
            </Box>
          </Box>
        </Box>
        <Box visibility="hidden">
          <VitecNextOrderProductBackButton {...props} />
        </Box>
      </Box>
    </WithPdfDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
