import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import Colors from '../../../../pure/libs/Colors'
import { Success } from '../../../../pure/libs/ColorsExperimental'
import { asObject } from '../../../../pure/libs/Common'
import { AllAdValidationFieldTemplates } from '../../../../pure/libs/JaguarWorkflowAdValidationItemsPure'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useEstate } from '../hooks/QueryHooks'
import { DASHBOARD_STATUS_ITEM_VIEW_POPOVER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { NoWrapFigmaText } from './StyledComponents'

export function DashboardStatusItemViewPopperRowSuccess(props: {
  item: VitecNextProductInfoItemValidationItem
  items: VitecNextProductInfoItemValidationItem[]
  isHighlighted?: boolean
  enableCTA?: boolean
  style?: React.CSSProperties
}) {
  const { item, isHighlighted, style } = props
  const items = asObject(props.items || [], 'id')

  const { externalEstateId } = useParams<{ externalEstateId: string }>()
  const { data: estate } = useEstate(externalEstateId)

  const shouldValidate = AllAdValidationFieldTemplates[item.id].shouldValidate?.({ items, estate })
  const value = shouldValidate !== false ? item.value : ''
  return (
    <Container fullWidth onClick={item.onClick} pointer={!!item.onClick} isHighlighted={isHighlighted} style={style}>
      <Box direction="row" align="center" fullWidth>
        <Box>
          <SuccessContainer fullPadding spacing={tinySpacing} />
        </Box>
        <Box left spacing={smallSpacing}>
          <NoWrapFigmaText
            styleTextKey={Texts.componentsValidationMessagesCssValidationMessageHeader}
            text={item.header}
          />
        </Box>
        <Box spacing={smallSpacing} fullWidth right alignText="right">
          <NoWrapFigmaText styleTextKey={Texts.componentsValidationMessagesCssValidationMessageText} text={value} />
        </Box>
        <Box right>
          <FigmaImageContainer imageKey={Images.adValiationCheckIcon} />
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isHighlighted?: boolean }>`
  width: ${DASHBOARD_STATUS_ITEM_VIEW_POPOVER_WIDTH}px;
  ${({ isHighlighted }) => isHighlighted && `background-color: ${Colors.neutral30};`}
`

const StatusContainer = styled(Box)`
  border-radius: 50%;
`
const SuccessContainer = styled(StatusContainer)`
  background-color: ${Success};
`
