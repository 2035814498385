import { useParams } from 'react-router-dom'
import { VitecNextOrderProductPropsWithLegacyOrderId } from '../../../../pure/types/VitecNextOrderProductProps'
import { Layout } from '../components/Layout'
import { VitecNextOrderProduct } from '../components/VitecNextOrderProduct'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { VitecNextOrderRedirectPage } from './DashboardPage'

export const VitecNextOrderProductPage = () => {
  // eslint-disable-next-line prefer-const
  let { externalEstateId, product, vitecNextOrderId } = useParams<VitecNextOrderProductPropsWithLegacyOrderId>()

  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  if (!externalEstateId && vitecNextOrder?.estateId) externalEstateId = vitecNextOrder.estateId

  if (!externalEstateId) return <Layout enableMenu />
  if (!product) return <VitecNextOrderRedirectPage externalEstateId={externalEstateId} />

  return <VitecNextOrderProduct product={product} externalEstateId={externalEstateId} />
}
