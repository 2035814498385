import React, { useEffect, useRef } from 'react'
import { JaguarBoundingBox as BoundingBox } from '../../../../pure/leopard/JaguarTypesDerived'
import { BorderRadixes } from '../enums/BorderRadixes'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import { getHighligtningOpacity, isSameBoundingBox } from '../libs/BoundingBoxHelper'
import { shouldShowBoundingBox } from '../libs/shouldShowBoundingBox'
import Box from './Box'
import { DrawerState } from './useDrawerContext'

const PADDING = SMALL_SPACING_PX * 1.5

export function PdfPageHighlight({
  pdfPageHighlight,
  pageHighlights,
  drawerState
}: {
  pdfPageHighlight: PdfPageHighlightData
  pageHighlights: PdfPageHighlightData[]
  drawerState: DrawerState
}) {
  const { boundingBox, style } = pdfPageHighlight
  const { highlightedField, enableScrollOffsetWithExtraBoundingbox } = drawerState
  const highlightedBoundingBoxes = highlightedField?.bounding_boxes || []
  const ref = useRef<HTMLDivElement>(null)
  const topBoundingBox = highlightedBoundingBoxes.sort((a, b) => {
    if (a.page !== b.page) return a.page - b.page
    return a.points?.[0]?.y - b.points?.[0]?.y
  })[0]

  const numberOfSameBoundingBoxes = pageHighlights.filter((highlight) =>
    isSameBoundingBox(highlight.boundingBox, boundingBox)
  ).length

  const opacity = getHighligtningOpacity(numberOfSameBoundingBoxes)

  let topBoundingBoxScrollOffset = drawerState.highlightedScrollOffset
  if (enableScrollOffsetWithExtraBoundingbox)
    topBoundingBoxScrollOffset = topBoundingBoxScrollOffset - (style.height as number) / 2

  const shouldScroll = isSameBoundingBox(topBoundingBox, boundingBox)

  // Should hide highlight if user actively wanna show another highlight
  const shouldShowHighlight = shouldShowBoundingBox(drawerState, boundingBox)

  useEffect(() => {
    if (!ref.current) return
    if (shouldScroll) ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [ref.current, shouldScroll])
  return (
    <Box>
      <Box
        style={{
          position: 'absolute',
          ...style,
          backgroundColor: undefined,
          top: `calc(${style.top}px - ${topBoundingBoxScrollOffset}px)`
        }}
        ref={ref}
      />
      <Box
        style={{
          position: 'absolute',
          ...style,
          // TODO WRITE TEST: Should offset type with half of PADDING
          top: (style.top as number) - PADDING / 2,
          // TODO WRITE TEST: Should offset height with PADDING
          height: (style.height as number) + PADDING,
          // TODO WRITE TEST: Should offset left with half PADDING
          left: (style.left as number) - PADDING / 2,
          // TODO WRITE TEST: Should offset width with PADDING
          width: (style.width as number) + PADDING,
          borderRadius: BorderRadixes.regular,
          opacity,
          backgroundColor: drawerState.hightlightColor
        }}
        visibility={shouldShowHighlight ? 'visible' : 'hidden'}
      />
    </Box>
  )
}
export type PdfPageHighlightData = {
  style: React.CSSProperties
  boundingBox: BoundingBox
}
