import styled from '@emotion/styled'
import { smallSpacing } from '../enums/Spacings'
import { DASHBOARD_STATUS_ITEM_VIEW_POPOVER_WIDTH } from '../libs/HardCodedSizes'
import { VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import { DashboardStatusItemViewPopperRow } from './DashboardStatusItemViewPopperRow'
import Popper from './Popper'

export function DashboardStatusItemViewPopper({
  anchorEl,
  validationItems,
  onMouseLeave,
  onMouseEnter
}: {
  anchorEl: HTMLElement | null
  validationItems: VitecNextProductInfoItemValidationItem[]
  onMouseLeave?: () => void
  onMouseEnter?: () => void
}) {
  return (
    <Popper anchorEl={anchorEl} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
      <div>
        <Container fullPadding spacing={smallSpacing}>
          {validationItems.map((item, key) => (
            <DashboardStatusItemViewPopperRow key={key} item={item} items={validationItems} />
          ))}
        </Container>
      </div>
    </Popper>
  )
}

const Container = styled(Box)`
  max-width: ${DASHBOARD_STATUS_ITEM_VIEW_POPOVER_WIDTH}px;
`
