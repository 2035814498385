import styled from '@emotion/styled'
import { defaultSpacing, littleSpacing, regularSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { ONBOARDING_BUTTON_HEIGHT } from '../libs/HardCodedSizes'
import { EmailValidation } from '../libs/ValidationHelper'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { TextField, TextFieldOutlined } from './TextFieldOutlined'
import { getFigmaText } from '../libs/TextRepository'
import * as Yup from 'yup'
import { Texts } from '../libs/Texts'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import { DeprecatedTextKeys, TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInSteps, SignInViewProps } from '../../../../pure/types/SignInTypes'
import useResponsive from '../hooks/useResponsive'
import Email from '@mui/icons-material/Email'
import { ENTER_KEYCODE } from '../../../../pure/libs/Consts'
import { useForm } from '../hooks/useForm'
import { Controller } from 'react-hook-form'
import { Input } from '@mui/material'
import { BorderRadixes } from '../enums/BorderRadixes'
import { AppTexts, DeprecatedTexts } from '../../../../pure/libs/AppTexts'
import { HomeFormCTAArrow } from './Icons'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import TextFieldForm from './TextFieldForm'

const ValidationSchema = Yup.object().shape({
  email: EmailValidation('email', getFigmaText(TextsDeprecated.onboardingEmailErrorNotValidEmail)),
  agreed: Yup.bool().oneOf([true], 'Field must be checked')
})

export default function SignInSignUpWithEmailForm(props: SignInViewProps) {
  const { signInState, isLoading, onTakeMeBack } = props
  const { step } = signInState
  const isSignIn = step === SignInSteps.LANDING
  const { email } = signInState.data
  const defaultValues = { email, agreed: true }

  const _onClick = (values) => props.onPressContinue({ ...signInState, data: { ...signInState.data, ...values } })

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues })

  const onSubmit = handleSubmit(_onClick)

  return (
    <Box fullWidth align="center" spacing={smallSpacing}>
      <Box fullWidth direction="row" justify="space-between" align="flex-start" spacing={regularSpacing}>
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <Box fullWidth direction={'row'} justify="space-between" align="center" spacing={regularSpacing}>
              <Box fullWidth right spacing={smallSpacing}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{
                    border: '1px solid white',
                    borderRadius: BorderRadixes.regular,
                    padding: smallSpacing
                  }}
                  onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)}
                  size="small"
                  placeholder={getFigmaText(AppTexts.signInEmailFieldPlaceholder)}
                  inputProps={{ style: { color: 'white', borderColor: 'white' } }}
                />
              </Box>
              <StyledButtonWrapper>
                <StyledButton
                  onClick={onSubmit}
                  disabled={!fieldState.isTouched}
                  loading={isLoading}
                  fullWidth
                  textKey={DeprecatedTextKeys.homeFormSubmitButton}
                  sx={{ backgroundColor: '#12059E' }}
                  spacing={defaultSpacing}
                  endIcon={<HomeFormCTAArrow />}
                />
              </StyledButtonWrapper>
            </Box>
          )}
        />
      </Box>
      {isSignIn && (
        <Box top spacing={smallSpacing} fullWidth>
          <SignInDescription fullWidth fullPadding direction="row" spacing={littleSpacing}>
            <FigmaText textKey={HardCodedTexts.signInDescription} text="🪄" />
            <FigmaText textKey={HardCodedTexts.signInDescription} />
          </SignInDescription>
        </Box>
      )}
    </Box>
  )
}

const StyledButton = styled(Button)`
  height: ${ONBOARDING_BUTTON_HEIGHT};
  div {
    width: fit-content !important;
  }
`
const StyledButtonWrapper = styled(Box)`
  min-width: auto;
`

const SignInDescription = styled(Box)`
  background-color: ${Colors.neutral40};
  border-radius: ${tinyBorderRadius};
`
