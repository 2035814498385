import dayjs from 'dayjs'
import { getDownloadURL, ref } from 'firebase/storage'
import { IsVitecNextProductEnabledResponseCode } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { JaguarResultResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { toMB } from '../../../../pure/libs/Common'
import { formatRoutePathForProduct } from '../../../../pure/libs/RoutePath'
import { isProcessingUpload, isTimedoutLeopardOrder } from '../../../../pure/libs/VitecNextOrderItemHelper'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { useNavigate } from '../hooks/useNavigate'
import { storage } from '../libs/Firebase'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextProductStatusProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { useLeopardOrder } from '../hooks/useLeopardOrder'

export const VitecNextOrderItemStatusItem = (
  props: Partial<VitecNextProductStatusProps> & {
    product: Product
    externalEstateId: string
    successText?: string
    now: string
    results?: JaguarResultResponse
  }
) => {
  const { successText, now, results } = props

  const navigate = useNavigate({ enablePreserveQueryParams: true })

  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { externalEstateId, product } = props

  const { isProductEnabled } = props

  const onClickDoneStatus = () =>
    navigate(
      formatRoutePathForProduct({
        product,
        externalEstateId
      })
    )

  if (!leopardOrder) return <Box />

  const { requestId } = leopardOrder

  if (isProductEnabled?.code === IsVitecNextProductEnabledResponseCode.DOCUMENT_TOO_BIG)
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.standardTooBigDocument}
        type="warning"
        text={formatText(getFigmaText(Texts.standardTooBigDocument), [
          toMB(isProductEnabled?.extra?.bytes || 0).toFixed(0)
        ])}
        onClick={() => getDownloadURL(ref(storage, isProductEnabled?.extra?.url || '')).then((url) => window.open(url))}
        statusProps={props}
      />
    )

  if (isProductEnabled && !isProductEnabled?.isEnabled) return <Box />

  if (!requestId) return <Box />

  if (results?.status === 'error')
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.standardNotificationCiError}
        type="error"
        statusProps={props}
        text={getFigmaText(Texts.standardNotificationCiError)}
      />
    )

  switch (true) {
    case results?.status === 'success':
      return (
        <Box link onClick={onClickDoneStatus} fullWidth>
          <VitecNextOrderItemStatusView
            textKey={Texts.standardNotificationCiCompleted}
            type="success"
            text={`${successText || getFigmaText(Texts.standardNotificationCiCompleted)} (${dayjs(
              leopardOrder.finishedAt || leopardOrder.createdAt
            ).format('DD/M HH:mm')})`}
            statusProps={props}
          />
        </Box>
      )

    case isTimedoutLeopardOrder({ now, o: leopardOrder, results }):
      return (
        <VitecNextOrderItemStatusView
          type="processing"
          textKey={Texts.ImageTextsItJobTakingToLong}
          statusProps={props}
        />
      )
    case dayjs(now).diff(dayjs(leopardOrder?.createdAt), 'seconds') < 30: // UX feature
      return (
        <VitecNextOrderItemStatusView
          type="processing"
          textKey={Texts.standardNotificationInProgress}
          statusProps={props}
        />
      )
    case isProcessingUpload(results):
      return (
        <VitecNextOrderItemStatusView
          type="processing"
          textKey={Texts.standardNotificationInProgress}
          statusProps={props}
        />
      )
    default:
      return <Box />
  }
}
