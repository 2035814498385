import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { SelfDeclarationItem } from '../../../../pure/leopard/JaguarTypesDerived'
import { BorderRadixes } from '../enums/BorderRadixes'
import { SMALL_SPACING_PX, smallSpacing, tinySpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { NoWrapFigmaText } from './StyledComponents'
import { HIGHLIGHTED_TOP_ABSOLUTE_POSITION } from '../libs/HardCodedSizes'
import { formatSelfDeclarationItem } from '../libs/SelfDeclarationHelper'
import { LightBlue25, LightBlue50 } from '../../../../pure/libs/Colors'

export function VitecNextOrderProductSelfDeclarationItem({
  item: r,
  onClickHighlight,
  isSelected = false
}: {
  item: SelfDeclarationItem
  onClickHighlight: () => unknown
  isSelected?: boolean
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const { question, answer } = formatSelfDeclarationItem(r)

  useEffect(() => {
    if (isSelected) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [isSelected])

  return (
    <Container
      fullPadding
      fullWidth
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isSelected={isSelected}
      spacing={smallSpacing}
      position="relative"
    >
      <Box ref={ref} style={{ position: 'absolute', top: -HIGHLIGHTED_TOP_ABSOLUTE_POSITION + SMALL_SPACING_PX }} />
      <FigmaText textKey={HardCodedTexts.cssSelfDeclerationQuestion} text={question} />
      <Box fullWidth direction="row" justify="space-between" align="center">
        <BackgroundTextContainer fullWidth backgroundColor="white">
          <TextContainer isSelected={isSelected} fullWidth fullPadding spacing={tinySpacing}>
            <FigmaTextWithStyle
              Component={FigmaTextWithLinebreaks as any}
              separator={<span />}
              separatorWhenEmptyContent={<br />}
              type="body2"
              text={answer}
            />
          </TextContainer>
        </BackgroundTextContainer>
        <Box
          direction="row"
          align="center"
          visibility={isHovered && !isSelected ? 'visible' : 'hidden'}
          onClick={onClickHighlight}
          pointer
          left
          right
        >
          <Box left spacing={tinySpacing}>
            <NoWrapFigmaText textKey={Texts.SelfDeclerationSeeReference} />
          </Box>
          <Box left spacing={smallSpacing}>
            <FigmaImageContainer imageKey={Images.arrowForward} />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? LightBlue50 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`

const BackgroundTextContainer = styled(Box)`
  background-color: white;
  border-radius: ${BorderRadixes.regular};
`

const TextContainer = styled(Box)<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? LightBlue25 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`
