import invariant from 'invariant'
import React from 'react'
import { getLeopardOrderId, getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { fulfillLeopardOrder } from '../libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { Texts } from '../libs/Texts'
import { IsSteppingThroughStatusContext } from '../types/IsSteppingThroughStatus'
import Box from './Box'
import Button from './Button'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export const IS_STEPPING_THROUGH_NEXT_BUTTON_VERTICAL_SPACING = '4px'

export const VitecNextOrderProductStepThroughContentFulfillButton = (
  props: VitecNextOrderProductProps & {
    isSteppingThroughStatusContext: IsSteppingThroughStatusContext
  }
) => {
  const { isSteppingThroughStatusContext, product, externalEstateId } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSteppingThroughStatus] = isSteppingThroughStatusContext
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))
  const { state } = useAppState()

  const [isSuccess, setIsSuccess] = React.useState(false)

  const onClick = () => {
    invariant(isSteppingThroughStatus === 'done', 'Cannot fulfill before stepping through')
    return Promise.resolve(setIsLoading(true))
      .then(
        () =>
          leopardOrder &&
          fulfillLeopardOrder(
            {
              externalEstateId,
              product
            },
            state
          )
      )
      .then(() => setIsSuccess(true))
      .catch((err) => captureAndNotifyError(err))
      .finally(() => setIsLoading(false))
  }

  if (isSuccess)
    return (
      <Box>
        <VitecNextOrderItemStatusView
          type="success"
          textKey={Texts.standardNotificationUploadComplete}
          statusProps={undefined}
        />
      </Box>
    )

  return (
    <Button
      buttonType="secondary"
      textKey={Texts.standardCtaUploadToNext}
      spacing={smallSpacing}
      verticalSpacing={IS_STEPPING_THROUGH_NEXT_BUTTON_VERTICAL_SPACING}
      onClick={onClick}
      loading={isLoading}
    />
  )
}
