import { B } from 'vitest/dist/chunks/benchmark.JVlTzojj'
import {
  BuildingComponent,
  ConstructionMethodResultsResponse
} from '../../../../pure/leopard/JaguarTypesDerived'
import { TG_SEVERITIES } from '../../../../pure/libs/Consts'
import { ConstructionMethodItem } from '../components/VitecNextOrderProductConstructionInfoItem'

export function getConstructionMethodItems(results?: ConstructionMethodResultsResponse) {
  const { building_components, building_condition, electrical_system } = results?.structured_outputs || {}

  const items: ConstructionMethodItem[] = []

  if (building_components?.report_date) {
    items.push({
      id: 'report_date',
      label: ConstructionComponentLabels.report_date,
      field: building_components?.report_date,
      text: `Informasjon er hentet fra vedlagte tilstandsrapport, datert ${building_components?.report_date?.description}`
    })

    items.push({ text: ``, id: 'new-line' })
  }

  ContructionInfoComponentIds.forEach((id, i, arr) => {
    const label = ConstructionComponentLabels[id]

    const field = building_components?.[id] as BuildingComponent
    if (!field) return

    const value = field?.description
    if (!value) return

    items.push({
      id,
      field,
      text: `${label}: ${value}`,
      label: `${ConstructionComponentLabels[id]}`
    })
    items.push({ text: ``, id: 'new-line' })
  })

  if (electrical_system) {
    items.push({
      id: 'electrical_system',
      text: `Elektrisk anlegg: ${electrical_system.description.description}\n\n`,
      label: 'Elektrisk anlegg',
      field: electrical_system.description
    })

    electrical_system.questions_and_answers
      .map((qa, i, arr) => {
        items.push({
          text: `${qa.question}\n\n- ${qa.answer}`,
          field: { ...qa, description: `${qa.question}: ${qa.answer}` },
          label: `${ConstructionComponentLabels.electrical_system}`,
          id: `electrical_system_${qa.question}`
        })

        items.push({ text: ``, id: 'new-line' })
      })
      .join('\n\n')

    items.push({ text: ``, id: 'new-line' })
  }

  if (building_components?.additional_buildings) {
    Object.entries(building_components.additional_buildings).forEach(([name, building], i, arr) => {
      items.push({
        id: `additional_buildings_${name}`,
        text: `${name.charAt(0).toUpperCase() + name.slice(1)}: ${building.description}`,
        label: `${ConstructionComponentLabels.additional_buildings}`,
        field: building
      })
      items.push({ text: ``, id: 'new-line' })
    })
  }
  if (building_condition) {
    items.push({ text: 'Tilstandsgrad:', id: 'tilstandsgrad', label: ConstructionComponentLabels.tilstandsgrad })
    TG_SEVERITIES.forEach((severity) => {
      const buildingConditionItems = building_condition.filter((item) => item.severity === severity)
      if (!buildingConditionItems.length) return

      items.push({ text: severity, id: 'new-line' })

      buildingConditionItems.forEach((issue, i, arr) =>
        items.push({
          text: `- ${issue.issue_name} | ${issue.description}${
            issue.cost_estimate ? ` | Kostnadsestimat: ${issue.cost_estimate}` : ''
          }\n`,
          field: issue,
          id: `building_condition_${issue.issue_name}`,
          label: `${ConstructionComponentLabels.tilstandsgrad} ${issue.issue_name}`
        })
      )

      items.push({ text: '', id: 'new-line' })
    })

    items.push({
      text: '\nOpplistingen over er ikke uttømmende og du oppfordres til å lese og sette deg grundig inn i alt salgsmateriell.',
      id: 'building_condition_suffix'
    })
  }
  return items
}

export const ContructionInfoComponentIds: (keyof typeof ConstructionComponentLabels)[] = [
  'building',
  'roof',
  'chimney_fireplace',
  'windows',
  'doors',
  'stairs_access',
  'balcony_terrace',
  'plumbing',
  'ventilation',
  'technical_details'
]

export const ConstructionComponentLabels = {
  report_date: 'Rapportdato',
  building: 'Bygning',
  roof: 'Tak',
  chimney_fireplace: 'Pipe/Ildsted',
  windows: 'Vinduer',
  doors: 'Dører',
  stairs_access: 'Trapper/adkomst',
  balcony_terrace: 'Balkong/terrasse',
  plumbing: 'VVS-installasjoner',
  ventilation: 'Ventilasjon',
  technical_details: 'Tekniske detaljer',
  electrical_system: 'Elektrisk anlegg',
  additional_buildings: 'Tilleggsbygg',
  tilstandsgrad: 'Tilstandsgrad'
}
