import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { formatRoutePathForProduct } from '../../../../pure/libs/RoutePath'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { bigSpacing, regularSpacing, smallSpacing } from '../enums/Spacings'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { useNavigate } from '../hooks/useNavigate'
import { isFinished } from '../libs/JaguarHelper'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { DashboardListItemDetails } from './DashboardListItemDetails'
import { DashboardListItemStatus } from './DashboardListItemStatus'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE, DashboardStatusItemViewPopperRowForwardArrowBlack } from './Icons'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { BetaButton } from './VitecNextOrderItemIconView'

export default function DashboardListItem(props: VitecNextOrderProductProps) {
  const { product } = props
  const { textKey, imageKey, enableBetaUX } = VitecNextProductInfoItems[product]
  const navigate = useNavigate()
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { data: result } = useJaguarResult(leopardOrder)
  return (
    <Box fullWidth>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <Box
              onClick={(e) => {
                e.stopPropagation()
                navigate(formatRoutePathForProduct(props))
              }}
              visibility={isFinished(result) ? 'visible' : 'hidden'}
            >
              <DashboardStatusItemViewPopperRowForwardArrowBlack />
            </Box>
          }
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(0deg)'
            }
          }}
        >
          <Box right direction="row" align="center" fullWidth justify="space-between">
            <Box>
              <FigmaImageContainer imageKey={imageKey} width={BIG_ICON_SIZE} />
            </Box>
            <Box left spacing={smallSpacing} fullWidth>
              <FigmaText styleTextKey={Texts.dashboardAutoCaptioningTitleCaptioningTitleg} textKey={textKey} />
            </Box>
            {enableBetaUX && (
              <Box left right spacing={bigSpacing}>
                <BetaButton />
              </Box>
            )}
            <Box>
              <DashboardListItemStatus {...props} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <DashboardListItemDetails {...props} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
