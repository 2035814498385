import { IsVitecNextProductEnabledResponseCode } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { getAddress } from '../../../../pure/libs/EstateFunctionsPure'
import { getDebugInfoAddress } from '../../../../pure/libs/getDebugInfoAddress'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { smallSpacing } from '../enums/Spacings'
import { useEstate, useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextProductStatusProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { VitecNextOrderItemStatusFile } from './VitecNextOrderItemStatusFile'
import { VitecNextOrderItemStatusItem } from './VitecNextOrderItemStatusItem'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderItemStatus = (props: VitecNextProductStatusProps) => {
  const { isProductEnabled, product, enableLoadingFilesWidget = true } = props

  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { data: results } = useJaguarResult(leopardOrder)

  const { data: estate } = useEstate(props.externalEstateId)

  // Deprecated use validation on VitecNextItem instead
  if (isProductEnabled?.code === IsVitecNextProductEnabledResponseCode.DOCUMENT_NOT_FOUND)
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.standardNotificationMissingDocument}
        type="warning"
        statusProps={props}
      />
    )

  // Deprecated use validation on VitecNextItem instead
  if (isProductEnabled?.code === IsVitecNextProductEnabledResponseCode.ADDRESS_NOT_FOUND)
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.AreaDescriptionMissingArea}
        type="warning"
        text={formatText(getFigmaText(Texts.AreaDescriptionMissingArea), [
          getDebugInfoAddress(getAddress({ estate })) || ''
        ])}
        statusProps={props}
      />
    )

  const successText = !!leopardOrder?.fulfilledAt ? getFigmaText(Texts.standardNotificationUploadComplete) : undefined

  return (
    <Box fullWidth>
      {enableLoadingFilesWidget && <VitecNextOrderItemStatusFile {...props} />}
      <Box top={enableLoadingFilesWidget} spacing={smallSpacing} fullWidth>
        <VitecNextOrderItemStatusItem
          {...props}
          product={product}
          isProductEnabled={isProductEnabled}
          successText={successText}
          results={results}
        />
      </Box>
    </Box>
  )
}
