import invariant from 'invariant'
import { JaguarBoundingBox } from '../leopard/JaguarTypesDerived'
import { groupBy } from './Common'

export type StepThroughContentField = {
  bounding_boxes?: JaguarBoundingBox[]
}

export type StepThroughContentItem<T extends StepThroughContentField> = {
  id: string
  text: string
  label?: string
  field?: T
  pageNumber?: string
}

export type StepThroughContentItemBase = StepThroughContentItem<StepThroughContentField>

export function getStepThroughContentItems<T extends StepThroughContentItem<StepThroughContentField>>(items: T[]): T[] {
  return items
    .filter((i) => !!i.field)
    .map((i) => {
      invariant(i.field, '!i.field')
      const boundingBoxes = i.field.bounding_boxes || []
      const _boundingBoxes = groupBy(boundingBoxes, 'page')
      return Object.entries(_boundingBoxes)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([page, bounding_boxes], j, arr) => ({
          ...i,
          pageNumber: page.toString(),
          label: `${i.label} ${arr.length > 1 ? `${j + 1}/${arr.length}` : ''}`,
          field: { ...i.field, bounding_boxes }
        }))
    })
    .flat()
}
