import {
  SelfDeclarationItem,
  SelfDeclarationResultsResponse
} from '../../../../pure/leopard/JaguarTypesDerived'

export function formatSelfDeclarationItem(r: SelfDeclarationItem): { question: any; answer: any } {
  let question = cleanText(r.question).replace(/\d+\./g, '')
  let answer = cleanText(r.answer).replace(/\n\d+\.\n/, '\n')

  if (question.includes('- Ja')) {
    question = question.replace('- Ja', '')
    answer = `- Ja, ${answer}`
  }

  question = question.trim()
  answer = answer.trim()

  return { question, answer }
}

function cleanText(text: string): string {
  return text.replaceAll('◆', '-')
}

export function getSelfDeclarationItems(jaguarResults: SelfDeclarationResultsResponse | undefined) {
  return (
    (jaguarResults?.structured_outputs?.items
      ?.filter((r) => r.answer)
      .sort(
        (a, b) => parseInt(a.question_number as string) - parseInt(b.question_number as string)
      ) as SelfDeclarationItem[]) || []
  )
}
