import { JaguarBoundingBox as BoundingBox } from '../../../../pure/leopard/JaguarTypesDerived'
import { getValidationItemsForResult } from '../../../../pure/libs/AdValidationHelper'
import { removeDuplicateStrings } from '../../../../pure/libs/Common'
import { PdfViewerProps } from '../components/PdfViewer'
import { useJaguarResultsForAdValiation } from './useJaguarResults'

export function useDefaultPdfViewerPropsForAdValidation(externalEstateId: string): {
  data?: Partial<PdfViewerProps>[]
  loading: boolean
} {
  const { data: results, isLoading: loading } = useJaguarResultsForAdValiation(externalEstateId)

  if (!results) return { data: [], loading: false }

  const data = Object.values(results || {}).reduce((a, v) => {
    if (!v) return a
    const _items = Object.values(getValidationItemsForResult(v))

    const fileNames = removeDuplicateStrings(
      _items
        .flatMap((v) => v?.bounding_boxes)
        .map((v) => v?.filename)
        .filter(Boolean) as string[]
    )

    const propSets = fileNames.reduce((a, fileName) => {
      const gsUrl = Object.values(results)
        .flatMap((v) => v?.input_files || [])
        .find(({ uri }) => !!fileName && uri.includes(fileName))?.uri

      if (!gsUrl) return a

      a.push({
        gsUrl,
        boundingBoxes: Object.values(_items || {})
          .flatMap((v) => v?.bounding_boxes)
          .filter((v) => !!v && v.filename === fileName) as BoundingBox[]
      })

      return a
    }, [] as Partial<PdfViewerProps>[])

    a.push(...propSets)

    return a
  }, [] as Partial<PdfViewerProps>[])

  return { data, loading }
}
