import { VitecNextProductPureInfoItems } from '../leopard/VitecNextProductPureInfoItems'
import { JaguarEstate } from '../types/JaguarEstate'
import { JaguarResultsObject } from '../types/JaguarResultsObject'
import { Product } from '../leopard/Product'

export function getJaguarResultsObject({
  jaguarEstate,
  product
}: {
  jaguarEstate?: JaguarEstate
  product?: Product
}): JaguarResultsObject {
  if (!jaguarEstate) return DefaultJaguarResultsObject
  if (!product) return DefaultJaguarResultsObject

  const workflowIds = VitecNextProductPureInfoItems[product]?.workflowIds || []

  return workflowIds.reduce((a, workflowId) => {
    const result = jaguarEstate?.workflow_history?.[workflowId]?.findLast((w) => true)

    if (!result) return a

    if (typeof result?.structured_outputs === 'string')
      result.structured_outputs = JSON.parse(result.structured_outputs)

    a[workflowId] = result
    return a
  }, {} as JaguarResultsObject)
}

export const DefaultJaguarResultsObject: JaguarResultsObject = {
  descriptions: undefined,
  'image-captions': undefined,
  'area-description': undefined,
  'basic-association': undefined,
  'construction-method': undefined,
  'self-declaration': undefined,
  tilstandsrapport: undefined,
  'municipal-info': undefined,
  'land-registry': undefined
}
