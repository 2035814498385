import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledInfo'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

type Props = VitecNextOrderProductProps & { isVitecNextProductEnabled: IsVitecNextProductEnabledResponse }

export function DashboardListItemDetailsProductNotEnabled(props: Props) {
  const { product, isVitecNextProductEnabled } = props
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))

  const { data: results } = useJaguarResult(leopardOrder)

  if (!isVitecNextProductEnabled?.code) return null

  const text = IsVitecNextProductEnabledInfo[isVitecNextProductEnabled.code].getText({
    ...props,
    isVitecNextProductEnabled
  })

  if (results?.status === 'error')
    return (
      <Box fullWidth align="center">
        <Box>
          <VitecNextOrderItemStatusView textKey={Texts.standardNotificationCiError} type="error" />
        </Box>
      </Box>
    )

  return (
    <Box fullWidth align="center">
      <Box>
        <VitecNextOrderItemStatusView type="warning" text={text} />
      </Box>
    </Box>
  )
}
