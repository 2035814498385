import { useParams } from 'react-router-dom'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { Layout } from '../components/Layout'
import { VitecNextOrderProductDiff } from '../components/VitecNextOrderProductDiff'

export const VitecNextOrderProductDiffPage = () => {
  const { product, externalEstateId } = useParams<VitecNextOrderProductProps>()

  if (!product) return <Layout enableMenu />
  if (!externalEstateId) return <Layout enableMenu />

  return <VitecNextOrderProductDiff product={product} externalEstateId={externalEstateId} />
}
