import { DescriptionsResultsResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { DescriptionsFields } from '../../../../pure/libs/JaguarWorkflowDescriptionsItemsPure'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { getDescriptionFieldsFromJaguarResultsClient } from '../libs/LeopardFieldsHelper'
import { useJaguarResult } from './useJaguarResult'
import { useLeopardOrder } from './useLeopardOrder'
import { useState } from './useState'

export const useDescriptionsFieldsState = (externalEstateId: string) => {
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId({ externalEstateId, product: Product.DESCRIPTIONS }))
  const { data: results } = useJaguarResult<DescriptionsResultsResponse>(leopardOrder)

  const [fields, setFields] = useState<DescriptionsFields>(getDescriptionFieldsFromJaguarResultsClient(results), {
    enabled: !!results
  })

  return { fields, setFields }
}
