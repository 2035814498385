import { useEffect } from 'react'
import { HIGHLIGHT_COLOR } from '../../../../pure/libs/Colors'
import { HighlightedField } from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import useAppState from '../hooks/useAppState'
import {
  HIGHLIGHTED_TOP_ABSOLUTE_POSITION,
  STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_RIGHT
} from '../libs/HardCodedSizes'

export const STEP_THROUGH_ITEMS_DRAWER_CONTEXT_EXTRA = {
  highlightedScrollOffset: STEPPING_THROUGH_HIGHLIGHTED_TOP_ABSOLUTE_POSITION_RIGHT,
  hightlightColor: HIGHLIGHT_COLOR,
  enableScrollOffsetWithExtraBoundingbox: true
}

export type DrawerContext = {
  drawerState: DrawerState
  close: () => void
  enable: () => void
  disable: () => void
  open: () => void
  highlight: (highlightedField: HighlightedField) => void
  type: DrawerTypes
}
export type DrawerState = {
  isOpen: boolean
  openWidth: number
  closeWidth: number
  highlightedField?: HighlightedField
  enabled?: boolean
  highlightedScrollOffset: number
  hightlightColor: string
  enableScrollOffsetWithExtraBoundingbox: boolean
}

const DEFAULT_STATE: DrawerState = {
  isOpen: false,
  openWidth: window.innerWidth / 2,
  closeWidth: window.innerWidth / 10,
  enabled: true,
  highlightedScrollOffset: HIGHLIGHTED_TOP_ABSOLUTE_POSITION,
  hightlightColor: HIGHLIGHT_COLOR,
  enableScrollOffsetWithExtraBoundingbox: false
}

export const useDrawerContext = (
  type: DrawerTypes,
  { onClose, onOpen, extra }: { onClose?: () => unknown; extra?: Partial<DrawerState>; onOpen?: () => unknown } = {}
): DrawerContext => {
  const appContext = useAppState()
  const defaultState = { ...DEFAULT_STATE, ...DrawerInfo[type], ...extra }
  const openState = { ...defaultState, isOpen: true }
  const closedState = { ...defaultState, isOpen: false }
  const drawerState = appContext.state.drawerContexts?.[type]?.drawerState || defaultState

  const setState = (drawerState: DrawerState) =>
    appContext.setState((state) => {
      return {
        ...state,
        drawerContexts: {
          ...state.drawerContexts,
          [type]: { ...state.drawerContexts?.[type], drawerState }
        }
      }
    })

  const open = () => {
    setState(openState)
    onOpen?.()
  }
  const close = () => {
    setState(closedState)
    onClose?.()
  }
  const enable = close
  const disable = () => setState({ ...DEFAULT_STATE, enabled: false })
  const highlight = (highlightedField: HighlightedField) => {
    if (!drawerState.highlightedField) setState(openState)
    setTimeout(() => setState({ ...openState, highlightedField }), 100)
  }

  useEffect(() => {
    if (!drawerState.enabled) enable()
    return () => {
      disable()
    }
  }, [])

  return { drawerState, open, close, enable, disable, highlight, type }
}

export type DrawerTypes =
  | 'ad_validation'
  | 'basic_association'
  | 'self_declaration'
  | 'construction_info'
  | 'descriptions'
  | 'area_description'
  | 'land_registry'

const DrawerInfo: {
  [property in DrawerTypes]: {
    openWidth?: number
    closeWidth?: number
  }
} = {
  ad_validation: { closeWidth: 0 },
  basic_association: {},
  self_declaration: {},
  construction_info: {},
  descriptions: {},
  area_description: {},
  land_registry: {}
}
