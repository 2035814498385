import { DescriptionsResultsResponse, PropertyComponents } from '../leopard/JaguarTypesDerived'
import { StepThroughContentField, StepThroughContentItem } from './StepThroughContentHelper'

// use getStepThroughItemsForDescriptions instead
export function getStandardFromJaguarResultDeprecated(results?: DescriptionsResultsResponse): string {
  if (!results?.structured_outputs) return ''
  if (typeof results.structured_outputs === 'string')
    results.structured_outputs = JSON.parse(results.structured_outputs)

  if (!results.structured_outputs?.standard_components) return ''

  const standard_components = results?.structured_outputs?.standard_components as PropertyComponents
  const floors = standard_components?.floors || {}
  const storage = standard_components?.storage || []
  const surfaces = standard_components?.surfaces || []

  const floorsCount = Object.keys(floors).length
  let description = ''

  // eslint-disable-next-line prefer-const
  for (let [floorName, rooms] of Object.entries(floors)) {
    rooms = rooms.filter((room) => !!room)
    if (floorsCount > 1) {
      description += `${floorName}:\n\n`
    }
    for (const room of rooms) {
      description += `${room.name}: ${room.description}\n\n`
    }
  }

  if (storage[0].description) description += `Oppbevaring:\n${storage[0].description}\n\n`
  if (surfaces[0].description) description += `Overflater består av:\n${surfaces[0].description}`

  return description
}

export function getStepThroughItemsForInnhold(
  results?: DescriptionsResultsResponse
): StepThroughContentItem<StepThroughContentField>[] {
  return [
    {
      id: 'innhold',
      text: results?.text_outputs?.innhold || '',
      label: 'Innhold',
      field: results?.structured_outputs?.property_layout
    }
  ]
}

export function getStepThroughItemsForStandard(
  results?: DescriptionsResultsResponse
): StepThroughContentItem<StepThroughContentField>[] {
  const items: StepThroughContentItem<StepThroughContentField>[] = []
  if (!results?.structured_outputs) return []
  if (typeof results.structured_outputs === 'string')
    results.structured_outputs = JSON.parse(results.structured_outputs)

  if (!results.structured_outputs?.standard_components) return []

  const standard_components = results?.structured_outputs?.standard_components as PropertyComponents
  const floors = standard_components?.floors || {}
  const storage = standard_components?.storage || []
  const surfaces = standard_components?.surfaces || []

  const floorsCount = Object.keys(floors).length

  // eslint-disable-next-line prefer-const
  for (let [floorName, rooms] of Object.entries(floors)) {
    rooms = rooms.filter((room) => !!room)
    if (floorsCount > 1) {
      push(
        {
          id: floorName,
          text: `${format(floorName)}:`
        },
        items
      )
    }
    for (const room of rooms) {
      push(
        {
          id: room.name,
          text: `${format(room.name)}: ${room.description}`,
          label: room.name,
          field: room
        },
        items
      )
      // description += `${room.name}: ${room.description}\n\n`
    }
  }

  // if (storage[0].description) description += `Oppbevaring:\n${storage[0].description}\n\n`
  if (storage[0].description)
    push(
      {
        id: 'storage',
        text: `${format('Oppbevaring')}:${storage[0].description}`,
        label: 'Oppbevaring',
        field: storage[0]
      },
      items
    )

  // if (surfaces[0].description) description += `Overflater består av:\n${surfaces[0].description}`
  if (surfaces[0].description)
    push(
      {
        id: 'surfaces',
        text: `${format('Overflater består av')}:${surfaces[0].description}`,
        label: 'Overflater',
        field: surfaces[0]
      },
      items
    )

  return items
}

const push = (item, items: Array<any>) => {
  items.push(item)
  items.push({
    id: item.id + '-newline',
    text: ''
  })
}

export const format = (text) => text
