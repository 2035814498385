import { BasicAssociationResultsResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { BasicAssociationResponseItemsKey } from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { HardCodedTexts } from '../libs/HardCodedTexts'
import { JaguarWorkflowBasicAssociationItems } from '../libs/JaguarWorkflowBasicAssociationItems'
import { BasicAssociationKeysAll } from '../../../../pure/libs/JaguarWorkflowBasicAssociationItemsPure'
import { Texts } from '../libs/Texts'
import { shouldShowBasicAssociationField } from '../libs/VitecNextOrderProductBasicAssociationHelper'
import { getBasicAssociationFieldsFromJaguarResultsClient } from '../libs/LeopardFieldsHelper'
import Box from './Box'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'

export const DashboardListItemDetailsBasicAssociationComponent = (props: VitecNextOrderProductProps) => {
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderId(props))
  const { data: jaguarResult } = useJaguarResult<BasicAssociationResultsResponse>(leopardOrder)

  const basicAssociationItems = jaguarResult?.structured_outputs?.basic_association_info

  const basicAssociationFields = basicAssociationItems
    ? getBasicAssociationFieldsFromJaguarResultsClient(jaguarResult)
    : undefined

  return (
    <Box fullWidth>
      {basicAssociationFields &&
        BasicAssociationKeysAll.filter((itemKey) =>
          shouldShowBasicAssociationField({
            itemKey: itemKey as BasicAssociationResponseItemsKey,
            fields: basicAssociationFields
          })
        ).map((itemKey, i) => {
          const { value } = basicAssociationFields[itemKey]
          const item = JaguarWorkflowBasicAssociationItems[itemKey]

          if (!value) return null

          return (
            <Box top fullWidth key={i} spacing={tinySpacing}>
              <Box left spacing={smallSpacing} fullWidth>
                <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} textKey={item.textKey} />
                <Box>
                  <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={value} />
                </Box>
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}
