import { Language } from '../types/Antiloop.js'

export const XXX = 'XXX'
export const PREVIOUS_OUTPUT = 'PREVIOUS_OUTPUT'
export const MONGO = 'MONGO'
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'
export const EUROPE_STOCKHOLM = 'Europe/Stockholm'

export const HTTP_STATUS_CODE_INTERNAL_ERROR = 500
export const HTTP_STATUS_CODE_BAD_REQUEST = 400
export const HTTP_STATUS_CODE_NOT_FOUND = 404
export const HTTP_STATUS_CODE_UNAUTHORIZED = 401
export const HTTP_STATUS_CODE_OK = 200
export const HTTP_STATUS_CODE_REDIRECT = 302

export const HTTP_LOCATION_HEADER = 'Location'

export const HALF_SECOND = 500
export const ONE_SECOND = 1000
export const TWO_SECONDS = 2000
export const FIVE_SECONDS = ONE_SECOND * 5
export const TEN_SECONDS = ONE_SECOND * 10
export const ONE_MINUTE = 60 * 1000
export const TEN_MINUTES = ONE_MINUTE * 10
export const ONE_PERCENT = 0.01

export const DD_MMM = 'DD MMM'

export const TAB_KEYCODE = 13
export const ENTER_KEYCODE = 13
export const ESC_KEYCODE = 27

export const STATE = 'STATE'

export const COMMON_EMAIL_DOMAINS = [
  'gmail.com',
  'outlook.com',
  'yahoo.com',
  'hotmail.com',
  'inbox.com',
  'icloud.com',
  'mail.com',
  'me.com'
]

export const sv = 'sv'
export const en = 'en'
export const LANGUAGES = [sv, en]

export const LANGUAGES_OBJECT: { [property in Language]: string } = { sv: 'Svenska', en: 'English', no: 'Norsk' }
export const LANGUAGES_OBJECT_MENU: { [property in Language]: string } = { sv: 'SWE', en: 'ENG', no: 'Norsk' }
export const LANGUAGES_OBJECT_CREATE_AD: { [languageCode: string]: string } = {
  ...LANGUAGES_OBJECT,
  fr: 'Français',
  da: 'Dansk',
  no: 'Norsk',
  es: 'Español',
  de: 'Deutsch',
  ar: 'عربى',
  zh: '中文'
}

export const IGNORED_TEXT_KEYS: string[] = ['exampleText', 'ignoreText', 'genericText']

export const URL_REGEXP = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const HHmm = 'HH:mm'
export const YYYYMMDD_HHmm = 'YYYY-MM-DD HH:mm'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const MMM_YYYY = 'MMM YYYY'
export const MMM_DD = 'MMM DD'
export const MMM_D = 'MMM D'

export const TEN_PERCENT = 0.1
export const TWENTY_PERCENT = 0.2

export const EUROPE_WEST_3 = 'europe-west3'
export const US_CENTRAL_1 = 'us-central1'
export const REGION = EUROPE_WEST_3

export const BLUR_KEYCODES = [TAB_KEYCODE, ENTER_KEYCODE]

export const MIN_CONTAINER_WIDTH = 600

export const USER_ID = 'userId'

export const MAX_QUERYABLE_ARRAY_LENGTH = 10

export const NO_OF_LETTERS_IN_SIGN_IN_CODE = 6

export const SNACK_BAR_PROPS: any = { maxSnack: 3, anchorOrigin: { vertical: 'top', horizontal: 'right' } }

export const APP_REVIEW_USER_EMAIL = 'APP_REVIEW_USER_EMAIL'

export const INVITE_MAIL_SUBJECT = 'You have been invited to join your colleagues at XXX'
export const LOGIN_WITH_CODE_SUBJECT = 'Gazelle Work confirmation code: XXX'

export const TERMS_AND_CONDITION_URL =
  'https://docs.google.com/document/d/e/2PACX-1vSnBA-ufOnzzPV9xNKj20bttR-XD2QYphtsyxY1HGD12iHZwl0jhn4zguvFUL_iE6qQDInQ0sVSnFkw/pub?embedded=true'

export const PRIVACY_POLICY_URL =
  'https://docs.google.com/document/d/e/2PACX-1vQuaMMmYWGj_l8pNJm-cao2v5S2q3veaZ1uTWXo2PGVROJyWJ44cZ6bytvjt_3qfw2w9A68e6m4TyxE/pub?embedded=true'

export const MAIL_TO_LINK = 'https://gazelle.work/#Contact'

export const FAQ_URL = 'https://gazelle.work/faq'

export const CONTACT_US_URL = 'https://www.gazelle.work/contact'

export const JAGUAR_SESSIONS_NOT_LOADING_UPDATE_INTERVAL = 1000

export const INFO_EMAIL_ADDRESS = 'info@gazelle.work'
export const ANDERS_EMAIL_ADDRESS = 'anders@gazelle.work'
export const CHRISTOPHER_EMAIL_ADDRESS = 'christopher@gazelle.work'

export const GAZELLE_ORG_ID = 'HqDEvWYpQ0PUkCJfx70l'

export const LINKED_IN_URL = 'https://www.linkedin.com/company/gazelle-work'
export const YOUTUBE_URL = 'https://www.youtube.com/@Gazelle_work'
export const INSTAGRAM_URL = 'https://www.instagram.com/gazelle.work/'
export const TWITTER_URL = 'https://twitter.com/Gazelle_Work'

export const VALID_HTTPS_URL_REGEX = /^https:\/\/(?:[\w-]+\.)+[\w-]+(?:\/[\w-./?%&=]*)?$/

export const NOT_AUTHENTUCATED_ERROR_MESSAGE = 'Not authenticated'

export const GOOGLE_CLOUD_MAPS_API_KEY = 'AIzaSyCXKUZuRRBuJkkC1vVNBZVXUlIhRrYL1Ws' // https://console.cloud.google.com/apis/credentials/key/13643b41-f83e-4b32-bc8c-d7a46c425e93?project=gazelle-4d5d9

export const NOT_ENOUGH_INFO_ERROR = 'NotEnoughInfoError'

export const PRICING_URL_DEPRECATED = 'https://www.gazelle.work/pricing'

export const IGNORED_KEYWORD_KEYS = ['meta']

export const USER_WIDGET_MENU_ID = 'UserWidgetMenu'

export const CAPTIONS_AD_LENGTH = 40

export const EIENDOMSMEGLER_ORG_ID = 'eiendomsmegler1'
export const EIENDOMSMEGLER_MITTNORGE_ORG_ID = 'eiendomsmegler1_mittnorge'

export const FIREBASE_CONFIG = {
  projectId: 'gazelle-4d5d9'
}

export const GC_FILE_PATH_DESIGN_TOKENS = `emu/design-tokens.tokens.json`
export const GC_FILE_PATH_DESIGN_SYSTEM_TOKENS = `emu/design-tokens.tokens.system.json`

export const MSEMOAH = 'MSEMOAH'
export const MSEMOAHATEST = 'MSEMOAHATEST'

export const EM1_SHORT_LENGTH = 800
export const EM1_HEADLINE_LENGTH = 120

export const SEVEN_MINUTES_IN_SECONDS = 7 * 60

export const FIGMA_MODES_LANG: {
  [property in Language]: string
} = {
  en: '3:0',
  sv: '3:0',
  no: '3:1'
}

export const THIRTY_FIVE_MB = 64 * 1024 * 1024

export const X_ENABLE_LOG = 'x-enable-log'

export const SUPPORTED_SELF_DECLARATION_WORD = 'Selgers egenerklæring' // Ex: https://storage.googleapis.com/gazelle-4d5d9.appspot.com/estates%2FC30AB6CF-EB8F-4B69-902A-08AF2DE67EC7%2F1-SelfDeclaration-1806600683728326.pdf

export const FREMTIND_EX_PDF_URL = '/fremtind_selfdecleration_example.png'

export const GAZELLE_WEBSITE_URL = 'https://www.gazelle.work'

export const MAX_NUMBER_OF_IMAGES = 75

export const DASHBOARD_LIST_ITEM_STATUS_VIEW_SIZE = 20 / 2 // 30 is figma size

export const VISMA_CLIENT_ID = 'integration_gazelle'

export const TG_SEVERITIES = [
  'TG3 - Store eller alvorlige avvik',
  'TG2 - Avvik som kan kreve tiltak',
  'TG2 - Avvik som ikke kreve umiddelbare tiltak',
  'TGIU - Ikke undersøkt/utilgjengelig'
]

export const SOMETHING_WENT_WRONG = `Something went wrong. Please try again later`

export const TRUE = 'TRUE'
export const FALSE = 'FALSE'

export const CAST = 'CAST' // Camilla Stuvå - employee id
