import qs from 'query-string'
import { AllAvailableQueryParams } from '../../../../pure/types/QueryParamTypes'

export default function useQueryParams<T extends AllAvailableQueryParams>(search: string = window.location.search): T {
  // eslint-disable-next-line prettier/prettier
  const {...qp} = (parse(search) as unknown) as T
  return qp as T
}

const parse = (text: string) => {
  text = text.split('?')[1]
  return qs.parse(decodeURIComponent(text))
}

export const getQueryParams = useQueryParams
