import styled from '@emotion/styled'
import { LightGrey } from '../../../../pure/libs/Colors'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import { IsSteppingThroughStatusContext } from '../types/IsSteppingThroughStatus'
import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { ConstructionMethodItem } from './VitecNextOrderProductConstructionInfoItem'
import { VitecNextOrderProductStepThroughContentFulfillButton } from './VitecNextOrderProductStepThroughContentFulfillButton'

export function VitecNextContructionInfoPdfDrawerMiddleComponent(
  props: {
    selectedItem: ConstructionMethodItem
    isSteppingThroughStatusContext: IsSteppingThroughStatusContext
  } & VitecNextOrderProductProps
) {
  const [isSteppingThroughStatus] = props.isSteppingThroughStatusContext
  const isDone = isSteppingThroughStatus === 'done'
  if (!isDone) return null

  return (
    <SuccessContainer fullPadding direction="row" align="center" spacing={bigSpacing}>
      <Box>
        <FigmaImage imageKey={Images.allTextsValidated} />
      </Box>
      <Box left>
        <Box>
          <FigmaText textKey={Texts.validatePdfAllTextsValidated} />
        </Box>
        <Box top>
          <VitecNextOrderProductStepThroughContentFulfillButton {...props} />
        </Box>
      </Box>
    </SuccessContainer>
  )
}

const SuccessContainer = styled(Box)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${LightGrey};
    border-radius: ${BorderRadixes.regular};
    opacity: 0.9;
    z-index: -1;
  }
`
