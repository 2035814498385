import { DescriptionsResultsResponse } from '../leopard/JaguarTypesDerived'
import {
  getStepThroughItemsForInnhold,
  getStepThroughItemsForStandard
} from './JaguarWorkflowDescriptionsItemsPureHelper'
import { DescriptionsFields } from './JaguarWorkflowDescriptionsItemsPure'

export function getDescriptionFieldsFromJaguarResults(
  results: DescriptionsResultsResponse | undefined
): DescriptionsFields {
  const stepThroughItemsStandard = getStepThroughItemsForStandard(results)
  const stepThroughItemsInnhold = getStepThroughItemsForInnhold(results)

  return {
    short_description: {
      checked: true,
      value: results?.text_outputs?.short_description || '',
      key: 'short_description'
    },
    long_description: {
      checked: true,
      value: results?.text_outputs?.long_description || '',
      key: 'long_description'
    },
    innhold: {
      checked: true,
      value: stepThroughItemsInnhold.map(({ text }) => text).join('\n\n'),
      key: 'innhold',
      stepThroughItems: stepThroughItemsInnhold
    },
    standard: {
      checked: true,
      value: stepThroughItemsStandard.map(({ text }) => text).join('\n\n'),
      key: 'standard',
      stepThroughItems: stepThroughItemsStandard
    }
  }
}
