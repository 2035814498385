import { Estate } from '../../../../pure/types/Estate.js'
import { formatText, getFigmaText } from '../libs/TextRepository.js'
import { Texts } from '../libs/Texts.js'
import FigmaText, { FigmaTextProps } from './FigmaText.js'

export function VitecNextOrderItemLongWaitFigmaText({
  estate,
  figmaTextProps
}: {
  estate?: Estate
  figmaTextProps?: FigmaTextProps
}) {
  return (
    <FigmaText
      {...figmaTextProps}
      textKey={Texts.standardNotificationLongJobEmail}
      text={formatText(getFigmaText(Texts.standardNotificationLongJobEmail), [
        estate?.metaData?.vitecNextOrder?.useremail
      ])}
    />
  )
}
