import { VitecSummarizeDocResultItemProps } from '../types/VitecSummarizeDocResultItemProps'
import { ONE_MINUTE } from '../../../../pure/libs/Consts'
import { HardCodedTexts } from './HardCodedTexts'
import { getFigmaText } from './TextRepository'
import { success } from './ToastHelper'

export const onCopyText = (text?: string | number) => {
  if (!navigator.clipboard) return
  if (text === undefined) return

  success(getFigmaText(HardCodedTexts.copyToClipBoard))

  navigator.clipboard.writeText(text.toString())
}

export const onClickCopy = (items: VitecSummarizeDocResultItemProps[]) => {
  const text = items.reduce((a, { header, description }) => a + `${header}\n${description}\n\n`, '')

  if (!navigator.clipboard) return

  success(getFigmaText(HardCodedTexts.copyToClipBoard))

  navigator.clipboard.writeText(text)
}
