import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RoutePath from '../../../../pure/libs/RoutePath.js'
import { VitecNOLandingQueryParams } from '../../../../pure/types/VitecNODerivedTypes'
import { Dashboard } from '../components/Dashboard'
import { Layout } from '../components/Layout.js'
import { useVitecNOOrder } from '../hooks/QueryHooks.js'
import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper.js'
import useQueryParams from '../libs/useQueryParams'

export const DashboardPage = () => {
  // eslint-disable-next-line prefer-const
  let { orderId } = useQueryParams<VitecNOLandingQueryParams>()
  let { externalEstateId } = useParams()

  const { data: vitecNextOrder } = useVitecNOOrder(orderId)

  if (!externalEstateId && vitecNextOrder?.estateId) externalEstateId = vitecNextOrder.estateId
  if (!externalEstateId) return <Layout enableMenu />

  return <Dashboard externalEstateId={externalEstateId} />
}

export const VitecNextOrderRedirectPage = ({ externalEstateId }: { externalEstateId: string }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(addQueryParamsToUrl(RoutePath.DASHBOARD, { estateId: externalEstateId }))
  }, [])

  return <Dashboard externalEstateId={externalEstateId} />
}
