import styled from '@emotion/styled'
import invariant from 'invariant'
import React from 'react'
import { Caption, ImageCaptionsResultsResponse } from '../../../../pure/leopard/JaguarTypesDerived'
import { Texts as AppTexts } from '../../../../pure/libs/AppTexts'
import Colors from '../../../../pure/libs/Colors'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useJaguarResult } from '../hooks/useJaguarResult'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { useState } from '../hooks/useState'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { Checkbox } from './Checkbox'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { LoadingPage } from './LoadingPage'
import { NoWrapFigmaText } from './StyledComponents'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import {
  SelectedCaptions,
  VitecNextOrderProductImageCaptionsResultExperiemental
} from './VitecNextOrderProductImageCaptionsResultExperiemental'

export const VitecNextOrderProductImageCaptionsExperimental = (props: VitecNextOrderProductProps) => {
  const leopardOrderId = getLeopardOrderId(props)
  const { data: leopardOrder } = useLeopardOrder(leopardOrderId)

  const { data: jaguarImageCaptions } = useJaguarResult<ImageCaptionsResultsResponse>(leopardOrder)

  const selectedCaptionContext = useState<SelectedCaptions>(getSelectedCaptionsForAllImages(jaguarImageCaptions), {
    enabled: !!jaguarImageCaptions
  })

  const [selectedCaptions, setSelectedCaptions] = selectedCaptionContext

  const captions: Caption[] = jaguarImageCaptions?.structured_outputs?.captions?.map((c) => c as Caption) || []
  const checkedCaptions = Object.entries(selectedCaptions || {})
    .filter(([, c]) => c?.checked)
    .map(([k]) => k)

  const [checked, setChecked] = React.useState(true)

  const onClickSelectAll = () => {
    if (!jaguarImageCaptions) return
    const newValue = !checked
    setChecked(newValue)
    invariant(jaguarImageCaptions?.structured_outputs?.captions, 'captions should be defined')
    setSelectedCaptions(getSelectedCaptionsForAllImages(jaguarImageCaptions, { checked: newValue }))
  }

  const onSelectCaption = (caption: Caption, context: typeof selectedCaptionContext) => {
    const [selectedCaptions, setSelectedCaptions] = context
    const { image_name } = caption
    invariant(image_name, 'caption image_name is required')
    invariant(selectedCaptions, '!selectedCaptions')

    setSelectedCaptions({ ...selectedCaptions, [image_name]: { ...caption, checked: !caption.checked } })
  }

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box>
        <VitecNextOrderProductBackButton {...props} />
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <VitecNextOrderProductHeader {...props} headerTextKey={Texts.ImageTextsItPreviewHeader} />
        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons
            fulfillMentSuccessText={formatText(getFigmaText(Texts.ImageTextsNotificationItCompleted), [
              checkedCaptions.length
            ])}
            {...props}
            enableCopy={false}
            enableFulfillment={checkedCaptions.length > 0}
            SuffixComponent={() => (
              <Box right pointer onClick={() => onClickSelectAll()}>
                <Box direction="row" align="center">
                  <NoWrapFigmaText
                    styleTextKey={Texts.ImageTextsItCssImageText}
                    textKey={AppTexts.cationsSelectAllButton}
                  />
                  <Box left spacing={smallSpacing}>
                    <Checkbox value={checked} />
                  </Box>
                </Box>
              </Box>
            )}
            onPreFulfill={async () => {
              leopardOrder &&
                (await upsertLeopardOrder(
                  {
                    ...leopardOrder,
                    extra: {
                      imageCaptions: {
                        imageCaptionsFulfillRequest: {
                          fileNames: checkedCaptions
                        }
                      }
                    }
                  },
                  { merge: true }
                ))
            }}
          />
        </Box>
        {captions.length > 0 && (
          <Box top>
            <FigmaText
              textKey={Texts.ImageTextsItPreviewSummary}
              text={formatText(getFigmaText(Texts.ImageTextsItPreviewSummary), [captions.length])}
            />
          </Box>
        )}
        <Box top spacing={tinySpacing} />
        {!!jaguarImageCaptions?.updated_at && !jaguarImageCaptions?.status && captions.length === 0 && <LoadingPage />}
        {captions.length > 0 && (
          <Container align="center" fullWidth>
            <Box direction="row" style={{ flexWrap: 'wrap' }} fullPadding fullWidth justify="center">
              {Object.values(selectedCaptions || {})?.map((caption, key) => {
                return (
                  <Box key={key} width="50%" fullPadding spacing={smallSpacing}>
                    <VitecNextOrderProductImageCaptionsResultExperiemental
                      caption={caption}
                      index={key}
                      selectedCaptions={selectedCaptions}
                      onSelectCaption={(c) => onSelectCaption(c, selectedCaptionContext)}
                      leopardOrder={leopardOrder}
                    />
                  </Box>
                )
              })}
            </Box>
          </Container>
        )}
      </Box>
      <Box />
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

function getSelectedCaptionsForAllImages(
  jaguarImageCaptions: ImageCaptionsResultsResponse | undefined,
  extra: Partial<Caption> = { checked: true }
): SelectedCaptions {
  const captions = jaguarImageCaptions?.structured_outputs?.captions || []
  return captions.reduce((acc, c) => {
    invariant(c?.image_name, 'image_name should be defined')
    acc[c?.image_name] = { ...c, ...extra }
    return acc
  }, {} as SelectedCaptions)
}
