import Box from './Box.js'

import dayjs from 'dayjs'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps.js'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus.js'

export const DashboardListItemDetailsStatus = (props: VitecNextOrderProductProps) => {
  return (
    <Box fullWidth align="center">
      <Box width="30%">
        <VitecNextOrderItemStatus {...props} now={dayjs().format()} />
      </Box>
    </Box>
  )
}
