import { AreaSizes, MunicipalInfoItems } from '../../../../pure/leopard/JaguarTypesDerived'
import { HardCodedTranslations } from '../../../../pure/libs/HardCodedTranslations'
import { HardCodedTexts } from './HardCodedTexts'

import { JaguarWorkflowBasicAssociationItems } from './JaguarWorkflowBasicAssociationItems'
import { JaguarWorkflowAdValidationClientItem } from '../../../../pure/types/JaguarWorkflowAdValidationClientItem'

const JaguarWorkflowMunicipalItems: {
  [property in keyof MunicipalInfoItems]: JaguarWorkflowAdValidationClientItem
} = {
  kommunale_avgifter: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.kommunale_avgifter }
  },
  kommunale_avgifter_year: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.kommunale_avgifter_year }
  },
  eiendomsskatt: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.eiendomsskatt }
  },
  eiendomsskatt_year: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.eiendomsskatt_year }
  },
  primary_value: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.primary_value }
  },
  primary_value_year: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.primary_value_year }
  },
  secondary_value: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.secondary_value }
  },
  secondary_value_year: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.secondary_value_year }
  }
}

const JaguarWorkflowTillstandsRapporttems: {
  [property in keyof AreaSizes]: JaguarWorkflowAdValidationClientItem
} = {
  bra: {
    // Boarea
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.bra }
  },
  braI: {
    // Boarea internal
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.braI }
  },
  braB: {
    // Boarea enclosed balcone
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.braB }
  },
  braE: {
    // Boarea External
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.braE }
  },
  tba: {
    // Boarea non enclosed balcony
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.tba }
  },
  pROM: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.pROM }
  },
  sROM: {
    textKey: { ...HardCodedTexts.default, texts: HardCodedTranslations.sROM }
  }
}

export const JaguarWorkflowAdValidationItems: {
  [key: string]: JaguarWorkflowAdValidationClientItem
} = {
  ...JaguarWorkflowMunicipalItems,
  ...JaguarWorkflowTillstandsRapporttems,
  ...JaguarWorkflowBasicAssociationItems
}
